.game-jam-presentation-Wrapper {
  display: flex;
  justify-content: center;

  padding: 100px 0 100px 0;

  .game-jam-presentation {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'text img';
    gap: 43px;

    max-width: 1095px;
    width: 100%;

    /* Text */
    &__text {
      grid-area: text;
      display: flex;
      flex-direction: column;
      gap: 24px;
    }

    /* Img */
    &__img {
      grid-area: img;
      position: relative;
      margin: 0 0 0 0;

      &--active {
        & > div {
          transform: rotate(0deg) !important;
        }
      }

      & > div {
        transform: rotate(6deg);

        & > div {
          border-radius: 16px;
          filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.08));

          & > iframe {
            border-radius: 16px;
            filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.08));
          }
        }
      }

      /* Play */
      &_play {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 151px;
        height: 151px;
        background: rgba(255, 255, 255, 0.25);
        border: 1px solid #ffffff;
        border-radius: 50%;

        & > svg {
          margin: 0 0 0 14px;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .game-jam-presentation-Wrapper {
    padding: 80px 20px 80px 20px;
    overflow: hidden;

    .game-jam-presentation {
      grid-template-columns: 1fr;
      grid-template-areas:
        'img'
        'text';
      gap: 64px;

      /* Text */
      &__text {
        text-align: center;
        gap: 32px;
      }

      /* Img */
      &__img {
        & > div {
          transform: rotate(0deg);
        }

        /* Play */
        &_play {
          width: 100px;
          height: 100px;
        }
      }
    }
  }
}
