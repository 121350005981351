.maintenance-wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: calc(20px + (50 - 20) * ((100vw - 300px) / (1600 - 300)));
  }
}
