.header-slider-Wrapper {
  position: relative;
  border-radius: 8px;
  position: relative;
  overflow: hidden;

  width: 100%;
  height: 100%;

  .lazy-load-image-background {
    position: absolute;
    top: 0;
    left: 0;

    & > img {
      width: 100%;
      height: 100%;
    }
  }

  &__button {
    position: absolute;
    bottom: 14px;
    right: 48%;
    z-index: 10;
  }
}
