.whatsapp-main-icon {
  position: fixed;
  right: 3rem;
  bottom: 2rem;

  width: 271px;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  padding: 10px 20px;

  z-index: 99;
  background-color: #128c7e;

  animation-name: desktop;
  animation-duration: 1.5s;
  transition: 0.6s;

  svg {
    margin-right: 16px;
    width: 64px;
    height: 64px;
  }

  p {
    color: white;
    font-size: 16px;
    font-weight: 700;
  }
}

@media only screen and (min-width: 480px) {
  .whatsapp-main-icon {
    &:hover {
      background-color: #dcf8c6;
      filter: drop-shadow(0px 2px 15px rgba(37, 211, 102, 0.25))
        drop-shadow(0px 15px 40px rgba(37, 211, 102, 0.5));

      p {
        color: black;
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .whatsapp-main-icon {
    bottom: 19px;
    right: 50%;
    transform: translate(50%, 0%);

    max-width: 90%;
    width: 323px;
    height: 55px;
    padding: 7px 12px;
    justify-content: flex-start;

    animation-name: mobile;
    animation-duration: 2s;

    svg {
      margin-right: 16px;
      width: 36px;
      height: 36px;
    }

    p {
      color: white;
      font-size: 14px;
      font-weight: 400;
    }
  }
}

@keyframes mobile {
  0% {
    transform: translate(180%, 0%);
  }
  100% {
    transform: translate(50%, 0%);
  }
}

@keyframes desktop {
  0% {
    transform: translate(180%, 0%);
  }
  100% {
    transform: translate(0%, 0%);
  }
}
