.niubiz-inputs-wrapper {
  max-width: 450px;

	.field_row {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 16px;
		min-height: 98px;

		.select-field-purchase-flow, .text-field-purchase-flow {
			border: 2px solid #001F19;
			height: 56px;
		}
	}

  .__text {
    @apply mb-6;
  }

  .__input-wrapper {
    @apply
      border-2 border-black h-12 flex items-center
      px-6 mb-6 flex-1;

    iframe {
      @apply w-full;
    }
  }

  .__row {
    @apply flex;

    .__input-wrapper {
      @apply mr-6;

      &:last-child {
        @apply mr-0;
      }
    }
  }

  .__product-info {
    @apply bg-grey-lighter p-5 py-8 mb-8;

    p {
      @apply mb-3;
    }

    h4 {
      @apply mb-2;
    }

    p:last-child {
      @apply mb-0;
    }
  }

  .__logos {
    @apply flex items-center mb-6 justify-between;

    & > img {
      @apply relative;
      left: -10px;
      max-width: 180px;
    }

    div {
      @apply flex items-center flex-wrap;
      max-width: calc(100px + 1.5rem);

      img {
        @apply mr-6;
        max-width: 50px;

        &:nth-child(even) {
          @apply mr-0;
        }
      }
    }
  }

  .__terms-and-condition {
    @apply flex items-center mb-6;

    input {
      @apply mr-3;
    }

    p {
      @apply mb-0 pt-1;
    }

    a {
      @apply ml-2;
    }
  }

  @screen lg {
    .__logos {
      & > img {
        max-width: 216px;
        left: -15px;
      }
    }
  }
}

.niubiz-footer {
  @apply flex justify-between items-end border-black py-6;
  justify-content: space-between;

  .niubiz-footer__total {
    div {
      @apply flex items-end;

      h3 {
        @apply mb-0 mr-2;
      }

      small {
        padding-bottom: 6px;
      }
    }
  }

  button {
    margin-bottom: 4px;
  }
}

.niubiz-message {
  .__inline-info {
    @apply flex items-end mb-4;

    p {
      @apply mr-2;
      padding-bottom: 5px;
    }
  }
}

@media only screen and (max-width: 768px) {
	.niubiz-inputs-wrapper {
	
		.field_row {
			grid-template-columns: 1fr;
			gap: 0;
			
			.container_field {
				height: 98px;
			}
		}
	}
}
