.counter-card {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	width: 100%;
	height: auto;
	padding: 40px 30px;

	background: #FFFFFF;
	box-shadow: 0px 16px 40px rgba(14, 86, 124, 0.2);
	border-radius: 20px;

}
.content-counter-card {
	display: flex;
	grid-gap: 25px;
	flex-direction: row;
	align-content: space-between;
	justify-content: space-evenly;
	flex-wrap: wrap;
}
.counter-card-item {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	padding: 0px;
	width: 250px;
	height: 125px;
}
.counter-card h3{
	font-family: 'Roca One';
	font-size: 22px;
	font-style: normal;
	font-weight: 400;
	margin-bottom: 40px;
}
.counter-card-subtitle {
	font-size: 60px;
	line-height: 60px;
	font-weight: 700;
	margin-bottom: 10px;
}
.counter-card-descrition {
	font-family: 'Roca One';
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 24px;
	text-align: center;
	color: #616161;
}
@media (min-width : 768px){
	.counter-card-item {
		width: 245px;
	}
}

@media (min-width : 1440px){
	.counter-card {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 1154px;
		height: 261px;
		background: #FFFFFF;
		box-shadow: 0px 16px 40px rgba(14, 86, 124, 0.2);
		border-radius: 20px;
	
	}
	.content-counter-card {
		display: flex;
		grid-gap: 25px;
		flex-direction: row;
	}
}
