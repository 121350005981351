.typography-h2 {
	font-family: Roca One;
	font-size: 51px;
	line-height: 60px;
}
@media (max-width: 768px) {
	.typography-h2 {
		font-size: 38px;
		line-height: 48px;
	}
}