.container-cover-page{
	display: flex;
	flex-direction: column;
	align-items: center;
}
.info-cover-page {
	display: flex;
	grid-gap: 2.5rem;
	flex-direction: column;
	align-items: center;
	margin-top: 1.25rem;
}
.info-cover-page h1{
	font-family: 'Roca One';
	font-style: normal;
	font-weight: bold;
	font-size: 3.13rem;
	line-height: 4rem;
	text-align: center;
	color: #000000;
}
.info-cover-page h4{
	font-family: 'Roca One';
	font-style: normal;
	font-weight: normal;
	font-size: 1.5rem;
	line-height: 2.5rem;
	text-align: center;
	color: #000000;
}
.button-cover-page{
	width: 14.75rem;
}
.image-cover-page img:last-child {
 display: none;
}

@media (min-width : 1020px){
	.container-cover-page{
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	.info-cover-page {
		align-items: flex-start;
	}
	.image-cover-page {
		display: flex;
	 }
	 .image-cover-page , .info-cover-page {
		 width: 50%;
	 }
	.image-cover-page img:first-child {
		display: none;
	 }
	 .image-cover-page img:last-child {
		display: flex;
	 }
	 .info-cover-page h1, .info-cover-page h4{
		width: 100%;
		text-align: left;
	}
}

@media (min-width : 1440px){
	.container-cover-page{
		display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
	}
	.info-cover-page {
		width: 50%;
	}
	
	
	 
}