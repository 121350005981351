.multi_step_form {
  align-self: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 606px;

  h1 {
    align-self: start;
    width: 80%;
    text-align: center;
    font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1600 - 300)));
    margin-bottom: 1rem;
  }
}

.container_step_desktop {
  width: 100%;
}

/* Progress-bar */

.progressbar {
  display: flex;
  justify-content: space-between;
  counter-reset: step;
  margin-bottom: 2rem;
}
.progressbar li {
  list-style: none;
  display: inline-block;
  position: relative;
  text-align: center;
  line-height: 21px;
  color: var( --color-gray);
  font-size: 14px;
  padding-top: 20px;
}
.progressbar li:before {
  content: "";
  width: 20px;
  height: 20px;
  border: 2px solid var( --color-gray);
  border-radius: 100%;
  display: block;
  text-align: center;
  margin: 0 auto 10px auto;
  background-color: white;
  line-height: 17px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.progressbar li.current {
  color: #2491FD;
}

.progressbar li.current:before {
  background: white;
  color: #2491FD;
  border: 1px solid #2491FD;
}

.progressbar li:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: var( --color-gray);
  top: 9px;
  left: -50%;
  z-index: -1;
}
.progressbar li:nth-child(2) {
  width: 213px;
}
.progressbar li:last-child:after {
  width: 150%;
  left: -100%;
}
.progressbar li:first-child:after {
  content: none;
}
.progressbar li.active {
  color: #2491FD;
}
.progressbar li.active:before {
  color: var(--color-white);
  background-color: #2491FD !important;
  border: 2px solid #2491FD;
  animation: circleSuccess 1s ease-out;
}
.progressbar li.active + li:after {
  animation: progressBar 1s ease-out;
  animation-fill-mode: both;
  background-color: #2491FD;
}

.progressbar li span:after{
  content: '';
  display: block;
  position: absolute;
  top: 2px;
  left: 9px;
  width: 6px;
  height: 14px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  right: 0;
  left: 0;
  margin: auto;
}

/* animation progres bar */
@keyframes progressBar {
  0% {
    background-color: var(--color-marmol-white);
  }
  100% {
    background-color: #2491FD;
  }
}
@keyframes circleSuccess {
  0% {
    background-color: #fff;
    border: 2px solid var(--color-marmol-white);
  }
  100% {
    background-color: #2491FD;
    border: 2px solid #2491FD;
  }
}

@media only screen and (max-width: 768px) {
  .multi_step_form {
    h1 {
      align-self: center;
      width: 95%;
    }
  }
  .container_step_desktop {
    display: none;
  }
}
