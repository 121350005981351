.game-jam-social-networks-Wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  /* background-position: center bottom;
  background-size: contain;
  background-repeat: no-repeat; */

  padding: 100px 0 100px 0;

  .game-jam-social-networks {
    display: flex;
    align-items: center;
    flex-direction: column;
    z-index: 2;
    /* gap: 40px; */

    max-width: 812px;
    width: 100%;

    /* Logo */
    &__logo {
      margin-bottom: 40px;

      & > span {
        display: block !important;
      }
    }

    /* Description */
    &__description {
      margin-bottom: 40px;
    }

    /* Social */
    &__social {
      display: flex;
      gap: 53px;
      margin: 0 0 48px 0;

      & > a > span {
        display: block !important;
      }
    }

    /* Go Home */
    &__go-home {
      & > a {
        display: flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;
      }
    }
  }

  &__background {
    position: absolute;
    bottom: 0;
    right: -180px;

    & > span {
      display: block !important;
    }
  }
}

@media only screen and (max-width: 768px) {
  .game-jam-social-networks-Wrapper {
    padding: 80px 20px 220px 20px;
    overflow: hidden;

    .game-jam-social-networks {
      &__description {
        margin-bottom: 16px;
      }
    }

    &__background {
      position: absolute;
      bottom: 0;
      right: -80px;

      & > span {
        display: block !important;
      }
    }
  }
}
