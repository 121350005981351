.course-shape {
	width: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	gap: 24px;
	font-family: 'Roca One';
	font-style: normal;
	text-align: center;
	line-height: 30px;
	margin: 56px 0 30px 0;
	&__title {
		width: 100%;
		font-size: 38px;
		line-height: 48px;
		text-align: left;
		&--bold {
			font-weight: bold;
		}
	}
	&__direction {
		width: 100%;
		display: flex;
    flex-direction: column;
    align-items: center;
	}
	&__list-courses {
		width: 100%;
		overflow-x: auto;
	}
	&__container {
		width: 100%;
    display: flex;
    flex-direction: row;
    align-content: center;
    gap: 9px;  
	}
	&__content {
		width: 224.6px;
		/* height: 230px; */
	}
	&__square {
		width: 224.6px;
		height: 197px;
		border-radius: 16px;
		padding: 20px 17px;
	}
	&__triangle {
		height: 77px;
		position: relative;
		top: -24px;
		padding: 0 auto;
		z-index: -2;
	}
	&__icon {
		margin: 0 auto;
	}
	&__text {
		font-size: 21px;
		letter-spacing: -0.024em;
		&--bold {
			font-weight: bold;
		}
		&--uppercase {
			text-transform: uppercase;
		}
	}
	&__name {
		font-size: 28px;
		min-height: 90px;
		margin: 4px 0 8px 0;
		&--bold {
			font-weight: bold;
		}
	}
	&__bar {
		display: flex;
		align-items: center;
    justify-content: center;
		width: 100%;
		min-height: 56px;
		border-radius: 17px;
		padding: 16px;
	}
	&__curriculum {
		width: 351px;
		max-width: 100%;
	}
	&__path {
		font-size: 21px;
		line-height: 24px;
		letter-spacing: 0.2em;
		&--bold {
			font-weight: bold;
		}
		&--uppercase {
			text-transform: uppercase;
		}
	}
}

@media (min-width : 1024px){
	.course-shape {
		&__curriculum {
			width: 351px;
			min-width: 100%;
		}
		&__list-courses {
			overflow-x: hidden;
		}
		&__container {
			justify-content: center;
		}
		&__curriculum {
			min-width: 351px;
			width: 351px;
			max-width: 351px;
		}
	} 
}
