.item-schedule-vacation {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-radius: 16px;
	cursor: pointer;
	/* HOVER */
	&--hover-5_to_7 {
		&:hover {
			/* box-shadow: 0px 16px 40px #990f8720; */
			box-shadow: 0px 16px 40px rgba(153, 15, 135, 0.2);
			border: 2px solid #990f87 !important;
		}
	}
	&--hover-8_plus {
		&:hover {
			/* box-shadow: 0px 16px 40px #25936920; */
			box-shadow: 0px 16px 40px rgba(37, 147, 105, 0.2);
			border: 2px solid #259369 !important;
		}
	}
	&--hover-12_plus {
		&:hover {
			/* box-shadow: 0px 16px 40px #2772AB20; */
			box-shadow: 0px 16px 40px rgba(39, 113, 170, 0.2);
			border: 2px solid #2772AB !important;
		}
	}
	&__content {
		padding: 19px 32px;
		width: 100%;
		height: 100%;
		display: flex;
    justify-content: flex-start;
		align-items: flex-start;
		gap: 31px;
		cursor: pointer;
	}
	&__input {
		display: none;
		cursor: pointer;
	}
	&__check {
		width: 56px;
		height: 56px;
		border-radius: 50%;
		display: flex;
		justify-content: center;
    align-items: center;
		cursor: pointer;
	}
	&__details-group {
		width: calc(100% - 87px);
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: space-between;
	}
	&__container-first-group {
		display: flex;
    flex-direction: column;
	}
	&__first-group {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}
	&__first-text {
		font-size: 20px;
		line-height: 145%;
		&--bold {
			font-weight: bold;
		}
	}
	&__second-text {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 184px;
		height: 48px;
		border-radius: 16px;
		font-weight: bold;
		font-size: 18px;
		line-height: 25px;
	}
	&__third-text {
		font-size: 14px;
		line-height: 144%;
		&--bold {
			font-weight: bold;
		}
	}
}

@media (min-width : 480px){

}
@media (min-width : 768px){
	.item-schedule-vacation {
		&__container-first-group {
			flex-direction: row;
			gap: 20px;
		}
		&__details-group {
			flex-direction: row;
			align-items: center;
		}
		&__details-group {
		}
		&__content {
			align-items: center;
		}
		&__first-group {
			align-items: center;
		}
	}
}
@media (min-width : 1024px){
	
}
@media (min-width : 1280px){
	
}