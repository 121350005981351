.sidebar_Tile,
.mobil_sidebar {
  width: 251px;
  height: 100%;
  position: fixed;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  /* padding: 1.3rem 0.8rem; */
	box-shadow: var(--shadow-small);
	position: fixed;
	z-index: 110;
}
.sidebar_header {
  margin-bottom: 85px;
  a > picture > svg {
    width: 80px;
  }
}
.sidebar_menu {
  width: 100%;
  .menu_item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
    padding: 0.6rem 0.4rem 0.6rem 0.8rem;
    transition: 0.4s;
    cursor: pointer;
    p {
      margin-left: 0.6rem;
    }
  }
  .menu_item:hover {
		background: rgba(246, 240, 253, 0.65);
  }
  .menu_item.active {
    background: rgba(246, 240, 253, 0.65);
    font-weight: 700;
		color: #8838E3;
		
  }
}

.sidebar_footer {
	width: 100%;
	padding: 0 1.75rem 3.5rem 1.75rem;
  align-self: center;
  position: absolute;
  bottom: 0;
}
.sidebar_social-red {
	display: flex;
	justify-content: center;
	gap: 1.5rem;
	margin-top: 1rem;
}

@media only screen and (max-width: 768px) {
  .sidebar_Tile {
    display: none;
  }
  .mobil_sidebar {
    z-index: 200;
    width: 272px;
    height: 100%;
    position: fixed;
    background: linear-gradient(0deg, #af9ef8 11.47%, #84f2d3 99.98%);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    /* padding: 1.3rem 0.8rem; */

    .sidebar_header {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .close_icon {
        cursor: pointer;
        padding: 0 0 0 0.5rem;
      }
    }
  }
}
