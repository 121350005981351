.thank-page-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}
.thank-page-welcome-img {
  max-width: 11%;
}
.thank-page-title {
  font-size: 2.5rem;
  font-weight: 800;
  text-align: center;
}
.thank-page-subtitle {
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
}
.thank-page-content {
  margin: 0.8rem 0;
  width: 100%;
  font-size: 1.3rem;
  text-align: center;
}
.thank-page-content > a {
  font-weight: 800;
  text-decoration: underline;
}
.thank-page-content-button-wrapper {
  margin: 0.5rem 0;
}
.thank-page-payments-section {
  width: 100%;
  padding: 1.2rem 0 0 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
}
.thank-page-payments-section > a {
  margin-bottom: 1.2rem;
}

.thank-page-testimonials {
  margin: 3rem 0;
  .testimonials-grid {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    grid-template-rows: masonry;

    picture {
      height: 250px;
    }
  }
}

.button-wrapper {
  margin: 1rem 0;
}

@media only screen and (max-width: 768px) {
  .thank-page-welcome-img {
    max-width: 40%;
  }
  .thank-page-title {
    font-size: 2rem;
  }
  .thank-page-content {
    font-size: 1rem;
    text-align: justify;
  }
  .thank-page-testimonials {
    .testimonials-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media only screen and (max-width: 425px) {
  .thank-page-welcome-img {
    max-width: 50%;
  }
  .thank-page-content {
    font-size: 1rem;
    text-align: justify;
  }
  .thank-page-testimonials {
    .testimonials-grid {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
