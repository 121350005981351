.card-payment-vacation {
	width: 335px;
	display: flex;
	flex-direction: column;
	gap: 16px;
	position: relative;
	min-width: 332px;

	.button-component-effect {
		border: 1px solid #F0F0F0;
	}

	&:hover {
		.card-payment-vacation__container--hover-5_to_7 {
			box-shadow: 0px 12px 25px rgba(153, 15, 135, 0.2);
			border: 2px solid #990f87  !important;
		}

		.button-component-effect--contained-color-alternative-5_to_7 {
			transition: all 0.6s ease;
			border: 2px solid #FFE3FE !important;
			background: #FFE3FE !important;
		}
		
		.button-component-effect--contained-color-alternative-5_to_7 a {
			color: #990f87 !important;
		}

		.card-payment-vacation__container--hover-8_plus {
			box-shadow: 0px 12px 25px rgba(37, 147, 105, 0.2);
			border: 2px solid #259369 !important;
		}

		.button-component-effect--contained-color-alternative-8_plus {
			transition: all 0.6s ease;
			border: 2px solid #D0FFED !important;
			background: #D0FFED !important;
		}
		
		.button-component-effect--contained-color-alternative-8_plus a {
			color: #259369 !important;
		}

		.card-payment-vacation__container--hover-12_plus {
			box-shadow: 0px 12px 25px rgba(39, 113, 170, 0.2);
			border: 2px solid #2772AB !important;
		}

		.button-component-effect--contained-color-alternative-12_plus {
			transition: all 0.6s ease;
			border: 2px solid #CFE9FD !important;
			background: #CFE9FD !important;
		}
		
		.button-component-effect--contained-color-alternative-12_plus a {
			color: #2772AB !important;
		}
	}

	&__tooltip {
		width: 128px;
		height: 32px;
		position: absolute;
		top: -16px;
		right: 16px;
		border-radius: 8px;
		font-size: 14px;
		display: flex;
		align-items: center;
		justify-content: center;

		&--bold {
			font-weight: bold;
		}
	}

	&__container {
		width: 100%;
		height: 530px;
		border-radius: 16px;
		padding: 32px;

		/* HOVER */
		&--hover-5_to_7 {
			&:hover {
				/* box-shadow: 0px 16px 40px #990f8720; */
				box-shadow: 0px 12px 25px rgba(153, 15, 135, 0.2);
				border: 2px solid #990f87 !important;
			}
		}

		&--hover-8_plus {
			&:hover {
				/* box-shadow: 0px 16px 40px #25936920; */
				box-shadow: 0px 12px 25px rgba(37, 147, 105, 0.2);
				border: 2px solid #259369 !important;
			}
		}

		&--hover-12_plus {
			&:hover {
				/* box-shadow: 0px 16px 40px #2772AB20; */
				box-shadow: 0px 12px 25px rgba(39, 113, 170, 0.2);
				border: 2px solid #2772AB !important;
			}
		}
	}

	&__title {
		font-size: 24px;
		line-height: 145%;
		display: flex;
		align-items: center;
		gap: 16px;

		&--bold {
			font-weight: bold;
		}
	}

	&__hr {
		margin: 20px 0 24px 0;
	}

	&__text {
		font-size: 16px;
		line-height: 145%;

		&--bold {
			font-weight: bold;
		}
	}

	&__container-modules {
		display: flex;
		flex-direction: column;
		padding: 22px 0;
		gap: 24px;
	}

	&__modules {
		font-size: 20px;
		line-height: 125%;
		display: flex;
		align-items: center;
		gap: 16px;
	}

	&__icon-modules {
		width: 19px;
	}

	&__container-button {
		width: 100%;
	}

}

@media (min-width : 480px) {
	.card-payment-vacation {
		display: flex;
		flex-direction: column;
		gap: 16px;
		position: relative;
	}
}

@media (min-width : 768px) {}

@media (min-width : 1024px) {}

@media (min-width : 1280px) {}