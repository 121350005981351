.start_your_future_item {
  display: flex;
  flex-direction: column;
  align-items: normal;
  justify-content: space-between;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 2px 18px rgba(80, 43, 116, 0.03), 0px 15px 32px rgba(105, 46, 165, 0.07);
  border-radius: 16px;
  overflow: hidden;

  &--header {
    &_thumbnail {
      display: block;
      width: 100%;
      height: 120px;
      cursor: pointer;

      span {
        width: 100%;
        height: 100%;

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
    }

    &_type {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 32px;
      background: #2491fd;

      & > p {
        font-size: 16px;
        line-height: 0px;
        font-weight: 700;
        color: white;
      }
    }

    .free_course {
      background: #6e17cf;
    }
  }

  &--body {
    padding: 24px 24px 0 24px;

    &_title {
      font-size: 28px;
      line-height: 32px;
      font-weight: 700;
    }

    &_options {
      font-size: 14px;
      line-height: 24px;
      font-weight: 400;
      margin: 0 0 0.5rem 0;

      &_duration {
        display: flex;

        &_h {
          color: #b2b2b2;
          margin: 0 5px 0 0;
        }
      }

      &_include {
        display: flex;

        &_h {
          color: #b2b2b2;
          margin: 0 5px 0 0;
        }
      }
    }

    &_description {
      &_description {
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
      }
    }
  }

  &--footer {
    display: flex;
    justify-content: center;
    align-items: center;

    &_btn {
      padding: 27px 24px 24px 24px;
      text-decoration: underline;
      font-size: 16px;
      line-height: 21px;
      font-weight: 700;
      text-align: center;
    }
  }
}

@media only screen and (max-width: 768px) {
  .start_your_future_item {
    min-width: 310px;
    width: 310px;
    height: 100%;
  }
}
