.certificate-Tile {
  background-color: var(--color-white);
  border-radius: 20px;
  padding: 1.4rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  h4 {
    font-size: calc(16px + (18 - 16) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 700;
    margin-bottom: 1.3rem;
  }

  .btn-accordion {
    align-self: center;
    font-size: 14px;
    line-height: 23px;
    font-weight: 600;
    border: none;
    transition: 0.4s;
    padding: 0.2rem 1rem;
    border-radius: 10px;
  }
  .btn-accordion:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
  .btn-accordion:focus {
    outline: none;
  }

  .certificate-list {
    width: 100%;
  }

  .accordion__content {
    width: 100%;
    border-radius: 0 0 10px 10px;
    overflow: hidden;
    transition: max-height 0.6s ease;
  }
}
