.trust_us-Wrapper {
  max-width: 1080px;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 0 40px 0;
  &.gamejam {
    margin: auto;
    h2 {
      font-size: 51px;
      color: #AC84FF;
      margin-bottom: 50px;
    }
    h3,
    p {
      color: #DBD1FD;
    }
    path {
      fill: #AC84FF;
    }
    .accordion__content,
    button {
      background: #101445;
      padding-left: 40px;
      padding-right: 40px;
    }
    .faq_card_upd-Wrapper {
      border-top: 1px solid #342486;
      &:first-child {
        border-top:none;
      }
    }
    .faq_card_upd-Content {
      text-align: left;
    }
  }

  .company_list {
    width: 100%;
    display: flex;
    gap: 25px;
    overflow-x: auto;
    margin: 24px 0 0 0;

    .logo {
      width: 125px;
      object-fit: contain;
      object-position: 0 0;
    }
  }
}

@media only screen and (max-width: 768px) {
  .trust_us-Wrapper {
    margin: 0 1.5rem 5rem 1.5rem;
    width: auto;
    &.gamejam {
      h2 {
        font-size: 38px;
      }
    }

    .company_list {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      gap: 10px;

      .logo {
        width: 68.5px;
        height: 55px;
      }
    }
  }
}
