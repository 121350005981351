.certificate_card-Tile {
  width: 100%;
  display: grid;
  grid-template-columns: 25px 1fr 22px;
  align-items: center;
  transition: 0.4s;
  padding: 0.5rem 1%;
  border-radius: 10px;

  p {
    font-size: 15px;
    line-height: 23px;
    color: #616161;
    font-weight: 500;
    margin: 0;
  }
}

.certificate_card-Tile:hover {
  background-color: rgba(0, 255, 255, 0.1);
  cursor: pointer;

  p {
    color: var(--color-black);
  }
}
