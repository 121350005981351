.announcement-bar-Wrapper {
  position: fixed;
  top: 0;
  z-index: 90;

  display: block;
  width: 100%;
  min-height: 60px;
  box-sizing: border-box;

  background: #001F19;
  font-size: 20px;
  line-height: 40px;

  &__hidden {
    display: none !important;
  }

  .announcement-bar {
    min-height: inherit;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1250px;
    margin: auto;

    &_text-left {
      margin: 0 10px 0 0;
    }

    &_text-right {
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      position: relative;
      padding: 5px 10px 5px 10px;
      border-radius: 8px;
      transition: 0.25s linear;
			min-width: 160px;
			margin-right: 16px;

      &:hover {
        background: rgba(0, 0, 0, 0.1);
        border-radius: 8px;
      }

      & > .link {
        display: flex;
        justify-content: center;
        align-items: center;

        i {
          margin: 0 0 0 6px;
        }
      }
    }

    &_close-icon {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(0, -50%);
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 770px) {
  .announcement-bar-Wrapper {
    top: 0;
    min-height: auto;

    .announcement-bar {
      flex-direction: column;
      justify-content: center;
      align-items: center;

      padding: 8px 32px;
      text-align: center;

      &_text-left {
        margin: 0;
      }

      &_text-right {
        display: none;
      }

      &_close-icon {
        top: 20px;
        right: 12px;
      }
    }
  }
}
