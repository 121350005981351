.ui-chip {
	min-width: 114px;
	max-width: 184px;
	height: 24px;
	border-radius: 12px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	p {
		margin: 0 0 0 10px;
		text-align: center;
	}
}