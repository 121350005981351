.dashboard_hero {
	width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
	max-width: 1168px;

  .title_section {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      font-size: calc(22px + (30 - 22) * ((100vw - 300px) / (1600 - 300)));
      margin-bottom: 0;
    }
    a {
      width: auto;
      background-color: #10fcf0;
      color: var(--color-black);
      border-radius: 20px;
      padding: 5px 20px;
    }
  }

  p {
    font-size: calc(16px + (18 - 16) * ((100vw - 300px) / (1600 - 300)));
    color: var(--color-gray-dark);
    font-weight: 400;
    margin-top: 1rem;
  }
}
