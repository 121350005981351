.ui-card-recommended-course {
  width: 14.81rem;
  min-width: 14.81rem;
  min-height: 23.31rem;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  border-radius: 1rem;
  overflow: hidden;
  background: #ffffff;
  box-shadow: var(--shadow-small);

  &__container-image {
    width: 14.81rem;
    height: 7.5rem;
    position: relative;
  }
  &__container-image > .ui-tag-discount {
    position: absolute;
    top: 1rem;
    right: 0;
    z-index: 1;
  }

  /* Information */
  &__container-information {
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 0.5rem 1rem 1rem;
    gap: 1rem;

    & > .link {
      & > div {
        height: 32px;

        & > a > span {
          font-size: 16px;
          line-height: 16px;
        }
      }
    }
  }
  &__details-information {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__content-title {
    min-height: 5.31rem;
  }
  &__content-title > p:last-child {
    min-height: 3rem;
  }
  &__content-date {
    background: #faf9fb;
    padding: 0.75rem 0;
    display: flex;
    gap: 1rem;
    border-radius: 0.5rem;
    justify-content: space-around;
  }
  &__duration,
  &__start-date {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    /* width: 50%; */
  }
  &__duration-text,
  &__start-date-text {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
