.game-jam-awards-Wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 100px 0 100px 0;

  .game-jam-awards {
    display: flex;
    flex-direction: column;
    gap: 32px;

    max-width: 1080px;
    width: 100%;

    /* List */
    &__list {
      display: grid;
      align-items: flex-start;
      grid-template-columns: 344px 344px 344px;
      grid-template-rows: 666px;
      grid-template-areas: 'second first third';
      gap: 24px;

      &__item {
        display: flex;
        align-items: flex-end;
        position: relative;

        width: 100%;
        height: 100%;

        &__container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          background: #101445;
          border-radius: 12px;
          z-index: 10;

          width: 100%;
          height: 100%;

          &_img {
            display: flex;
            justify-content: center;
          }

          &_text {
            padding: 0 40px;
          }

          &--1 {
            gap: 12px;
            height: 318px;
          }
          &--2 {
            gap: 37px;
            height: 350px;
          }
          &--3 {
            gap: 16px;
            height: 318px;
          }
        }

        &_img-background {
          position: absolute;
          left: 50%;
          transform: translate(-50%, 0);

          &--1 {
            top: 160px;
          }
          &--2 {
            top: 0px;
          }
          &--3 {
            top: 185px;
          }
        }

        &--1 {
          grid-area: first;
        }
        &--2 {
          grid-area: second;
        }
        &--3 {
          grid-area: third;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .game-jam-awards-Wrapper {
    padding: 80px 20px 80px 20px;
    overflow: hidden;

    .game-jam-awards {
      gap: 32px;

      /* List */
      &__list {
        display: grid;
        align-items: flex-end;
        grid-template-columns: 1fr;
        grid-template-rows: 226px 226px 226px;
        grid-template-areas:
          'first'
          'second'
          'third';
        gap: 24px;
        padding: 0 46px;

        &__item {
          &__container {
            height: 100%;
            z-index: 0;

            &--1 {
              gap: 14px;
            }
            &--2 {
              gap: 14px;
            }
            &--3 {
              gap: 14px;
            }
          }

          &_img-background {
            position: absolute;
            left: auto;
            transform: translate(0, 0);

            &--1 {
              top: 30px;
              right: -30px;
              left: auto;
            }
            &--2 {
              top: 30px;
              left: -50px;
              transform: rotate(-9.3deg);
            }
            &--3 {
              top: 30px;
              left: -50px;
            }
          }
        }
      }
    }
  }
}
