.ui-card-free-course-progress {
	padding: 2px 6px;
	border-radius: 16px;
	background: #FFD755;
	min-width: calc(100% - 46px);

	&__header {
		display: flex;
		gap: 17px;
	}

	&__header-principal {
		width: 100%;
	}

	&__header-title-status {
		display: flex;
		justify-content: space-between;
	}

	&__body {
		display: flex;

	}

	&__column:first-child {
		width: auto;
	}

	&__column {
		width: 80%;
	}

	&__column:last-child {
		width: 10%;
	}

	&__container-image {
		width: 100px;
		height: 100px;
		border-radius: 50%;
		margin-top: 13px;
		background: #dcdcdc;
		overflow: hidden;

	}

	&__section-open {
		height: auto;
		transition: all 0.5s ease-in;
	}

	&__section-close {
		height: 0;
		overflow: hidden;
		transition: all 0.5s ease-in;
	}

	&__container-icon-close {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-top: 8px;
	}

	&__icon-open {
		margin-bottom: 20px;
		transform: rotate(0deg);
	}

	&__icon-close {
		margin-bottom: 20px;
		transform: rotate(180deg);
	}
}