.ui-body-card-course {
	padding: 16px 16px 33px 16px;
	display: flex;
	flex-direction: row;
	gap: 16px;
	&__column:first-child {
		width:auto;
		padding: 0;
	}
	&__column:last-child {
		display: flex;
		flex-direction: column;
		height: auto;
	}
	&__principal-info {
		display: flex;
	}
	&__project-info {
		p:nth-child(2) {
			min-height: 72px;
		}
}
	&__container-image-mobile {
		display: none;
	}
	&__container-image {
		width: 100px;
		height: 100px;
		border-radius: 50%;
		background: #dcdcdc;
		overflow: hidden;
	}
	&__container-items-section {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		gap: 0.5rem;
		button {
			a {
				display: flex;
				align-items: center;
				gap: 0.31rem;
			}
		}
		&--column {
			display: flex;
			flex-direction: column;
			gap: 0.5rem;
		}
		&--column-last {
			display: flex;
			flex-direction: column;
			padding: 0 0.87rem 0 1rem;
		}
	}
	&__container-section {
		display: flex;
		flex-direction: column;
	}
	&__content-section {
		display: flex;
		align-items: center;
		gap: 1.5rem;
	}
	&__container-file {
		display: flex;
		align-items: center;
		gap: 0.25rem;
		a {
			display: flex;
			align-items: center;
			gap: 0.25rem;
		}
	}
	&__card-end-course {
		width: 100%;
		display: flex;
		gap: 0.5rem;
		flex-direction: column;
		align-items: center;
		padding: 0.5rem;
		background: radial-gradient(106.81% 50% at 50% 50%, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.5) 100%);
		border-radius: 8px;
	}
	&__card-end-course-desc {
		display: flex;
		gap: 0.62rem;
		flex-direction: column;
		align-items: center;
		width: 100%;
		padding: 0 0.9rem;
	}
	&__button-filled {
		display: flex;
		align-items: center;
		gap: 0.25rem;
		background: white;
		border-radius: 6px;
		width: 250px;
		text-align: center;
		a {
			display: flex;
			align-items: center;
			gap: 0.25rem;
			justify-content: center;
			padding: 8px;
		}
	}
	&__button-outline {
		display: flex;
		align-items: center;
		gap: 0.25rem;
		border: 2px solid #FFFFFF;
		border-radius: 6px;
		width: 250px;
		text-align: center;
		a {
			display: flex;
			align-items: center;
			gap: 0.25rem;
			justify-content: center;
			padding: 8px;
		}
	}
}

@media only screen and (max-width: 420px) {
  .ui-body-card-course {
		flex-direction: column;
		&__column:first-child {
			display: none;
		}
		&__column {
			display: flex;
			flex-direction: column;
			width: 100% !important;
		}
		&__principal-info {
			display: flex;
			gap: 1rem;
		}
		&__container-image-mobile {
			display: flex;
			width: 88px;
			height: 88px;
			min-width: 88px;
			min-height: 88px;
			border-radius: 50%;
			margin-top: 0;
			background: #dcdcdc;
			overflow: hidden;
		}
		&__card-end-course {
			flex-direction: row;
			padding: 1rem;
			&-desc {
				width: auto;
				p {
					display: none;
				}
			}
		}
	}
}