.title-h2-home {
  font-size: 50px;
  text-align: center;
  font-weight: 700;
  line-height: 50px;
  padding-top: 220px !important;
}

.why_learn_ctc-Wrapper {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: 0 0 96px 0;

  .why_learn_ctc {
    &--list {
      max-width: 1080px;
    }

    &--contact {
      margin: 129px 0 0 0;
      max-width: 1080px;
    }
  }
}

.why_learn_ctc_item {
  display: grid;
  grid-template-rows: 262px;
  column-gap: 77.5px;
  align-items: center;

  max-width: 1080px;

  &:not(:last-child) {
    margin: 0 0 143px 0;
  }

  &--left {
    grid-template-columns: 420px 435px;
    grid-template-areas: 'img text';
  }

  &--right {
    grid-template-columns: 435px 420px;
    grid-template-areas: 'text img';
  }

  /* Text */
  &--text {
    grid-area: text;
  }

  /* Image */
  &--image {
    grid-area: img;

    & > span {
      width: 100%;
      height: 100%;

      & > img {
        width: 100%;
        height: 100%;
        animation: hide_show 0.25s cubic-bezier(0.47, 0, 0.75, 0.72);
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .title-h2-home {
    font-size: 38px;
    padding: 120px 0 0 0 !important;
  }
  .why_learn_ctc-Wrapper {
    margin: 0 0 80px 0;

    .why_learn_ctc {
      &--list {
        max-width: 1080px;
        width: 100%;
      }

      &--contact {
        margin: 48px 0 0 0;
        max-width: 1080px;
        width: 90%;
      }
    }
  }

  .why_learn_ctc_item {
    column-gap: 0;
    row-gap: 24px;
    grid-template-rows: auto;
    grid-template-columns: 1fr;
    grid-template-areas:
      'img'
      'text';

    &:not(:last-child) {
      margin: 0 0 48px 0;
    }

    /* Image */
    &--image {
      grid-area: img;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;

      & > span {
        width: 240px;
        /* height: ; */

        & > img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

@keyframes hide_show {
  0% {
    opacity: 0;
    visibility: hidden;
    transform: translateX(-15%);
  }
  100% {
    opacity: 1;
    visibility: visible;
    transform: translateX(0%);
  }
}
