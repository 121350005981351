.landing-404 {
	display: flex;
	min-height: calc(100vh - 538px);
	height: 100%;
	align-items: center;
	justify-content: center;
	padding: 160px 0 34px 0;
	margin-right: 160px;
	&__content-button {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 40px;
		margin-right: 160px;
	}
	&__content {
		display: flex;
		flex-direction: column;
		gap: 20px;
		max-width: 400px;
	}
	&__image {
		width: 530px;
		
	}
}

@media only screen and (max-width: 768px) {
  .landing-404 {
    flex-direction: column;
    align-items: center;
    justify-content: center;
		padding: 138px 0 34px 0;
		margin-right: 0;
		&__content-button {
			margin-right: 0;
		}
	}
}
