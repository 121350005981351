.benefits-wrapper {
  width: 100%;
  /*   border: solid 2px blue; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
	margin-top: 10rem;

	h4:last-child {
		margin-bottom: 3rem;
	}
}

.benefits-banner {
  margin-bottom: 1.2rem;
}

.benefits-list {
  /* border: solid 2px orange; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}

.benefits-list-items {
  width: 90%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: left;
  margin: 1rem 0;

	span {
		min-width: 200px;
	}

  h4 {
    margin-left: 2rem;
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 425px) {
  .benefits-wrapper {
    text-align: left;
  }
  .benefits-list-items {
    width: 90%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: left;
    margin: 1rem 0;

		span {
			min-width: 100px;
			margin-bottom: 1rem;
		}
		
		h4 {
			margin-left: 0;
			text-align: center;
			margin-bottom: 1rem;
		}
  }
  .benefits-list-items {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.5rem;
  }
}
