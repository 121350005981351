.item-range-age {
	width: 160px;
	height: 132px;
	padding: 12px;
	border-radius: 16px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	transition: var(--transition);
	cursor: pointer;
	box-shadow: var(--shadow-small);
	
	&--hover-5_to_7 {
		&:hover {
			box-shadow: var(--shadow-color_5-7);
			button {
				transition: var(--transition);
				background: rgba(0, 0, 0, 0.1);
			}
		}
	}
	&--hover-8_plus {
		&:hover {
			box-shadow: var(--shadow-color_8-11);
			button {
				transition: var(--transition);
				background: rgba(0, 0, 0, 0.1);
			}
		}
	}
	&--hover-12_plus {
		&:hover {
			box-shadow: var(--shadow-color_12);
			button {
				transition: var(--transition);
				background: rgba(0, 0, 0, 0.1);
			}
		}
	}
	&__name {
		font-size: 40px;
		line-height: 100%;
		&--bold {
			font-weight: bold;
		}
	}
	&__name-complement {
		font-size: 24px;
		line-height: 100%;
		&--bold {
			font-weight: bold;
		}
	}
}
.item-range-age .typography-h3 {
	display: flex !important;
}

.age-range-programs {
	width: 100%;
	display: flex;
	justify-content: center;
	flex-direction: row;
	gap: 24px;
	transition: all 0.6s ease;
}

@media (max-width : 768px){
	.item-range-age {
		width: 328px;
		height: auto;
		padding: 16px;
		flex-direction: row;
		align-items: center;
		
		.button-component {
			width: 62px;
			height: 56px;
		}
		.icon-left {
			display: none;
		}
	}
	.age-range-programs {
		flex-direction: column;
		align-items: center;	
		gap: 12px;
	} 
}
