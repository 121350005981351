.featured-video-tile {
  @apply relative cursor-pointer shadow-2xl transition-transform duration-300 overflow-hidden
    flex items-center justify-center;
  height: 450px;

  &::after {
    @apply absolute left-0 top-0 w-full h-full bg-black-light opacity-50;
    content: '';
  }

  img {
    @apply w-full;
  }

  i {
    @apply absolute text-black-light bg-white rounded-full transition-colors duration-300;
    font-size: 120px;
    left: calc(50% - 60px);
    top: calc(50% - 60px);
    z-index: 3;
  }

  &:hover {
    transform: scale(1.01);

    i {
      @apply bg-sky;
    }
  }
}

.featured-video-tile.always-vertical {
  height: 650px;
  max-width: 375px;
}

.featured-video-tile-mobile {
  @apply relative;
  left: -1.5rem;

  iframe {
    @apply w-screen;
    height: 680px;
  }
}

.featured-video-tile-modal {
  @apply fixed left-0 top-0 w-full h-full bg-black-light z-50 bg-opacity-50
    hidden items-center justify-center;

  button {
    @apply absolute border-0;
    top: 2rem;
    right: 2rem;

    i {
      @apply bg-white text-2xl rounded-full p-2 cursor-pointer;
    }

    &:hover i {
      @apply bg-black-light text-white;
    }
  }

  iframe {
    width: calc(100vw - 200px);
    max-width: 1200px;
    height: calc(100vh - 100px);
    max-height: 720px;
  }
}

.featured-video-tile-modal.active {
  @apply flex;
}

.feature-video-tile-modal {
  @apply fixed left-0 top-0 w-full h-full bg-black-light z-50 bg-opacity-50
  hidden items-center justify-center;
  max-height: calc(100vh - 76px);
  top: 76px;
  button {
    @apply absolute border-0;
    top: 5rem;
    right: 2rem;

    i {
      @apply bg-white text-2xl rounded-full p-2 cursor-pointer;
    }

    &:hover i {
      @apply bg-black-light text-white;
    }
  }

  iframe {
    transition: all 0.6s ease;
    width: calc(100vw - 20px);
    max-width: 1200px;
    height: calc(100vh - 200px);
    max-height: 720px;
  }
}

.feature-video-tile-modal.active {
  @apply flex;
}

@media (max-width: 768px) {
  .feature-video-tile-modal {
    @apply fixed left-0 top-0 w-full h-full bg-black-light z-50 bg-opacity-50
		hidden items-center justify-center;
    max-height: calc(100vh - 76px);
    top: 76px;
    button {
      @apply absolute border-0;
      top: 5rem;
      right: 2rem;

      i {
        @apply bg-white text-2xl rounded-full p-2 cursor-pointer;
      }

      &:hover i {
        @apply bg-black-light text-white;
      }
    }

    iframe {
      width: calc(100vw - 20px);
      max-width: 1200px;
      height: calc(100vh - 200px);
      max-height: 420px;
    }
  }
}
