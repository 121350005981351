.pagos_container {
  padding-top: 50px;
  padding-left: 100px;
  display: grid;
  grid-template-columns: 1fr 30%;
  grid-auto-rows: auto;
  grid-template-areas: 'pagos image';
  grid-gap: 64px;

  & > .pagos {
    grid-area: pagos;

    & > .dashboard_hero {
      & > .body {
        margin: 0px;
      }
    }

    & > .pagos__pago-container {
      margin-top: 40px;

      & > .container {
        & > .container__img {
          & > img {
            width: 105px;
            height: 80px;
          }
        }

        & > .information {
          margin-top: 16px;

          & > h4 {
            font-weight: 700;
            line-height: 42px;
          }

          & > p {
            font-weight: 400;
            line-height: 42px;
          }
        }
      }
    }
  }

  & > .image {
    grid-area: image;
    display: flex;

    & > img {
      width: 297px;
      height: 312px;
    }
  }
}

@media (max-width: 1024px) {
  .pagos_container {
    padding-top: 16px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 100px;
    grid-template-columns: 1fr;
    grid-template-areas:
      'image'
      'pagos';
    grid-gap: 31px;

    & > .pagos {
      & > .pagos__pago-container {
        margin-top: 40px;

        & > .container {
          & > .information {
            & > h4 {
              font-size: 21px;
            }

            & > p {
              font-size: 21px;
            }
          }
        }
      }
    }

    & > .faqs .faqs__faq-container {
      margin-top: 15px;
    }

    & > .image {
      justify-content: center;

      & > img {
        width: 297px;
        height: 312px;
      }
    }
  }
}
