.schedule-vacation {
	display: flex;
	flex-direction: column;
	gap: 32px;
	/* transition: transform 0.6s ease; */
	&__details-title {
		font-size: 32px;
		line-height: 125%;
		padding-top: 56px;
		&--bold {
			font-weight: bold;
		}
	}
	&__empty-schedule {
		text-align: center;
		color: #52575e;
		font-weight: 300;
	}
}