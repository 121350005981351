.ui-stepper {
	display: flex;
	padding: 0 24px;    
	align-items: center;
	justify-content: space-between;
	&__container-step {
		display: flex;
		flex-direction: row;
		justify-content: center;
		margin-bottom: 16px;
		gap: 4px;
	}
	&__circle-arrow {
		width: 28px;
		height: 28px;
		min-width: 28px;
		min-height: 28px;
		border-radius: 50%;
		background: #DFDFDF;
		display: flex;
		align-items: center;
    justify-content: center;
		transform: rotate(180deg);
		margin-top: 16px;
		cursor: pointer;
		&--left {
			transform: rotate(0deg);
		}
	}
}

@media only screen and (max-width: 420px) {
	.ui-stepper {
		justify-content: center;
		&__circle-arrow {
			display: none;
			&--left {
				transform: rotate(0deg);
			}
		}
	}
}