.helper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: auto;
  grid-gap: 20px;
  background-color: #ffffff;
  margin-top: 40px;
  border-radius: 16px;
  padding: 24px;
  box-shadow: 0px 2px 18px rgba(0, 0, 0, 0.03), 0px 15px 32px rgba(0, 0, 0, 0.07);

  & > .info {
    & > .h4 {
      margin-bottom: 4px;
      color: #8c30f2;
      font-weight: bold;
    }

    & > .body {
      margin-bottom: 0px;
    }
  }

  & > .actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    & > .link {
      width: 218px;
      border-radius: 8px;
    }
  }
}

@media (max-width: 1024px) {
  .helper {
    grid-template-columns: 1fr;
    grid-gap: 24px;

    & > .info {
      text-align: center;
      padding: 0 30px;

      & > .h4 {
        margin-bottom: 4px;
        color: #8c30f2;
        font-weight: bold;
      }

      & > .body {
        margin-bottom: 0px;
      }
    }

    & > .actions {
      display: flex;
      justify-content: center;
      align-items: center;

      & > .link {
        width: 100%;
        border-radius: 8px;
      }
    }
  }
}

body {
  overflow-x: hidden;
}
