.ui-container-tab-line {
	display: flex;
	flex-direction: column;
	max-width: 100%;
	padding: 3px;
	overflow: hidden;

	&__section-path-carousel {
		max-width: 100%;
		overflow: hidden;
		border-radius: 16px;
		box-shadow: var(--shadow-small);
		transition: all 0.5s ease-in;
	}

	&__container-items-tab {
		width: 100%;
		overflow-x: scroll;
	}

	&__section-paths-details {
		display: flex;
		flex-direction: column;
		gap: 38px;
	}

	&__section-open {
		height: auto;
		transition: all 0.5s ease-in;
	}

	&__section-close {
		height: 0;
		overflow: hidden;
		transition: all 0.5s ease-in;
	}

	&__container-icon-close {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	&__icon-open {
		margin-bottom: 20px;
		transform: rotate(0deg);
	}

	&__icon-close {
		margin-bottom: 20px;
		transform: rotate(180deg);
	}

	&__container-items-tab::-webkit-scrollbar {
		background: #fff;
		border: 1px solid white;
	}

	&__container-items-tab::-webkit-scrollbar-track {
		border: 1px solid white;

	}

	&__container-items-tab::-webkit-scrollbar-thumb {
		background: #00000035;
		;
		border-radius: 20px;
		border: 5px solid white;
	}

	&__items-tab {
		min-width: 100%;
		width: max-content;
		display: flex;
		flex-direction: row;
		/* justify-content: space-evenly; */
		justify-content: flex-start;
		border-bottom: 1px solid #000000;
	}

	/* 	&__items-tab::-webkit-scrollbar {
    -webkit-appearance: none;
		border: 1px solid blue;
} */
	&__path-name-header-close {
		width: 100%;
		margin-top: 1.5rem;

	}

	&__path-name-header-open {
		width: 100%;
		margin-top: 1.5rem;
		margin-bottom: 2rem;
	}

	&__item-content {
		background: #FFFFFF;
		border-radius: 16px;
		display: flex;
		gap: 16px;
	}

	&__item-content-details {
		background: #FFFFFF;
		border-radius: 16px;
		display: flex;
		gap: 16px;
		justify-content: space-between;
		padding: 27px 29px 30px 29px;
	}

	&__path-detail {
		display: flex;
		flex-direction: row;
	}

	&__item-content-card {
		display: flex;
		gap: 16px;
		padding: 0 22px;
		min-height: 422px;
	}

	&__card-left {
		min-width: 32px;
		height: 100%;
		background: #FF900D;
		border-radius: 0 16px 16px 0;
		position: relative;
		left: -26px;
		margin-right: -26px;
	}

	&__card-right {
		min-width: 32px;
		height: 100%;
		background: #FF900D;
		border-radius: 16px 0 0 16px;
		position: relative;
		right: -26px;
		margin-left: -26px;
	}
}

@media only screen and (max-width: 420px) {
	.ui-container-tab-line {

		&__items-tab {
			justify-content: flex-start;
			overflow-x: scroll;
		}

		/* &__path-name-header {
			width: 100%;
			margin-top: 1.5rem;
			margin-bottom: 1rem;
		}
		&__item-content {
			background: #FFFFFF;
			border-radius: 16px;
			display: flex;
			gap: 16px;
		} */
		&__item-content-details {
			flex-direction: column;
			align-items: center;
		}

		&__path-detail {
			flex-direction: column;
			align-items: center;
		}

		/* &__item-content-card {
			background: pink;
			display: flex;
			gap: 16px;
			padding: 0 22px;
	
		}
		&__card-left {
			min-width: 32px; 
			height: 100%; 
			background: #FF900D; 
			border-radius: 0 16px 16px 0; 
			position: relative; 
			left: -26px; 
			margin-right: -26px;
		}
		&__card-right {
			min-width: 32px; 
			height: 100%; 
			background: #FF900D; 
			border-radius: 16px 0 0 16px; 
			position: relative; 
			right: -26px; 
			margin-left: -26px;
		} */
	}
}