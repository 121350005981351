.link_dropdown-Wrapper {
  margin-bottom: 0px;
  min-height: 24px;
  z-index: 99;

  .accordion {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &_title {
      font-size: 21px;
      font-weight: 700;
      line-height: 24px;
    }
  }

  .accordion__icon {
    margin-left: auto;
    transition: transform 0.6s ease;
  }

  .rotate {
    transform: rotate(180deg);
  }

  .accordion__content {
    width: 100%;
    margin-top: 0;
    padding: 0 0 0 1.5rem;
    border-radius: 0 0 10px 10px;
    background-color: transparent;
    overflow: auto;
    transition: all 0.6s ease;

    .link_dropdown-List {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      a {
        font-size: 16px;
        padding: 0.5rem 0;
        border-bottom: solid 2px #ffffff;

        &:hover {
          font-weight: 700;
          border-bottom: solid 1px #000000;
        }
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }

    &--active {
      margin-top: 1.5rem;
    }
  }
}
