/* Sidebar */
.sidebar-Wrapper {
  position: fixed;
  top: 0;
  left: 100%;

  height: 100vh;
  width: 100%;
  background: white;
  z-index: 999;
  transition: all 0.25s ease-in-out;
  padding: 90px 20px 52px 20px;

  li::marker {
    content: none;
  }

  .sidebar {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__open {
      position: absolute;
			top: 78.44px;
			left: -69.44px;
    }

    &__container {
      &__list {
        display: flex;
        flex-direction: column;
        gap: 25px;

        border-bottom: 1px solid #e4e4e4;
        padding: 0 0 21px 0;
      }
      .choose_country span {
        justify-content: flex-start;
      }
      .choose_country .user-dropdown__options {
        border: none;
        box-shadow: none;
        border-radius: 0px;
        top: 20px;
        left: 8px;
      }

      &__user {
				padding-top: 20px;
        &_text {
          font-size: 18px;
          font-weight: 600;
          line-height: 24px;
          position: relative;
          transition: .25s;
         
        }
      }
    }

    &__test {
      display: flex;
      justify-content: center;

      & > .button-component {
        width: 80%;
      }
    }

    &__close {
      position: absolute;
      top: 22.44px;
      right: 16.44px;
    }
  }

  &--active {
    left: 0;
    z-index: 9999;
  }
}
