.ui-container-tab-child {
	width: 100%;
	background: #EFEAF6;
	&__content-tabs {
		display: flex;
		justify-content: space-between;
	}
	&__items-tab {
		padding: 24px 98px 0 17px;
		display: flex;
	}
	&__item-content {
		background: #FFFFFF;
	}
	&__right {
		height: 60px;
		display: flex;
		align-items: center;
		margin-top: auto;
		margin-right: 1.5rem;
	}
	&__item-content-footer {
		width: 100%;
		padding: 2rem 1.5rem 3.5rem 1.5rem;
		display: flex;
		flex-direction: column;
		gap: 19px;
	}
	&__footer-info {
		margin-left: auto;
	}
}

@media only screen and (max-width: 420px) {
  .ui-container-tab-child {
		max-width: 100%;
		
		/* &__content-tabs {
			display: flex;
			justify-content: space-between;
		}
		&__items-tab {
			padding: 24px 98px 0 17px;
			display: flex;
		}
		&__item-content {
			background: #FFFFFF;
		} */
		&__right {
			display: none;
		}
		/* &__item-content-footer {
			width: 100%;
			padding: 2rem 5.13rem 3.5rem 1.5rem;
			display: flex;
			flex-direction: column;
			gap: 19px;
		}
		&__footer-info {
			margin-left: auto;
		} */
	}
}