.technical-card {
	&__vacation-wrapper{
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	&__icon-vacation {
		min-width: 19px;
    margin-left: auto;
    transition: transform 0.6s ease;
		&--rotate {
			transform: rotate(180deg);
		}
	}
	&__vacation-title {
		font-size: 32px;
		line-height: 42px;
		font-weight: bold;
		width: 100%;
		display: flex;
		align-items: center;
		padding: 19px 33px;
		border-radius: 20px 20px 0 0;
	}
	&__content-vacation {
		display: flex;
		flex-direction: column;
		gap: 60px;
    width: 100%;
    padding: 0 20px;
    background: white;
    overflow: hidden;
    transition: max-height 0.6s ease;
	}
	&__vacation-content {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 42px;
	}
	&__icons_container {
		width: auto;
		display: flex;
		gap: 14px;
	}
	&__icon {
		width: 72px;
		height: 72px;
		border-radius: 10px;
		background: #fff;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	&__text {
		font-family: 'Roca One';
		font-weight: 300;
		font-size: 18px;
		line-height: 28px;
	}
	&__show-details {
		display: flex;
		width: 100%;
		border-radius: 0 0 20px 20px;
		padding: 12px 33px;
		justify-content: space-between;
	}
	&__accordion-vacation {
		width: 20px;
    border-radius: 10px;
    display: flex;
    grid-template-columns: 10% 1fr;
    justify-items: center;
    align-items: center;
    justify-content: center;
    transition: all 0.6s ease;
    outline: none;
		&:focus {
			outline: none;
		}
	}
}

@media (min-width : 480px){

}
@media (min-width : 768px){
	
}
@media (min-width : 1024px){
	
}
@media (min-width : 1280px){
	.technical-card {
		&__vacation-content {
			flex-direction: row;
		}
		&__content-vacation{
			padding: 0 68px;
		}
	}
}
