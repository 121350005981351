/* Header */
.header-Wrapper {
	flex-direction: column;
	position: fixed;
	display: flex;
	align-items: center;
	background: transparent;
	z-index: 999;
	height: 102px;
	width: 100%;
	margin: 27px 0 0;
	/* overflow: hidden; */

	.header {
		display: flex;
		align-items: center;

		width: 100%;
		margin-left: 16px;
		height: 100%;

		max-width: 1232px;
		justify-content: space-between;
		margin: 0 auto;
		background: #ffffff;
		border-radius: 50px;
		box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
		padding: 0 39px 0 56px;

		/* Shadow */
		&--shadow {
			max-width: inherit;
			border-radius: 0;
			box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
		}

		&__content {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			width: 100%;
			max-width: 1137px;
			margin: 0 auto;
			height: 100%;
		}


		/* Logo */
		&__logo>a>picture>svg {
			margin: 0;
		}

		&__links {
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 56px;
			height: 100%;

			/* Navbar */
			&_navbar {
				display: flex;
				gap: 40px;
				height: 100%;

				&_link {
					display: flex;
					justify-content: center;
					align-items: center;
					gap: 6px;
					cursor: pointer;

					&>li {
						font-size: 18px;
					}

				}

				& div>span {
					font-size: 18px;
				}
			}
		}

		&__login {
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 56px;
			height: 100%;

			/* Navbar */
			&_navbar {
				gap: 40px;
				width: 110px;
				height: 48px;
				border: 1px solid black;
				border-radius: 25px;

				&_link {
					display: flex;
					justify-content: center;
					align-items: center;
					gap: 6px;
					cursor: pointer;

					&>li {
						font-size: 18px;
					}

				}

				& div>span {
					font-size: 18px;
				}
			}
		}

		/* Access */
		&__access {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 16px;

			&>.signup {

				cursor: pointer;
			}

			&>.button-component>a {
				font-size: 16px;
			}
		}
		
		/* Access */
		&__access-alianza {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 16px;
			width: 100%;
			
			.choose_country .user-dropdown__options {
				left: -42px;
				top: 60px;
				width: 213px;
			}
		}

		&__logo-alianza {
			width: calc(100% - 150px);
			display: flex;
			justify-content: center;

			img {
				height: 63px;
			}
		}
	}
}

@media only screen and (max-width: 769px) {

	/* Header */
	.header-Wrapper {
		height: 76px;
		width: 100%;
		overflow: hidden;

		.header {
			width: calc(100% - 30px);
			margin-right: 15px;
			margin-left: 15px;
			height: 76px;

			display: flex;
			align-items: center;

			position: fixed;
			background: white;
			z-index: 999;
			padding: 0 24px 0 24px;
			border-radius: 50px;
			justify-content: flex-start;

			/* Shadow */
			&--shadow {
				max-width: inherit;
				border-radius: 0;
				box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
				width: calc(100%);
				margin-right: 0;
				margin-left: 0;
			}

			/* Logo */
			&__logo>a>picture>svg {
				margin: 0;
				width: 66px;
				height: 32px;
			}

			&__access {
				display: flex;
				align-items: center;
				gap: 26px;
				height: 100%;

				&>.button-component {
					height: 32px;
					padding: 9px 16px 7px;

					&>a>span {
						line-height: 16px;
						font-size: 14px;
					}
				}

				/* & > svg {
          width: 80px;
        } */
			}

			&__access-alianza {
				display: flex;
				align-items: center;
				gap: 26px;
				height: 100%;

				.choose_country .user-dropdown__options {
					left: -112px;
					top: 46px;
					width: 185px;
				}
				span  {
					p	{
							display: none;
					}
				}
			}

			&__logo-alianza {
				width: calc(100% - 75px);
				display: flex;
				justify-content: center;

				img {
					height: 32px;
				}
			}
		}
	}
}

.choose_country {
	position: relative;
	cursor: pointer;

	span {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	i {
		display: block;
		margin-left: 8px;
		margin-right: 6px;
		font-style: normal;
	}

	.user-dropdown__options {
		left: 0;
		top: 60px;
		width: 350px;

		.user-dropdown__option {
			justify-content: flex-start;
		}
	}
}