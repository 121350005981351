.building_process_alert {
  place-self: center;
  width: 100%;
  max-width: 1200px;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 0px;

  h2 {
    font-size: calc(20px + (50 - 20) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 500;
    width: auto;
    margin-bottom: 2rem;
  }
  img {
    justify-self: center;
    padding-left: 20%;
    width: 85%;
  }
}
