.user-dropdown {
	position: relative;
	height: 100%;
	width: auto;

	&__login-navbar {
		svg {
			padding-top: 2px;
		}
	}

	/* Trigger */
	&__trigger {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 4px;
		padding: 11px 20px;

		&:active,
		&:focus {
			outline: 0;
			border: none;
			-moz-outline-style: none;
		}

		&_username {
			font-size: 15px;
			font-weight: 700;
			line-height: 21px;
			text-decoration: underline;
		}

		&_logo {
			display: flex;
			justify-content: center;
			align-items: center;

			width: 24px;
			height: 24px;
			background: #1ca3ff;
			border-radius: 50%;

			&_word {
				color: white;
				font-size: 15px;
				font-weight: 700;
				line-height: 15px;
				padding: 0 0 2px 0;
			}
		}
	}

	/* Options */
	&__options {
		position: absolute;
		background: white;
		z-index: 999;
		font-size: 16px;
		top: 50px;
		left: -98px;
		width: 206px;
		z-index: 9;
		border-radius: 16px;
		overflow: hidden;
		padding: 14px 0 14px 0;
		box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1), 0px 15px 40px rgba(0, 0, 0, 0.08);

		a,
		button {
			display: flex;
			justify-content: flex-start;
			width: 100%;
			transition: 0.4s;
		}
	}

	/* Options login home*/
	&__options-login {
		position: absolute;
		background: white;
		z-index: 999;
		font-size: 16px;
		top: 80px;
		right: 135px;
		width: 235px;
		z-index: 9;
		border-radius: 16px;
		overflow: hidden;
		padding: 14px 0 14px 0;
		box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1), 0px 15px 40px rgba(0, 0, 0, 0.08);

		a,
		button {
			display: flex;
			justify-content: flex-start;
			width: 100%;
			transition: 0.4s;
		}
	}

	&__option {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 8px;
		padding: 10px 24px 10px 24px;
		color: #171A1A;

		&:hover {
			background: #F7F6FD;
		}
	}

	&__button-logout {
		color: #C00808;

		&:focus {
			outline: none;
		}
	}

	&__icon-desktop {
		min-width: 16px;
		width: 16px;
	}
}

@media only screen and (max-width: 769px) {}