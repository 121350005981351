.ui-card-certificate-details {
	display: flex;
	background: #000000;
	border-radius: 0 0 8px 8px;
	height: 61px;
	padding: 0 53px;
	&__image-container {
		width: 118px;
		height: 107px;
		position: relative;
		border-radius: 8px;
		overflow: hidden;
		transform: rotate(8deg);
		top: -20px;
	}
	&__details-text {
		display: flex;
		align-items: center;
		width: calc(100% - 171px);
		margin-left: 25px;
		gap: 15px;
	}
}