.purchase_flow-Wrapper {
  max-width: 1160px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 12.5rem auto 4rem;
  overflow: hidden;
  align-items: self-start;
  &.user-recruitment,
  &.user-recruitment-with-account {
    .price-section,
    .description_purchase-Prices,
    .cupon-section,
    .container_step_desktop,
    .go-to-login,
    .transaction-title {
      display: none;
    }
  }

  .description_purchase-Wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    max-width: 580px;
  }
  .form_step-Wrapper {
    width: 100%;
    display: flex;
    /* justify-content: center; */
    min-width: 606px;
    animation: hide_show 0.25s cubic-bezier(0.47, 0, 0.75, 0.72);
  }

  .info_step-Mobile {
    width: 100%;
    display: none;
    grid-template-columns: repeat(auto-fit, minmax(20px, 1fr));
    align-items: center;

    h4 {
      font-weight: bold;
    }

    button {
      justify-self: end;
    }
    
    & button:focus {
      outline: none;
    }

    .chevron-button {
      margin-right: 10px;
    }
  }

  .container_step-Mobile {
    display: none;
    margin: 0.2rem auto 2.5rem auto;
    padding: 0;
    width: 100%;
    height: 15px;
    overflow: hidden;
    background: var(--color-gray-white);
    border-radius: 5px;
    .bar {
      position: relative;
      float: left;
      min-width: 1%;
      height: 100%;
      background: #2491fd;
    }
  }
}

.form_step-Wrapper-container {
  display: flex;
}

@media only screen and (max-width: 768px) {
  .form_step-Wrapper-container {
    flex-direction: column-reverse;
    margin-top: -50px;
  }
  .purchase_flow-Wrapper {
    margin: 7.5rem auto 3rem;
    padding-bottom: 4rem;
    display: grid;
    grid-template-columns: 1fr;
    width: 90%;
    .info_step-Mobile {
      display: flex;
    }

    .container_step-Mobile {
      display: inline;
    }

    .description_purchase-Wrapper {
      margin-top: 40px;
    }

    .form_step-Wrapper {
      min-width: 100%;
    }
  }
}

.w-100 {
  width: 100%;
}
.w-80 {
  width: 80%;
}
.m-auto {
  margin: auto;
}
.payment_success {
  text-align: center;
}
.payment_success .container_step_desktop {
  display: none;
}
.purchase_flow-Wrapper.payment_success .description_purchase-Wrapper {
  display: none;
}
.purchase_flow-Wrapper.payment_success .form_step-Wrapper {
  align-items: center;
}
.payment_success .form_step-Wrapper .payment_content-Wrapper {
  justify-content: center;
  align-items: center;
}

@keyframes hide_show {
  0% {
    opacity: 0;
    visibility: hidden;
    transform: translateX(-15%);
  }
  100% {
    opacity: 1;
    visibility: visible;
    transform: translateX(0%);
  }
}
