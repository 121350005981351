.side-modal {
  @apply fixed left-0 top-0 w-screen bg-black bg-opacity-50;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

.side-modal__content {
  @apply bg-white min-h-screen ml-auto overflow-y-auto;
  min-height: 100%;
  max-height: 100%;
  max-width: 720px;
}

.side-modal__header {
  @apply flex p-8 px-4 items-center relative;

  button {
    @apply mr-2;
  }

  @screen md {
    @apply px-20 pt-12;

    button {
      @apply absolute;
      top: 3.8rem;
      left: 1.8rem;

      i {
        @apply text-3xl;
      }
    }
  }
}

.side-modal__body {
  @apply px-6;

  @screen md {
    @apply px-20;
  }
}
