.outline_card-Wrapper {
  width: 20.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  transition: 0.4s;

  & > .outline_description {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    font-family: 'Roca One';
    color: #616161 !important;
  }

  & > div {
    margin: 0 0 24px 0;
  }
}

.outline_card_w_images-Wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-radius: 10px;
  transition: 0.4s;
  width: 100%;

  p {
    color: #616161;
  }

  span {
    width: 96px;
    margin-bottom: 1rem;

    img {
      width: 100% !important;
    }
  }
}

@media only screen and (max-width: 768px) {
  .outline_card-Wrapper {
    width: auto;
    max-width: 17.6rem;

    & > div {
      margin: 0 0 4px 0;
    }
  }

  .outline_card_w_images-Wrapper {
    min-width: 125px;

    span {
      /* width: 80px; */
    }
  }
}
