.our-teachers-Wrapper {
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 9.5rem;

  .our-teachers {
    max-width: 1080px;
    width: 100%;

    &-list {
      width: 100%;
      display: grid;
      column-gap: 3.5rem;
      grid-template-columns: repeat(auto-fit, 180px);
      justify-content: center;
      justify-items: center;
      align-items: start;
      margin: 24px 0 32px 0;
    }
  }

  a {
    color: #000000;
    font-weight: bold;
    font-size: 21px;
    line-height: 32px;
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
    text-decoration: underline;
  }

  .link--txt-only {
    padding: 0 auto !important;
  }
}

@media only screen and (max-width: 768px) {
  .our-teachers-Wrapper {
    margin: 0 0 4.2rem;

    .our-teachers {
      max-width: 1080px;
      width: 100%;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &-list {
        width: 95%;
        display: flex;
        justify-content: flex-start;
        column-gap: 1.5em;
        align-items: start;
        overflow-x: scroll;
        margin: 32px -5% 32px 0;
        padding: 0 8px 0 10px;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
}
