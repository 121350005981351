.container-video-card{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	grid-gap: 1.87rem;
}
/* ITEM VIDEO */
.item-video {
	width: 100%;
	height: auto;
	padding: 40px 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	background: #FFFFFF;
	border-radius: 20px;
	box-shadow: 0px 16px 40px rgba(14, 86, 124, 0.2);
}
.content-item-video {
	width: 100%;
}
.content-item-video iframe {
	width: 100%;
	height: 11.75rem;
	border-radius: 15px;
	margin-bottom: 40px;
}
.container-info-video {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.content-video-card {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	grid-gap: 10px;
	margin-bottom: 27px;
}
.content-video-card img {
	width: auto;
	height: 28px;
}
.content-video-card h4 {
	font-weight: 300;
	text-align: center;
	letter-spacing: 0.095em;
	text-transform: uppercase;
	color: #616161;
}
.content-video-card h2 {
	font-weight: bold;
	font-size: 40px;
	line-height: 46px;
	color: #000000;
}
.content-video-card p {
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 27px;
	text-align: center;
	color: #616161;
	letter-spacing: -0.01em;
}
.button-video-card {
	width: 236px;
}
.button-video-card .link--black-w-icon {
	padding: 10px 2rem;
}

/* PROGRAM CARD */
.container-program {
	  width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 1.87rem;
    flex-wrap: wrap;
    justify-content: space-evenly;
}
.card-program{
	width: 357px;
	height: 90px;
	border-radius: 14px;
	padding: 8px;
	display: flex;
	align-items: center;
	gap: 1.25rem;
}
.card-program-image{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 82px;
	height: 74px;
	border-radius: 10px;
	padding: 9px 13px;
}

.card-program h4{
	width: 230px;
	font-weight: bold;
	font-size: 20px;
	line-height: 27px;
}
.description-program{
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 28px;
	color: #616161;
}
.description-program p{
	text-align: center;
}

.container-video-card .trust_us-Wrapper h3 {
	font-family: 'Roca One';
	font-style: normal;
	font-weight: normal;
	font-size: 1.13rem;
	line-height: 1.14rem;
	color: #616161;
}
.container-video-card .trust_us-Wrapper .company_list {
	display: flex;
	overflow-x: hidden;
	-ms-scroll-snap-type: x mandatory;
	scroll-snap-type: x mandatory;
	scroll-behavior: smooth;
	-webkit-overflow-scrolling: touch;
	justify-content: space-evenly;
	padding: 1rem;
	background-color: #ffffff;
	-moz-column-gap: 2rem;
	grid-column-gap: 2rem;
	column-gap: 2rem;
	flex-direction: row;
	flex-wrap: wrap;
	margin: 2rem 0 0 0;
}

@media (min-width : 768px){
	.content-item-video, .container-info-video {
		width: calc(100% - 1rem);
	}
	.container-video-card{
		display: flex;
		flex-direction: column;
		align-items: center;
		grid-gap: 3rem;
	}
	/* ITEM VIDEO */
	.item-video {
		width: 100%;
		height: auto;
		padding: 40px;
		flex-direction: row;
		justify-content: space-between;
		gap: 2rem;
	}
	.content-item-video iframe {
		width: 100%;
		height: 290px;
		border-radius: 15px;
		margin-bottom: 40px;
	}
	.content-video-card {
		align-items: flex-start;
	}
	.content-video-card p {
		text-align: initial;
	}
	/* PROGRAM CARD */
	.description-program{
		width: 100%;
	}
	.description-program p{
		text-align: left;
	}  
	.container-video-card .trust_us-Wrapper h3 {
		font-size: 1.6rem;
		line-height: 2.5rem;
	}
}
@media (min-width : 960px){
	.container-program {
		align-items: center;
    grid-gap: 40px;
		flex-wrap: nowrap;
		grid-gap: 1.87rem;
	}
	.card-program-image {
		width: 119px;
		height: 107px;
	}
	.card-program {
		width: 521px;
		height: 131px;
		padding: 12px;
	}
	.card-program h4 {
		width: 337px;
		font-size: 28px;
	}
}


@media (min-width : 1440px){
	.container-video-card{
		width: 1153px;
		grid-gap: 3.75rem;
	}
/* ITEM VIDEO */
	.item-video {
		width: 1153px;
		height: 387px;
		padding: 40px;
		flex-direction: row;
		justify-content: space-between;
	}
	.content-item-video {
		width: 632px;
		height: 100%;
	}
	.content-item-video iframe {
		width: 100%;
		height: 307px;
		border-radius: 15px;
		margin-bottom: 40px;
	}
	.container-info-video {
		width: 377px;
		flex-direction: column;
		align-items: flex-start;
	}
	.content-video-card {
		align-items: flex-start;
	}
	.content-video-card p {
		text-align: initial;
	}
	/* PROGRAM CARD */
	.container-program {
		width: 1155px;
		flex-direction: row;
		align-items: center;
		grid-gap: 40px;
	}
	.card-program-image {
		width: 119px;
		height: 107px;
	}
	.card-program {
		width: 521px;
		height: 131px;
		padding: 12px;
	}
	.card-program h4 {
		width: 337px;
		font-size: 28px;
	}
	.description-program{
		width: 594px;
	}
	.description-program p{
		text-align: left;
	}
}
