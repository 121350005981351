.blog-hero {
  left: -1.5rem;

  @screen lg {
    @apply w-full left-0;
  }
}

.blog-hero__image {
  object-fit: cover;
  @apply mb-6 relative flex items-center justify-center bg-black overflow-hidden;

  z-index: 1;
  min-height: 300px;
  max-height: 350px;

  @screen lg {
    margin-bottom: -4rem;
    min-height: 408px;
  }
}

.blog-hero__title {
  width: 100%;
  @apply px-6 mb-2;

  & > p {
    @apply mb-3 mt-4;
  }

  @screen lg {
    @apply bg-sky px-10 pr-12 py-4 relative mb-6 shadow-md;
    z-index: 2;
    width: fit-content;
    max-width: 80%;

    h1 {
      line-height: 1.3;
    }

    h2 {
      line-height: 1.3;
    }

    h4 {
      @apply mb-0;
    }

    .breadcrumbs {
      @apply hidden;
    }
  }
}

.blog-information {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: -0.5rem;
  margin-bottom: 2rem;

  img {
    width: 60px;
    margin-right: 0.5rem;
  }
  h3 {
    font-weight: 700;
    font-size: 18px;
  }
  time {
    font-weight: 400;
    font-size: 14px;
  }
}

.blog-information__section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
