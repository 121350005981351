.sidebar-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 8px 4px 0;

  & > span {
    font-size: 18px;
    line-height: 24px;
  }
}
