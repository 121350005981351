.blog_detail-wrapper {
  @apply mb-40;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  .__richt-text {
    width: 80%;
    p {
      @apply mb-6 text-lg;
      line-height: 1.85;
    }
    p > a {
      color: #6552ff;
    }
  }
}
