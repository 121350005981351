.contact_us_page-Tile {
  width: 100%;
  max-width: 1200px;
  display: grid;
  grid-template-columns: 40% 60%;
  row-gap: 2em;
  justify-content: center;
  justify-items: center;
  align-items: start;
  margin-top: 1rem;
  .contact_us_form-Tile {
    width: 85%;
    display: flex;
    flex-direction: column;

    h1 {
      font-size: calc(30px + (40 - 30) * ((100vw - 300px) / (1600 - 300)));
      font-weight: 700;
      text-align: center;
      margin: 0 0 3rem 0;
    }

    .contact-us-form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .fields_grid {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 40px;
        margin-bottom: 3rem;
      }
      textarea,
      select {
        margin-bottom: 3rem;
      }

      /* Phone Input */
      .containerClass {
        margin-top: 4rem;
      }
      .phone_input-Wrapper {
        width: 100%;
        margin: 0;
      }
      .form-control {
        border: none;
        border-bottom: solid 2px #e0e0e0;
        color: var(--color-black);
        font-weight: 700;
        border-radius: 0;
        height: 3rem;
      }
      .flag-dropdown {
        border: none;
        background-color: transparent;
        transition: 0.4s;
				width: 60px;
      }
      .form-control {
        width: 100%;
      }
      .selected-flag:hover {
        background-color: transparent;
      }

      button {
        place-self: flex-start;
        width: auto;
        padding: 0 3.2rem;
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .contact_us_page-Tile {
    grid-template-columns: 1fr;
    .contact_us_card {
      order: 2;
      margin-bottom: 2rem;
    }
    .contact_us_form-Tile {
      order: 1;
      width: 100%;
      .contact-us-form {
        width: 100%;
        place-self: center;
      }
    }
  }
}

@media only screen and (max-width: 425px) {
  .contact_us_page-Tile {
    .contact_us_form-Tile {
      .contact-us-form {
        .fields_grid {
          grid-template-columns: 1fr;
          margin-bottom: 0;
        }
        input,
        textarea,
        select {
          margin-bottom: 1rem;
        }
        button {
          align-self: center;
          margin: 1rem 0;
        }
      }
    }
  }
}
