.nps{
  background: white;
}

.nps-Wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 32px 0;
  background-position: center;

  &__previous-question {
    position: relative;
    right: 70px;
    width: 20px;
  }

  &__logo {
    display: flex;
  }
}

@media only screen and (max-width: 768px) {
  .nps-Wrapper  {
    display: grid;
    padding: 26px 0;

    &__logo-with-arrow {
      display: flex;
      margin-top: -18px
    }
  }
}
