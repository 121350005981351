.logo {
	@apply block;
	font-size: 3.8rem;

	svg {
		margin-top: 20px;
		width: 128px;
		height: 63px;
		margin-left: 16px;
	}
}

.logo--white {
	@apply text-white;
}

@media only screen and (max-width: 768px) {
	.logo {

		svg {
			margin-top: 28px;
			width: 66px;
			height: 32px;
			margin-left: 16px;
		}
	}
}