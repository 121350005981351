.faq_card_upd-Wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .accordion {
    width: 100%;
    border-radius: 10px;
    display: flex;
    padding: 2rem 0;
    grid-template-columns: 10% 1fr;
    justify-items: center;
    align-items: center;
    justify-content: center;
    transition: all 0.6s ease;
    outline: inherit;
    padding: 2rem 1rem;
		gap: 20px;

    h3 {
      font-family: 'Roca One';
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 28px;
      text-align: left;
    }
  }

  .accordion__icon {
    min-width: 19px;
    margin-left: auto;
    transition: transform 0.6s ease;
  }

  .rotate {
    transform: rotate(90deg);
  }

  .accordion__content {
    width: 100%;
    margin-top: -1rem;
    padding: 0 2rem;
    border-radius: 0 0 10px 10px;
    background: transparent;
    overflow: hidden;
    transition: max-height 0.6s ease;

    .faq_card_upd-Content {
      width: 100%;
      padding: 1.7rem 0;
      font-family: 'Roca One';
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 28px;
    }
  }

  .accordion__text {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
    padding: 18px;
  }
}

.faq_card_upd-Wrapper + .faq_card_upd-Wrapper {
  border-top: 1px solid #dddddd;
}

@media only screen and (max-width: 768px) {
	.faq_card_upd-Wrapper {

		.accordion {

			span:first-child {
				text-align: left;
			}
		}
	}
}