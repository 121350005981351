.typography-h1 {
	font-family: Roca One;
	font-size: 67px;
	line-height: 80px;
}
@media (max-width: 768px) {
	.typography-h1 {
		font-size: 51px;
		line-height: 60px;
	} 
}