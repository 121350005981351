.form_step-Wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  h3 {
    margin-bottom: 0;
  }
  h2 {
    color: var(--color-blue);
    line-height: 32px;
    font-size: 24px;
    /* margin-top: 20px; */
  }

  .body {
    margin-bottom: 30px;
  }

  .error_title {
    color: var(--color-error);
    margin-top: 2rem;
  }
  .bg-confirm_pay {
    background: #f6f6f6;
    border-radius: 8px;
    text-align: left;
    padding: 1rem 2rem;
  }

  .form_step {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .forget_password {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      text-decoration: underline;
      cursor: pointer;
      margin-top: 0.5rem;
      font-size: 14px;
      margin-top: 15px;
      svg {
        margin-right: 0.4rem;
      }
    }

    .login_tyc {
      margin: 2rem 0;
    }

    .new_children {
      margin-top: 0.8rem;
      font-size: calc(12px + (20 - 12) * ((100vw - 300px) / (1600 - 300)));
      h4 > button {
        width: auto;
        font-weight: 800;
        font-size: 1.2rem;
        text-decoration: none;
      }
    }

    .notice-user {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 2rem 0;
      p {
        margin: 0 0 0 0.5rem;
      }
    }

    h2 {
      margin-top: 3rem;
    }

    /* phone input styles */
    .phone_input-Wrapper {
      width: 100%;
      margin: 20px 0 0 0;
    }
    .phone_input-step-1 {
      width: 100%;
      margin: 40px 0 0 0;
    }
    .form-control,
    .flag-dropdown {
      border: solid 2px var(--color-gray);
      color: #616161;
      border-radius: 10px;
      height: 3rem;
      transition: 0.4s;
    }
    .form-control {
      width: 100%;
      color: #616161;
      font-family: Roca One;
    }
    .selected-flag:hover {
      background-color: transparent;
    }
    .field_row {
      width: 100%;
    }
    .modal_select_children {
      text-align: center;
      background-color: #fff;
      color: #000000;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 12px;
      padding: 30px 10px;
      > div > p {
        margin-top: 15px;
        margin-bottom: 15px;
      }
    }

    .select_children_body {
      width: 337px;
    }

    .select_children_continue_button {
      width: 100%;
    }

    .modal_select_children_exit_button {
      justify-content: center;
    }
  }

  .niubiz-inputs-wrapper {
    width: 100%;
    max-width: 100%;

    .btn_add_cuppon {
      color: var(--color-violet);
      font-weight: 700;
      cursor: pointer;
    }
    .cupon-section {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .cupon-input {
        padding: 0.5rem 0.2rem;
        outline: none;
      }
    }
  }
  .niubiz-footer {
    width: 100%;

    .navigation {
      font-weight: bold;
      text-decoration: underline;
      cursor: pointer;
    }

    .button:hover {
      background: rgba(0, 0, 0, 0.8);
    }

    .price_discount-section {
      h3 {
        text-decoration: line-through;
        font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1600 - 300)));
      }
      small {
        text-decoration: line-through;
        font-size: calc(8px + (10 - 8) * ((100vw - 300px) / (1600 - 300)));
      }
    }
  }
  .__security-info {
    margin: auto;
    .__logos {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 13px;
      img {
        width: 63px;
      }
    }
    .__logo {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 13px;
      margin-top: 60px;
      img {
        width: 100px;
      }
    }
    p {
      text-align: center;
      font-size: 12px;
    }
    .notify_payment {
      background-color: #00d6fe;
      padding: 1rem;
      margin-bottom: 1rem;
      p:first-child {
        font-size: 18px;
        font-weight: 700;
      }
    }
  }

  .payment_content-Wrapper {
    width: 95%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    p {
      font-size: calc(14px + (20 - 14) * ((100vw - 300px) / (1600 - 300)));
      margin-bottom: 1rem;
      strong {
        color: var(--color-black);
      }
    }

    .accent-title:last-child {
      margin-bottom: 0;
    }
  }

  .content-Wrapper {
    align-self: center;
    width: 95%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    h4 {
      text-align: center;
      align-self: center;
      font-size: calc(20px + (30 - 20) * ((100vw - 300px) / (1600 - 300)));
      font-weight: 800;
    }
    p {
      font-size: calc(14px + (20 - 14) * ((100vw - 300px) / (1600 - 300)));
      margin-bottom: 1rem;
      strong {
        color: var(--color-cadetBlue);
      }
    }
    button {
      align-self: flex-end;
    }
    .accent-title {
      color: var(--color-cadetBlue);
      font-weight: 800;
      margin-bottom: 0.5rem;
    }
    .information_box {
      margin: 0 0 1.5rem 0;
    }
  }

  .border-bottom {
    padding-bottom: 30px;
    border-bottom: 1px solid #e5e5e5;
  }
}

.wrapper-credit-card-input .InputContainer {
  background-color: red !important;
}

.InputContainer,
#root {
  background: red;
}

.grid_2x2 {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 30px;
  column-gap: 30px;
  grid-row-gap: 15px;
  row-gap: 25px;

  .btn_inside_grid {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    button {
      width: 60%;
    }
  }
}

.navigation_grid {
  align-self: end;
  .navigation {
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
  }

  h4 > button {
    width: auto;
    font-weight: 800;
    font-size: 1.3rem;
  }

  span > button {
    display: flex;
    justify-content: center;
    font-weight: 800;
    text-decoration: none;

    svg {
      margin-right: 0.5rem;
    }
  }
}

.stripe_form-input {
  width: 100%;
  margin: 0 0 1rem 0;
  padding: 1.5rem 1rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  outline: none;
}
.dlocal_form-input {
  width: 100%;
  margin: 0 0 1rem 0;
  padding: 1.5rem 1rem;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
  outline: none;
  display: flex;
}
.dlocal_form-card {
  width: 100%;
  margin: 0 0 1rem 0;
  /* padding: 1.2rem 1rem; */
  outline: none;
  display: flex;
  border: 2px solid black;
  border-radius: 6px;
  .field {
    padding: 1.2rem 1rem;
  }
  .fieldPan {
    border-right: 2px solid black;
  }
  .fieldExpiration {
    border-right: 2px solid black;
  }
}
.dlocal_card-input {
  width: 50%;
  margin: 0 0 1rem 0;
  padding: 1.5rem 1rem;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
  outline: none;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  color: '#31325F';
}
.dlocal_card-input::placeholder {
  font-family: Roca One;
  color: #a0aec0;
  font-weight: 500;
}

.user_recruitment_footer {
  width: 100%;
  display: grid;
  justify-items: center;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  column-gap: 50px;
  row-gap: 20px;
  button {
    width: 100%;
  }

  button:first-child {
    display: flex;
    justify-content: center;
    font-weight: 800;
    text-decoration: none;

    svg {
      margin-right: 0.5rem;
    }
  }
}

.go-to-login {
  padding: 10px 0px;
  h4 {
    font-size: 16px;
  }
  .button--variant-link {
    padding: 5px 10px;
    background-color: #000000;
    border-radius: 8px;
    color: white;
    text-decoration: none;
    font-size: 14px;
    margin-left: 10px;
    position: relative;
    top: -1px;
    &:hover {
      background: rgb(0 0 0 / 80%);
    }
  }
}

.go-to-login-enrollmentng {
  padding: 0;
  margin-bottom: 20px;
}

.create-login {
  width: 100%;
}

.terms_conditions_promotions {
  margin-top: 40px;
  width: 100%;
  label {
    position: relative;
    cursor: pointer;
  }
}

.terms_conditions {
  display: flex;
  align-items: center;
  font-size: 15px;
  margin-top: 25px;
  label {
    margin-left: 10px;
  }
}

@media only screen and (max-width: 1024px) {
  .form_step-Wrapper {
    .form_step {
      width: 100%;
    }
    .__security-info {
      .__logo {
        margin-top: 15px;
      }
    }
  }
  .grid_2x2 {
    column-gap: 30px;
  }
}

.form_step-Wrapper-show-web {
  display: flex;
}

.form_step-Wrapper-show-mobile {
  display: none;
}

@media only screen and (max-width: 768px) {
  .form_step-Wrapper {
    h2 {
      width: 100%;
      text-align: left;
    }
    h3 {
      /* margin: auto; */
    }
    .form_step {
      width: 100%;
      .new_children {
        align-self: center;
        text-align: center;
      }
      .login_tyc {
        width: 80%;
        align-self: center;
      }
      .phone_input-step-1 {
        margin: 20px 0 0 0;
      }
    }
    .payment_content-Wrapper {
      width: 100%;
      button {
        width: 80%;
        align-self: center;
      }
    }
    .content-Wrapper {
      width: 100%;
      button {
        width: 80%;
        align-self: center;
      }
    }

    .niubiz-inputs-wrapper {
      width: 100%;
    }
    .niubiz-footer {
      width: 100%;
      button {
        width: 100%;
      }
    }
    .niubiz-footer-back-button {
      display: none;
    }
  }
  .grid_2x2 {
    grid-template-columns: 1fr;
    justify-items: center;
    .btn_inside_grid {
      justify-content: center;
    }
  }
  .navigation_grid {
    grid-template-columns: 1fr;
    justify-items: center;
    row-gap: 20px;
    h4 {
      margin-bottom: 0;
    }
  }
  .m-full-width {
    width: 100%;
  }

  .form_step-Wrapper-show-web {
    display: none;
  }

  .form_step-Wrapper-show-mobile {
    text-align: left;
    display: flex;
  }
}

@media screen and (max-width: 643px) {
  .user_recruitment_footer > button:first-child {
    order: 2;
  }
}

@media only screen and (max-width: 425px) {
  .form_step-Wrapper {
    .form_step {
      .field_row {
        width: 100%;
      }
      .login_tyc {
        width: 100%;
      }
    }
  }
}

.niubiz-inputs-wrapper iframe {
  border-radius: 6px;
  /* border: 2px solid #001F19 !important; */
  border-radius: 8;
  /* height: 56px !important;
	.CardField, input {
		height: 56px !important;
	} */
}

.niubiz-inputs-wrapper .DlocalField iframe {
  box-shadow: none;
}

.date {
  background: transparent !important;
  width: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.create-login-subtitle {
  font-size: 16px;
  margin-top: -13px;
  margin-bottom: 25px;
}

.success-inscription-user-pass-container {
  display: flex;
  justify-content: space-between;
  width: 392px;
  background-color: #e2edff;
  border-radius: 6px;
  margin-top: 20px;
  padding: 10px 20px;
  button:focus {
    outline: none;
  }
}

.form_step-Wrapper {
  .content-Wrapper {
    .success-inscription-title-description p,
    .success-inscription-user-pass-container p {
      font-size: 16px;
    }
    .success-inscription-user-pass-container button {
      align-self: center;
    }
  }
}

.success-inscription-buttons-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 50px;
  & a {
    margin-top: 5px;
    border-radius: 0;
    & p {
      margin-bottom: 0.3rem !important;
    }
  }
}

@media only screen and (max-width: 768px) {
  .success-inscription-user-pass-container {
    width: 100%;
  }

  .form_step-Wrapper {
    .success-inscription-buttons-container {
      flex-direction: column-reverse;
      & button {
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }
}
