.ui-progress-bar {
	width: 100%;
	height: 8px;
	border-radius: 10px;
	display: flex;
	&--free-course {
		background: rgba(0, 0, 0, 0.1);
	}
	&--path-course {
		background: rgba(255, 255, 255, 0.7);
	}
	&--empty-progress {
		background: rgba(255, 255, 255, 0);
	}
	&--alternative {
		background: red;
	}
	&__fill {
		height: 100%;
		border-radius: 10px;
		transition: 0.1s ;
	}
}