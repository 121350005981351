.section-about-us {
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-bottom: 80px;

  /* First */
  &__first-section {
    display: flex;
    flex-direction: column;
    width: 50%;
    justify-content: center;
  }
  &__title {
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 24px;
    &--bold {
      font-weight: bold;
    }
  }
  &__description {
    font-size: 21px;
    line-height: 32px;
  }

  /* Second */
  &__second-section {
    width: 50%;
  }
  &__image {
    width: 512px;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 920px) {
  .section-about-us {
    flex-direction: column;
    gap: 24px;

    /* First */
    &__first-section {
      width: 100%;
    }
    &__title {
      font-size: 38px;
      line-height: 48px;
      text-align: center;
    }
    &__description {
      font-size: 21px;
      line-height: 32px;
      text-align: center;
    }

    /* Second */
    &__second-section {
      width: 100%;
      order: 1 !important;
    }
    &__image {
      width: 259px;
    }
  }
}
