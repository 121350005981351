.typography-h3 {
	font-family: Roca One;
	font-size: 27px;
	line-height: 38px;
}
@media (max-width: 768px) {
	.typography-h3 {
		font-size: 28px;
		line-height: 32px;
	}
}