.main {
  min-height: 100vh;

  .main__content {
    @apply mx-auto px-6;
    max-width: calc(1200px + 5rem);
  }

  .main__content--full {
    @apply px-0;
    max-width: initial;
  }
  .main__content--full_abtest {
    margin: 0;
    padding: 0;
    max-width: initial;
  }
  .main__content--full_variant {
    margin: 0;
    padding: 0;
    max-width: initial;
		overflow: hidden;

    & > h1 {
      display: none;
    }
  }

  .main__content--terms {
    @apply mx-auto pt-32 px-6;
    max-width: calc(1200px + 5rem);
  }

  @screen lg {
    .main__content {
      @apply mb-12 pt-4 px-10;
    }

    .main__content--full_abtest {
      margin: 0;
      padding: 0;
      max-width: initial;
    }

    .main__content--full {
      @apply mb-0 pt-0;
    }

    .main__content--full_variant {
      margin: 0;
      padding: 0;
      max-width: initial;
			overflow: hidden;
    }

    .main__content--terms {
      @apply mb-12 pt-40 px-10;
    }
  }
}

/* Background colors */
.main__content__bk_color--cadetBlue {
  background-color: var(--color-cadetBlue);
}

.main--transparent-on-mobile {
  padding-top: 0;

  .breadcrumbs-in-course-detail {
    @apply hidden mb-6;
  }

  @screen lg {
    padding-top: calc(80px + 1rem);

    .breadcrumbs-in-course-detail {
      @apply block;
    }
  }
}

.main--appear-on-scroll {
  padding-top: 0;
}

/* Todo: Move this somewhere else */
.main__course-layout {
  @apply mb-8;

  .course-layout__hero {
    @apply flex-1 mr-10;
  }

  .course-layout__body {
    @apply flex-1 mr-10;

    & > section {
      @apply mb-8;
    }

    .body__sky-bg {
      @apply bg-sky w-screen relative px-6 py-8 mb-10;
      left: -1.5rem;

      &::before {
        @apply absolute bg-sky hidden top-0 h-full;
        width: 100vh;
        left: -100vh;
        content: '';
      }
    }
  }

  .course-layout__subscription {
    @apply flex-1 border-b-4 border-grey w-screen relative px-6 mb-12 pb-8;
    left: -1.5rem;

    @screen lg {
      @apply border-black px-10 py-8 bg-white shadow-lg;
      max-width: 400px;
      border-width: 3px;
    }
  }

  .course-layout__empty {
    @apply flex-1;
    max-width: 400px;
  }

  @screen lg {
    @apply flex mb-0 items-start;

    .course-layout__body {
      & > section {
        @apply mb-10 px-10;
      }

      .body__sky-bg {
        @apply w-full left-0 px-10 py-10 relative;

        &::before {
          @apply block;
        }
      }
    }

    .course-layout__subscription {
      @apply mb-0 left-0;
    }
  }
}

.mobile-sticky-subscribe {
  @apply flex justify-between items-center bg-black w-screen relative h-20 px-6;
  left: -1.5rem;

  button {
    @apply px-4;
  }
}

.course-body-item {
  @apply mb-6;
}

.course-body-item > p > p > a {
  color: #6552ff;
}

.course-body-item__rendered-html {
  li {
    @apply relative pl-4 mb-2;
  }

  li::before {
    @apply absolute left-0;
    top: -1px;
    content: '- ';
  }
}

.main__blog-layout {
  @apply mb-40;
  max-width: 760px;

  .__richt-text {
    p {
      @apply mb-6 text-lg;
      line-height: 1.85;
    }
  }
}

/* Body Landing Scroll Snap */

body.snap-scrolling {
  @apply bg-sky overflow-x-hidden overflow-y-hidden;

  & > div {
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100vh;

    & > section,
    & > footer {
      scroll-snap-align: start;
    }
  }
}

.layout-aside {
  @apply mt-4;

  @screen lg {
    @apply flex mt-8;

    & > * {
      @apply flex-1;
    }

    & > div {
      @apply mr-12;
    }

    aside {
      max-width: 400px;
    }
  }
}

/* SORRY NOT SORRY */
.contact-us-form {
  max-width: 600px;

  & > * {
    @apply mb-6;
  }
}

.onboarding-form {
  max-width: 400px;

  & > * {
    @apply mb-6;
  }

  .__row {
    @apply flex;

    & > *:first-child {
      @apply mr-4 flex-1;
      max-width: 120px;
    }

    & > *:last-child {
      @apply flex-1;
    }
  }
}

.w-50 {
  width: 50%;
}

.w-60 {
  width: 60%;
}

.w-40 {
  width: 40% !important;
}

.free-class {
  width: 96%;
  max-width: 1080px;
  margin: auto;
  margin-top: 40px;
  margin-bottom: 20px;
  h2 {
    font-family: 'Roca One';
  }
}

.bg-gray {
  background: #f8f8f8 !important;
}

.mx-500 {
  max-width: 580px;
  margin: auto;
  text-align: center;
}

.phone_input-Wrapper {
  width: 100%;
  margin: 0;
  .form-control,
  .flag-dropdown {
    border: solid 2px var(--color-gray);
    color: #616161;
    border-radius: 10px;
    height: 3rem;
    transition: 0.4s;
  }
  .form-control {
    width: 100%;
    color: #616161;
    font-family: Roca One;
  }
  .selected-flag:hover {
    background-color: transparent;
  }
}

.blue {
  color: #006fd4;
}
.orange {
  color: #ff6518;
}
.free-class-form {
  h4 {
    font-size: 16px;
  }
}
.free-class-button {
  .button {
    width: 216px;
  }
}
.free-class-footer {
  width: 90%;
  padding-top: 27px !important;
  padding-bottom: 18px !important;
}

.mx_70 {
  max-width: 712px;
  width: 100%;
}

.notification {
  position: absolute;
  top: 50px;
  right: 0;
  left: 0;
  max-width: 1100px;
  margin: auto;
  text-align: center;
  background: rgb(246 246 246);
  border-radius: 12px;
  padding: 20px 0 16px 0;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  img {
    margin-right: 10px;
  }
}

.notification_schedule {
  display: flex;
  max-width: 1100px;
  margin: auto;
  text-align: center;
  background: rgb(246 246 246);
  padding: 20px 0 16px 0;
  justify-content: center;
  border-radius: 16px;
  img {
    margin-right: 10px;
  }
  &.checkout {
    padding: 20px 30px 16px 30px;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
    border-radius: 16px;
    margin: 0 0 12px 0;
    p {
      font-weight: inherit;
    }
  }
}

@media screen and (max-width: 768px) {
  .free-class {
    margin-top: 0;
    flex-direction: column-reverse;
    .w-60,
    .w-40 {
      width: 95% !important;
      margin: auto;
    }
    .w-60 {
      margin-bottom: 20px;
    }
    .grid_2x2 {
      display: block;
    }
    .field_row {
      margin-bottom: 15px;
    }
    span.flex {
      justify-content: center !important;
      margin-top: 30px;
    }
  }

  .notification {
    text-align: left;
    align-items: flex-start;
    width: 90%;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
  }
}
