.testimony-Wrapper {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin: 0 0 124px 0;

  .testimony {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    max-width: 1264px;

    &_list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-auto-rows: 1fr;
      justify-content: center;
      align-items: flex-start;
      gap: 16px;

      max-width: 100%;
      width: 100%;
      margin: 24px 0 0 0;
    }
  }
}

@media only screen and (max-width: 768px) {
  .testimony-Wrapper {
    margin: 0 0 60px 0;
    width: 100%;

    .testimony {
      &_list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: 1fr;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: nowrap;

        overflow-x: scroll;

        width: 100vw;
        margin: 24px 0 0 0;
        padding: 5px 15px 10px 20px;

        &::-webkit-scrollbar {
          height: 10px;

          &-track {
            background: #f5f5f7;
          }

          &-thumb {
            background: #888;

            &:hover {
              background: #555;
            }
          }
        }

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
}
