.container-level-card {
	display: flex;
	flex-direction: column;
	gap: 40px;
}

.container-level-card> h1 {
	font-weight: bold;
	font-size: 30px;
	line-height: 35px;
}
.container-level-card> p {
	font-weight: normal;
	font-size: 22px;
	line-height: 35px;
	color: #616161;
}
.content-level {
	box-shadow: 0px 16px 40px rgba(14, 86, 124, 0.2);
	border-radius: 20px;
	padding: 40px 22px;
	display: flex;
	flex-direction: column;
	grid-gap: 10px;
}
.container-level-item {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 88px;
	border-radius:  15px;
}
.content-level h2 {
	font-weight: bold;
	font-size: 25px;
	line-height: 35px;
	margin-bottom: 30px
}
.section-levels {
	display: flex; 
	flex-direction: column;
	gap: 10px;
}
.container-level-card> h1,
.container-level-card> p,
.content-level h2,
.container-level-item h5,
.container-level-item p,
.level-card-range-age h5 {
	font-family: 'Roca One';
	color: #000000;
	text-align: center;
}

.container-level-item h5 {
	font-weight: bold;
	font-size: 20px;
	line-height: 25px;
}
.container-level-item p {
	font-weight: normal;
	font-size: 20px;
	line-height: 25px;
}

.level-card-range-age {
	display: flex;
	justify-content: space-between;
	background: linear-gradient(90deg, rgba(147, 254, 213, 0.4) 0%, rgba(183, 197, 255, 0.4) 100%);
	border-radius: 15px;
	padding: 11px 18px;
	margin-top: 10px;
}
.level-card-range-age h5{
	font-weight: bold;
	font-size: 16px;
	line-height: 25px;
}

.container-level-card  .trust_us-Wrapper .company_list {
	width: 100%;
	display: grid;
	-moz-column-gap: 3em;
	grid-column-gap: 3em;
	column-gap: 3em;
	grid-row-gap: 2em;
	row-gap: 2em;
	grid-template-columns: repeat(auto-fit, minmax(82px, 130px));
	justify-content: center;
	justify-items: center;
	align-items: start;
	margin: 2rem 0 0 0;
}

.container-level-card .trust_us-Wrapper h3 {
	font-family: 'Roca One';
	font-style: normal;
	font-weight: normal;
	font-size: 1.13rem;
	line-height: 1.14rem;
	color: #616161;
}

@media (min-width : 760px){
	.section-levels {
    grid-gap: 10px;
		flex-direction: row;
    justify-content: space-evenly;
	}
}
@media (min-width : 768px){
	.container-level-card .trust_us-Wrapper h3 {
		font-size: 1.6rem;
    line-height: 2.5rem;
	}
}
