.link {
  cursor: pointer;
  width: auto;
  display: inline;
}

.bold {
  font-weight: bold;
}

.link--whatsapp {
  @apply flex items-center px-4 border-2 border-black rounded text-white;
  background-color: #25d366;
  width: fit-content;
  height: auto;
  padding: 0.5rem 1rem;
  transition: 0.4s;

  svg {
    @apply text-sm ml-4;
    width: 1.5em;
    height: 1.5em;
  }

  &:hover {
    transform: scale(1.04, 1.04);
  }
}

.link--underline {
  @apply underline;
}

.link--outline-w-icon {
  text-align: center;
  @apply rounded-sm px-6 flex justify-center items-center shadow-md
    tracking-wider h-12 bg-transparent border-2 border-black text-black font-bold;
  font-family: Montserrat, sans-serif;

  i {
    @apply ml-2 relative;
    top: -1px;
  }

  &:hover {
    @apply bg-black text-white;
  }
}
.link--white-w-icon {
  text-align: center;
  @apply rounded-sm px-6 flex justify-center items-center shadow-md
  tracking-wider h-12 border-2 border-black text-black font-bold;
  font-family: Montserrat, sans-serif;
  background-color: var(--color-white);

  i {
    @apply ml-2 relative;
    top: -1px;
  }

  &:hover {
    @apply bg-black text-white;
  }
}

.link--black-w-icon {
  text-align: center;
  border-radius: 10px;
  transition: 0.4s;
  @apply px-6 flex justify-center items-center shadow-md
    tracking-wider  bg-black text-white font-bold;
  font-family: Montserrat, sans-serif;
  padding: 1rem 2rem;

  i {
    @apply ml-2 relative;
    top: -1px;
  }

  &:hover {
    transform: scale(1.04, 1.04);
  }
}

.link--black-rounded-w-icon {
  text-align: center;
  border-radius: 10px;
  transition: 0.4s;
  @apply px-6 flex justify-center items-center shadow-md
    tracking-wider  bg-black text-white font-bold;
  padding: 0.5rem 0.65rem;
  font-weight: 700;

  i {
    @apply ml-2 relative;
    top: -1px;
  }

  &:hover {
    transform: scale(1.04, 1.04);
  }
  &:active {
    transform: scale(0.9);
  }
}

.link--degraded-w-icon {
  text-align: center;
  font-size: 1.4rem;
  background: rgb(255, 146, 255);
  background: linear-gradient(
    270deg,
    rgba(255, 146, 255, 1) 0%,
    rgba(0, 255, 255, 1) 100% 100%
  );

  @apply rounded-3xl px-12 flex justify-center h-12
    items-center shadow-md tracking-wider text-white font-bold;
  font-family: Montserrat, sans-serif;

  i {
    @apply ml-2 relative;
    top: -1px;
  }

  &:hover {
    @apply shadow-xl transition duration-500 ease-in-out;
    background: linear-gradient(
      90deg,
      rgba(255, 146, 255, 1) 0%,
      rgba(0, 255, 255, 1) 100%
    );
  }
}

.link--degraded-lg-w-icon {
  font-size: 1.4rem;
  background: rgb(255, 146, 255);
  background: linear-gradient(
    270deg,
    rgba(255, 146, 255, 1) 0%,
    rgba(0, 255, 255, 1) 100%
  );

  @apply rounded-3xl px-20 flex justify-center h-16
    items-center shadow-md tracking-wider text-white font-bold;
  font-family: Montserrat, sans-serif;

  i {
    @apply ml-2 relative;
    top: -1px;
  }

  &:hover {
    @apply shadow-xl transition duration-500 ease-in-out;
    background: linear-gradient(
      90deg,
      rgba(255, 146, 255, 1) 0%,
      rgba(0, 255, 255, 1) 100%
    );
  }
}

.link--degraded2-lg-w-icon {
  font-size: 1.4rem;
  background: rgb(255, 146, 255);
  background: linear-gradient(270deg, rgba(255, 146, 255, 1) 0%, rgba(0, 255, 0, 1) 100%);

  @apply rounded-3xl px-20 flex justify-center h-16
    items-center shadow-md tracking-wider text-white font-bold;
  font-family: Montserrat, sans-serif;

  i {
    @apply ml-2 relative;
    top: -1px;
  }

  &:hover {
    @apply shadow-xl transition duration-500 ease-in-out;
    background: linear-gradient(
      90deg,
      rgba(255, 146, 255, 1) 0%,
      rgba(0, 255, 0, 1) 100%
    );
  }
}

.link--degraded3-lg-w-icon {
  font-size: 1.4rem;
  background: rgb(255, 202, 0);
  background: linear-gradient(270deg, rgba(255, 202, 0) 0%, rgba(0, 255, 255, 1) 100%);

  @apply rounded-3xl px-20 flex justify-center h-16
    items-center shadow-md tracking-wider text-white font-bold;
  font-family: Montserrat, sans-serif;

  i {
    @apply ml-2 relative;
    top: -1px;
  }

  &:hover {
    @apply shadow-xl transition duration-500 ease-in-out;
    background: linear-gradient(
      90deg,
      rgba(255, 202, 0, 1) 0%,
      rgba(0, 255, 255, 1) 100%
    );
  }
}

.link--degraded4-lg-w-icon {
  font-size: 1.4rem;
  background: rgb(255, 157, 82);
  background: linear-gradient(270deg, rgba(255, 157, 82) 0%, rgba(255, 255, 0, 1) 100%);

  @apply rounded-3xl px-20 flex justify-center h-16
    items-center shadow-md tracking-wider text-white font-bold;
  font-family: Montserrat, sans-serif;

  i {
    @apply ml-2 relative;
    top: -1px;
  }

  &:hover {
    @apply shadow-xl transition duration-500 ease-in-out;
    background: linear-gradient(
      90deg,
      rgba(255, 157, 82, 1) 0%,
      rgba(255, 255, 0, 1) 100%
    );
  }
}

.link--little-coders {
  width: 85%;
  background-color: var(--color-little-coders);
  text-align: center;
  font-weight: 700;
  box-shadow: 0px 26.2108px 52.4359px rgba(14, 86, 124, 0.16);
  border-radius: 8px;
  font-size: calc(16px + (20 - 16) * ((100vw - 300px) / (1600 - 300)));
  padding: 0.8rem 1rem;
  margin: 1rem 0;

  &:hover {
    @apply shadow-xl transition duration-500 ease-in-out;
  }
}

.link--desarrollo-de-videojuegos {
  width: 85%;
  background-color: var(--color-desarrollador-de-videojuegos);
  text-align: center;
  font-weight: 700;
  box-shadow: 0px 26.2108px 52.4359px rgba(14, 86, 124, 0.16);
  border-radius: 8px;
  font-size: calc(16px + (20 - 16) * ((100vw - 300px) / (1600 - 300)));
  padding: 0.8rem 1rem;
  margin: 1rem 0;

  &:hover {
    @apply shadow-xl transition duration-500 ease-in-out;
  }
}

.link--disenio-y-creatividad {
  width: 85%;
  background-color: var(--color-disenio-y-creatividad);
  text-align: center;
  font-weight: 700;
  box-shadow: 0px 26.2108px 52.4359px rgba(14, 86, 124, 0.16);
  border-radius: 8px;
  font-size: calc(16px + (20 - 16) * ((100vw - 300px) / (1600 - 300)));
  padding: 0.8rem 1rem;
  margin: 1rem 0;

  &:hover {
    @apply shadow-xl transition duration-500 ease-in-out;
  }
}

.link--super-programadores {
  width: 85%;
  background-color: var(--color-super-programador);
  text-align: center;
  font-weight: 700;
  box-shadow: 0px 26.2108px 52.4359px rgba(14, 86, 124, 0.16);
  border-radius: 8px;
  font-size: calc(16px + (20 - 16) * ((100vw - 300px) / (1600 - 300)));
  padding: 0.8rem 1rem;
  margin: 1rem 0;

  &:hover {
    @apply shadow-xl transition duration-500 ease-in-out;
  }
}

.link--any-question-card {
  width: 85%;
  background-color: var(--color-white);
  color: var(--color-black);
  text-align: center;
  font-weight: 700;
  box-shadow: 0px 26.2108px 52.4359px rgba(14, 86, 124, 0.16);
  border: solid 2px #a8afb3;
  border-radius: 8px;
  font-size: calc(12px + (14 - 12) * ((100vw - 300px) / (1600 - 300)));
  padding: 0.8rem 1rem;
  margin: 1rem 0;

  &:hover {
    @apply shadow-xl transition duration-500 ease-in-out;
  }
}

.link--txt-only {
  width: 85%;
  color: var(--color-black);
  text-align: center;
  font-weight: 700;
  border: none;
  font-size: calc(12px + (14 - 12) * ((100vw - 300px) / (1600 - 300)));
  padding: 0.8rem 1rem;
  margin-bottom: 0;

  &:hover {
    cursor: pointer;
  }
}

.link--is-blocked {
  background-color: #ffffff;
  color: var(--color-gray-dark);
  pointer-events: none;
  cursor: default;
  svg {
    path {
      fill: var(--color-gray-dark);
    }
  }
}
