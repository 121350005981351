.contact-form-card {
	display: flex;
	flex-direction: column;
	gap: 24px;
	padding: 24px;
	border-radius: 16px;
	box-shadow: var(--shadow-small);
	background: #ffffff;
}

.contact-form-card-content {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.contact-form-card-content ul {
	list-style: initial !important;
	margin: 8px 16px 24px 32px;
}

.contact-form-card-content li {
	/* display: flex;
	gap: 5px; */
	font-size: 14px;
}

.contact-form-card-content li a {
	width: auto !important;
}

.contact-form-card .text-field-contact-form {
	border: 2px solid #000000;
	color: #000000;
	border-radius: 10px;
	transition: 0.4s;
	outline: 2px solid transparent;
	outline-offset: 2px;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	height: 3.5rem;
	border-width: 2px;
	display: block;
	width: 100%;
}

.contact-form-card .select-field-contact-form {
	border: 2px solid #000000;
	color: #000000;
	border-radius: 10px;
	transition: 0.4s;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-image: url(/icons/icon-arrow-select.svg);
	background-repeat: no-repeat, repeat;
	background-position: right 20px top 53%, 0 0;
	outline: 2px solid transparent;
	outline-offset: 2px;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	height: 3.5rem;
	border-width: 2px;
	display: block;
	width: 100%;
}

.contact-form-card .select-field-contact-form .placeholder:selected {
	color: #A0AEC0 !important;
}

.contact-form-card select:required:invalid {
	color: red;
}

.contact-form-card .textarea-contact-form {
	border: 2px solid #000000;
	color: #000000;
	border-radius: 10px;
	transition: 0.4s;
	resize: none;
	outline: 2px solid transparent;
	outline-offset: 2px;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	padding: 1rem;
	height: 3.5rem;
	border-width: 2px;
	display: block;
	width: 100%;
	height: 110px;
}
