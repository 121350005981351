.contact-advisor {
	max-width: 1040px;
	display: flex;
	justify-content: center; 
	align-items: center;
	margin: 0 auto;
	gap: 102px;
	&__first-content {
		width: 424px;
	}
	&__second-content {
		width: 512px;
		display: flex;
		flex-direction: column;
		gap: 24px;
	}
	&__title {
		font-size: 38px;
		line-height: 125%;
		&--bold {
			font-weight: bold;
		}
	}
}

@media (max-width : 768px){
	.contact-advisor {
		padding: 0 23px;
		flex-direction: column;
		gap: 34px; 
		&__first-content {
			width: 100%;
		}
		&__second-content {
			width: 100%;
			order: -1;
		}
	}
	&__br{
		display: none;
	}
}

