.ui-card-path-course-blocked {
	border-radius: 16px;
	min-height: 404px;
	transition: all 0.8s;
	min-width: 100%;
	overflow: hidden;
	background:rgba(88, 58, 240, 0.95);
	height: 100%;
	position: absolute;
	width: 100%;
	flex-direction: column;
	align-items: center;
	z-index: 100;
	&__show {
		display: flex;
	}
	&__hidden {
		display: none;
	}
	&__container {
		display: flex;
		flex-direction: column;
		align-items: center;
		row-gap: 16px;
		padding-bottom: 36px;
	}
	&__title-section {
		padding-top: 8px;
		max-width: 380px;
	}
	&__sub-title-section {
		max-width: 440px;
	}
	&__button-close {
		width: 100%;
		display: flex;
		justify-content: flex-start;
		flex-direction: row-reverse;
		padding-right: 20px;
		padding-top: 20px;
		cursor: pointer;
	}
	&__buttons-section {
		display: flex;
		align-items: center;
		gap: 20px;
		flex-direction: column;
	}
	&__button-filled {
		display: flex;
		align-items: center;
		gap: 0.25rem;
		background: white;
		border-radius: 6px;
		width: 250px;
		text-align: center;
		a {
			display: flex;
			align-items: center;
			gap: 0.25rem;
			justify-content: center;
			padding: 8px;
		}
	}
	&__button-outline {
		display: flex;
		align-items: center;
		gap: 0.25rem;
		border: 2px solid #FFFFFF;
		border-radius: 6px;
		width: 250px;
		text-align: center;
		a {
			display: flex;
			align-items: center;
			gap: 0.25rem;
			justify-content: center;
			padding: 8px;
		}
	}

}