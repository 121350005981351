.ui-card-final-certificate-progress {
	border-radius: 16px;
	transition: all 0.8s;
	min-width: 100%;
	/* padding: 16px; */
	/* min-width: 100%; */
	/* overflow: hidden; */
/* 	&__content {
		margin: 16px;
		width: calc(100% - 32px);
		background: #FFFFFF;
		border-radius: 8px;
		padding: 1.5rem 4.75rem 2.5rem;
		display: flex;
		flex-direction: column;
    align-items: center;
		p:first-child {
			max-width: 446px !important;
			margin: 0 auto;
		}
	} */
/* 	&__details {
		display: flex;
		background: #000000;
		border-radius: 0 0 8px 8px;
		height: 61px;
		padding: 0 53px;
	}
	&__image-container {
		width: 118px;
		height: 107px;
		position: relative;
		border-radius: 8px;
		overflow: hidden;
		transform: rotate(8deg);
		top: -20px;
	}
	&__details-text {
		display: flex;
		align-items: center;
		width: calc(100% - 171px);
		margin-left: 25px;
		gap: 15px;
	} */
	/* &__container-certificate {
		width: 100%;
		display: flex;
		justify-content: center;
		margin: 12px auto;
	} */
}