.game-jam-bullying-Wrapper {
  display: flex;
  justify-content: center;

  padding: 100px 0 100px 0;

  .game-jam-bullying {
    position: relative;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-areas: 'img text';
    gap: 38px;

    max-width: 1082px;
    width: 100%;

    border: 1px solid #5d37ce;
    border-radius: 12px;

    background: radial-gradient(
      122.27% 181.56% at 50% 50%,
      rgba(92, 33, 245, 0) 0%,
      rgba(92, 33, 245, 0.28) 100%
    );

    padding: 54px 325px 68px 32px;

    /* Img */
    &__img {
      grid-area: img;

      & > span {
        display: block !important;
      }
    }

    /* Text */
    &__text {
      grid-area: text;
    }

    /* Background Img */
    &__background-img {
      position: absolute;
      bottom: 0;
      right: 0;

      & > span {
        display: block !important;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .game-jam-bullying-Wrapper {
    padding: 80px 20px 80px 20px;
    overflow: hidden;

    .game-jam-bullying {
      grid-template-columns: 1fr;
      grid-template-areas:
        'img'
        'text';
      gap: 25px;

      padding: 40px 24px 152px 24px;

      /* Img */
      &__img {
        grid-area: img;
        display: flex;
        justify-content: center;
      }
    }
  }
}
