.about-vacation {
	max-width: 946px;
	display: flex;
	justify-content: center; 
	flex-direction: row;
	margin: 0 auto;
	gap: 57px;
	&__first-content {
		width: 277px;
		display: flex;
		align-items: center;
	}
	&__image {
		width: 100%;
		transform: translate(0);
		animation: 5s ease-in-out bounce_image infinite;
	}
	&__second-content {
		width: 600px;
		display: flex;
		flex-direction: column;
		gap: 20px;
	}
	&__subtitle {
		font-size: 20px;
		line-height: 180%;
		&--bold {
			font-weight: bold;
		}
	}
	&__title {
		font-size: 38px;
		line-height: 125%;
		&--bold {
			font-weight: bold;
		}
	}
	&__paragraph {
		font-size: 20px;
		line-height: 180%;
	}
	&__container-img {
		width: 336px;
		height: 224px;
		border-radius: 20px;
		position: relative;
		display: flex;
		align-items: flex-end;
		bottom: -90px;
	}
	&__container-button {
		display: flex;
		align-items: center;
		gap: 0.5rem;
		flex-direction: row;
		align-items: center;
	}
}

@media (max-width : 768px){
	.about-vacation {
		max-width: 920px;
		display: flex;
		justify-content: center; 
		flex-direction: column-reverse;
		margin: 0 auto;
		gap: 34px;
		align-items: center;
		&__first-content {
			width: 294px;
		}
		&__second-content {
			width: 100%;
			padding: 26px 23px 0 23px;
			order: -1;
			gap: 11px;
		}
		&__subtitle {
			font-size: 20px;
			line-height: 180%;
			&--bold {
				font-weight: bold;
			}
		}
		&__title {
			font-size: 38px;
			line-height: 125%;
			&--bold {
				font-weight: bold;
			}
		}
		&__paragraph {
			font-size: 20px;
			line-height: 180%;
		}
		&__container-button {
			flex-direction: column;
    		align-items: flex-start;
		}
	}  
}

@keyframes bounce_image {
	0% {
		transform: translate(40px, -30px);
	}
	50% {
		transform: translate(0px, 0px);
	}
	100% {
		transform: translate(40px, -30px);
	}
}