.text-field {
	@apply outline-none px-6 h-12 border-2 border-black block w-full;
}

.text-field.error {
	@apply border-red-error;
}

.text-field-purchase-flow {
	border-color: var(--color-gray);
	border-radius: 10px;
	transition: 0.4s;
	@apply outline-none px-6 h-12 border-2 block w-full;

}

.text-field-program-organization {
	border: 2px solid #B9BFBD;
	border-radius: 16px !important;
	transition: 0.4s;
	background-color: white;
	width: 100%;
	padding: 16px 24px 18px;
	font-family: NT Bau;
	font-size: 18px;
	line-height: 122%;
}

.text-field-program-organization::placeholder {
	font-family: NT Bau;
	font-size: 18px;
	color: #808080;
}
.text-field-program-organization:focus {
	outline: none;
}

.select-field-purchase-flow {
	-webkit-appearance: none;
	/* WebKit (Chrome and Safari) */
	-moz-appearance: none;
	/* Gecko (Firefox) */
	appearance: none;
	/* Other browsers */

	background: url('/arrow-select-icon.svg') no-repeat calc(100% - 12px) center;
}

.select-field-purchase-flow::-ms-expand {
	display: none;
	/* Ocultar la flecha en Internet Explorer */
}

.text-field-contact-us {
	border-color: var(--color-gray);
	border-radius: 10px;
	transition: 0.4s;
	@apply outline-none h-12 block w-full;
	padding: 0 16px;
	border-radius: 16px !important;
}

input[type='date']:before {
	color: lightgrey;
	content: attr(placeholder) !important;
	margin-right: 0.5em;
}

input[type='date']:focus:before {
	content: '' !important;
}

.text-field-purchase-flow:focus {
	border-color: var(--color-black);
}

.text-field-purchase-flow.error {
	@apply border-red-error;
}

.text-field-contact-home {
	border-color: #616161;
	color: #616161;
	border-radius: 10px;
	transition: 0.4s;
	@apply outline-none px-6 h-12 border-2 block w-full;
}

.text-field-material-design {
	border-bottom: solid 2px #e0e0e0;
	color: var(--color-black);
	font-weight: 700;
	transition: 0.4s;
	@apply outline-none h-12 block w-full;
}

.text-field-material-design:focus {
	border-bottom: solid 2px;
}

.text-field-material-design::placeholder {
	color: var(--color-black);
	font-weight: 700;
}

.content-text-field {
	display: flex;

	& button {
		margin-top: 5px;
		margin-left: -35px;
	}

	& button:focus {
		outline: none;
	}
}

@media only screen and (max-width: 768px) {
	.text-field-program-organization {
		border: 1px solid #B9BFBD;
		border-radius: 12px !important;
		padding: 12px 13px;
		font-size: 16px;
		line-height: 22px;
		letter-spacing: -0.5px;
	}

	.text-field-program-organization::placeholder {
		font-size: 14px;
	}
}