.section-programs-vacation {
	display: flex;
	flex-direction: column;
	align-items: center;
	/* &__title {
		font-size: 32px;
		line-height: 125%;
		&--bold {
			font-weight: bold;
		}
	} */
	&__container-programs {
		margin-bottom: 80px;
	}
}

@media (max-width : 768px){
	.section-programs-vacation {
		width: 100%;
		padding: 0 23px;
		&__title {
			margin-top: 50px; 
			margin-bottom: -26px; 
			text-align: center;
			/* width: 239px; */
		}
		&__container-programs {
			margin-bottom: 80px;
		}
	} 
}