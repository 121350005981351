.blog-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  .h1 {
    width: 60%;
    margin-bottom: 2rem;
  }
}

.post-list {
  width: 100%;
  display: grid;
  gap: 20px 30px;
  justify-items: center;
  align-items: start;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-auto-flow: row dense;
}
