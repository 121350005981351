.loader-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    align-items: center;
  }
}
