/* Trigger */
.trigger {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;

  height: 100%;
  cursor: pointer;

	svg {
		margin-top: 2.5px;
	}
}

/* Dropdown */
.dropdown-Wrapper {
  position: absolute;
  top: 75px;
  left: 0;
  height: auto;
  width: auto;

  background: transparent;
  opacity: 1;
  cursor: default;
}

.trigger-login {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;

  height: 48px;
  cursor: pointer;

	svg {
		margin-top: 2.5px;
	}
}

.dropdown-Wrapper-login {
  position: absolute;
  top: 50px;
  right: 0;
  height: auto;
  width: auto;

  background: transparent;
  opacity: 1;
  cursor: default;
}
