.select-field {
  @apply outline-none px-6
    h-12 border-2 border-black block w-full;
}

.select-field-purchase-flow {
  color: var(--color-black);
  background-color: var(--color-white);
  border-color: var(--color-gray);
  border-radius: 10px;
  transition: 0.4s;
  @apply outline-none px-6
    h-12 border-2  block w-full;
}

.select-field-contact-us {
  color: #bcbcbc;
  background-color: var(--color-white);
  border: 2px solid var(--color-gray);
  border-radius: 6px;
  padding: 16px 44px 14px 16px;
  transition: 0.4s;
  width: 100%;
  transition: 0.4s;

  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url('/icons/arrow-select.svg');
  background-repeat: no-repeat, repeat;
  background-position: right 20px top 50%, 0 0;
}

.select-field-purchase-flow:focus {
  color: var(--color-black);
  border-color: var(--color-black);
}
.select-field-purchase-flow:active {
  color: var(--color-black);
}

.select-field.error {
  @apply border-red-error;
}

.select-field-contact-home {
  border-color: #616161;
  color: #616161;
  border-radius: 10px;
  transition: 0.4s;

  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url('/icons/arrow-select.svg');
  background-repeat: no-repeat, repeat;
  background-position: right 20px top 50%, 0 0;

  @apply outline-none px-6
    h-12 border-2  block w-full;
}

.select-field-outline {
  color: var(--color-black);
  font-weight: 700;
  transition: 0.4s;
  @apply outline-none
    h-12  block w-full;
}

@media only screen and (max-width: 480px) {
  .select-field-contact-home {
    background-position: right 16px top 50%, 0 0;
  }
}
