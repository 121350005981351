.navbar {
  padding-top: 50px;
  padding-left: 100px;
  padding-right: 100px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
}

.faq_container {
  padding-top: 50px;
  padding-left: 100px;
  display: grid;
  grid-template-columns: 1fr 30%;
  grid-auto-rows: auto;
  grid-template-areas:
    'faqs image'
    'nav  main';
  grid-gap: 64px;

  & > .faqs {
    grid-area: faqs;

    & > .dashboard_hero {
      & > .body {
        margin: 0px;
      }
    }

    & > .faqs__faq-container {
      margin-top: 40px;

      & > .faq_list {
        & > hr {
          color: #dddddd;
        }
      }
    }
  }

  & > .image {
    grid-area: image;
    display: flex;

    & > img {
      width: 267px;
      height: 317px;
    }
  }
}

@media (max-width: 1024px) {
  .navbar {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: row-reverse;
  }

  .faq_container {
    padding-top: 16px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 100px;
    grid-template-columns: 1fr;
    grid-template-areas:
      'image'
      'faqs';
    grid-gap: 30px;

    & > .image {
      justify-content: center;

      & > img {
        width: 236px;
        height: 280px;
      }
    }

    & > .faqs .faqs__faq-container {
      margin-top: 15px;
    }
  }
}
