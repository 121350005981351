.ui-button {
	border-radius: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
	&__level-primary {
		color: white;
		background: #000000;
	}
	&__level-linear {
		
	}
	&__level-default {
		
	}
}