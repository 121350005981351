.our-values {
	display: flex;
	gap: 56px;
	margin-bottom: 80px;
	&__title {
		font-size: 28px;
		line-height: 32px;
		text-align: left;
		margin-top: 55px;
		&--bold {
			font-weight: bold;
		}
	}
	&__container-item {
		display: flex;
		flex-direction: column;
	}
	&__item {
		display: flex;
		flex-direction: row;
		gap: 19px;
		align-items: center;
	}
	&__item-image {
		width: 207px;
		min-width: 206px;
		max-width: 300px;
		margin: 0 auto;
		padding: 10px 0;
	}
	&__item-title {
		font-size: 21px;
		line-height: 32px;
		text-align: left;
		&--bold {
			font-weight: bold;
		}
	}
	&__item-description {
		font-size: 21px;
		line-height: 32px;
		text-align: left;
	}
}

@media only screen and (max-width: 920px) {
	.our-values {
		flex-direction: column;
		gap: 0;
		&__title {
			font-size: 28px;
			line-height: 32px;
			text-align: center;
			&--bold {
				font-weight: bold;
			}
		}
		&__container-item {
			gap: 56px;
		}
		&__item {
			flex-direction: column;
		}
		&__item-image {
			width: 207px;
			min-width: 206px;
			margin: 0 auto;
			padding: 0;
		}
		&__item-title {
			font-size: 21px;
			line-height: 32px;
			text-align: center;
			&--bold {
				font-weight: bold;
			}
		}
		&__item-description {
			font-size: 21px;
			line-height: 32px;
			text-align: center;
		}
	}
}