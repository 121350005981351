.nps-content-Wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    &__controls {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }
    &__content {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding-top: 42px;
        .textarea-nps-design {
            width: 630px;
            height: 130px;
            background: #F6F6F6;
            border-radius: 4px;
            resize: none;
            padding: 10px;
            margin-bottom: 46px;
            &::placeholder {
                color: #BCBCBC;
            }
        }
        .textarea-nps-design-xl {
            width: 630px;
            height: 247px;
            background: #F6F6F6;
            border-radius: 4px;
            resize: none;
            padding: 10px;
            margin-bottom: 46px;
            &::placeholder {
                color: #555555;
            }
        }
        &_image-title{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-bottom: 86px;
        }
        &_image{
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 200px;
          height: 170px;
        }
        &_image-next{
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 200px;
          height: 148px;
        }
        &_image-last{
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 200px;
          height: 148px;
        }
        .button--variant-nps{
          max-width: 209px;
          width: 100%;
          height: 56px;
          border-radius: 8px;
          padding-top: 0;
          background: #000000;
          &:hover {
            background: #555555;
          }
          &:focus {
            outline: none;
          }
        }
        .disable{
          background-color: #E5E5E5;
          color: #BCBCBC;
          opacity: 1;
          cursor: not-allowed;
          &:hover {
            background: #E5E5E5;
          }
          &:focus {
            outline: none;
          }
        }
        &_button {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 209px;
            text-align: center;
            font-family: 'Roca One';
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 21px;
        }
        &_link {
            font-family: 'Roca One';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 21px;
            display: flex;
            align-items: center;
            text-decoration-line: underline;
            color: #000000;
    
            &:hover {
              color: #555555;
            }
        }
        &_linkback {
          font-family: 'Roca One';
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 21px;
          display: flex;
          align-items: center;
          text-decoration-line: underline;
          color: #000000;
          position: absolute;
          left: 50px;
          cursor: pointer;
  
          &:hover {
            color: #555555;
            & > svg {
              fill: #555555;
            }
          }

          & > svg {
            fill: #000000;
          }
      }
    }
    &__footer{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
}

@media only screen and (max-width: 768px) {
  .nps-content-Wrapper {
    &__content {
      padding-top: 24px;
      .textarea-nps-design {
        height: 247px;
      }
      &_image-title{
        width: 318px !important;
        margin-bottom: 20px !important;
        margin-top: 0;
      }
      &_image{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 200px;
        height: 180px;
      }
      &_image-next{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 200px;
        height: 138px;
      }
      &_image-last{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 200px;
        height: 165px;
      }
      .textarea-nps-design {
        width: 318px;
        height: 82px;
        margin-bottom: 36px;
      }
      .textarea-nps-design-xl {
        width: 318px;
        height: 190px;
        margin-bottom: 36px;
      }
      &_button {
        &:hover {
          background: transparent;
        }
    }
    }
    &__footer{
      position: absolute;
      bottom: 130px;
    }
  }
}
  