.ui-card-path-course-progress {
	border-radius: 16px;
	min-height: 404px;
	transition: all 0.8s;
	min-width: 100%;
	overflow: hidden;	
	&__header {
		padding: 8px 16px;
		display: flex;
		gap: 17px;
	}
	&__header-principal {
		width: 100%;
	}
	&__header-title-status {
		display: flex;
		justify-content: space-between;	
	}
	&__header-title-status > p:first-child {
		margin-top: 14px;
	}
	&__header-start-date {
		display: flex;
		justify-content: space-between;	
	}
/* 	&__body {
		padding: 13px 16px 38px 16px;
		display: flex;
		gap: 16px;
	}
	&__column:first-child {
		width:auto;
		padding: 0;
	}
	
	&__container-image {
		width: 100px;
		height: 100px;
		border-radius: 50%;
		margin-top: 13px;
		background: #dcdcdc;
		overflow: hidden;
		
	} */
}