.login-form {
	display: flex;
	flex-direction: column;
	gap: 16px;
	.text-field {
		border-radius: 0.5rem;
		height: 3.06rem;
		border-width: 2px;
		transition: all 0.4s ease;
		border-color:  #000000;
	}
	.text-field:hover, .text-field:focus {
		border-color: #000000;
	}
	.button--variant-link {
		width: auto;
		text-align: right;
		font-size: 0.875rem;
		line-height: 0.88rem;
		margin-bottom: 1.4rem;
		margin-left: auto;
	}
	.button:last-child {
		border-radius: 0.5rem;
		height: 2rem;
		padding: 0 1.5rem;
		&:hover {
			transform: scale(1.04, 1.04);
			background: #000000;
			padding: 0 auto;
		}
	}
}

@media (min-width : 1024px){
	.login-form {
		.text-field {
			height: 3.13rem;
			border-width: 2px;
		}
		.button--variant-link {
			font-size: 0.875rem;
			margin-bottom: 2.25rem;
			margin-top: 0.8rem;
		}
		.button:last-child {
			height: 2.5rem;
			padding: 0 3.25rem;
		}
	}
}
