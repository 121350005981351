.info_home-Wrapper {
  display: flex;
  justify-content: center;
  background: #ffffff;
  border-radius: 32px;
  padding: 80px 92px;

  width: 1264px;
  margin: 0 auto 32px auto;

  .info_home {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;

    grid-template-areas: 'img text';

    max-width: 1080px;

    /* Text */
    &--text {
      grid-area: text;
      z-index: 80;

      &_description {
        margin: 0 0 32px 0;
      }

      &_options {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        & > div:first-of-type {
          margin: 0 24px 0 0;
        }
      }
    }

    /* Image */
    &--image {
      grid-area: img;
      margin: 0 85px 0 0;

      img {
        width: 468px;
        height: 309px;
        margin: -4% 0 0 0;
        animation: hide_show .25s cubic-bezier(0.47, 0, 0.75, 0.72);
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .info_home-Wrapper {
    width: 90%;
    margin: 0 auto 24px auto;
    padding: 40px 16px;

    .info_home {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 210px auto;

      grid-template-areas:
        'img'
        'text';

      /* Text */
      &--text {
        grid-area: text;

        &-description {
          margin: 0 0 1.5rem 0;
        }

        &_options {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          align-items: center;

          & > div:first-of-type {
            margin: 0 0 24px 0;
          }
        }
      }

      .button-component {
        margin: 0 auto;
      }

      /* Image */
      &--image {
        grid-area: img;
        width: 100%;
        height: auto;
        margin: 0 0 0 0;
        height: 194px;

        & > span {
          width: 100%;
          height: 100%;

          & > img {
            width: 100%;
            height: 100%;
            margin: 0 0 0 0;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 480px) {
}
