.container-press-release {
	width: calc(100vw - 20px);
	height: 35.937rem;
	overflow: hidden;
	position: absolute;
	bottom: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.container-press-release-deg {
	background: linear-gradient(-180deg, #FFC0D4 17.05%, #ECBDD8 26.11%, #C0B5E2 49.49%, #A0AFE9 70.43%, #8CABED 87.98%, #85AAEF 99.98%);
}
.container-press-release h2{
	font-family: 'Roca One';
	font-style: normal;
	font-weight: bold;
	font-size: 1.9rem;
	line-height: 35px;
	margin: 3.12rem auto 2.63rem auto;
}
.light-bg {
	position: absolute;
	width: 579.17px;
	height: 1466.51px;
	top: -800px;
	background: radial-gradient(50% 50% at 50% 50%, #F3F3F3 0%, rgba(243, 243, 243, 0) 100%);
	mix-blend-mode: soft-light;
}
.content-press-release {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	width: 15rem;
	height: 22.4rem;	
	background: #FFFFFF;
	box-shadow: 0px 11.9333px 29.8333px rgba(14, 86, 124, 0.2);
	border-radius: 15px;
	border: 2px solid #ffffff;
	padding: 0.56rem 1.39rem 1.85rem 1.39rem;
	font-family: 'Roca One';
	font-style: normal;
	transition: 0.4s;
	margin: 0.5rem; 
}
.content-press-release:hover {
	transform: scale(1.04, 1.04);
}
.content-press-release img{
	margin-bottom: 0.78rem;
}
.press-release-date {
	width: 100%;
	font-weight: normal;
	font-size: 0.84rem;
	line-height: 1.56rem;
	color: #616161;
	margin-bottom: 0.91rem;
}
.press-release-description {
	font-weight: normal;
	font-size: 1.12rem;
	line-height: 1.56rem;
	color: #000000;
}
.button-press-release {
	width: 9.2rem;
}
.button-press-release .link--black-w-icon {
	padding: 0.5rem 2rem; 
	font-family: 'Roca One';
}
@media (min-width : 1024px) {
	.container-press-release {
		margin-bottom: -48px;
	}
}
@media (min-width : 1440px){
	.container-press-release {
		height: 46.7rem;
		overflow: hidden;
	}
	.light-bg {
		position: absolute;
		width: 579.17px;
		height: 1466.51px;
		top: -800px;
		background: radial-gradient(50% 50% at 50% 50%, #F3F3F3 0%, rgba(243, 243, 243, 0) 100%);
		mix-blend-mode: soft-light;
	}
	.content-press-release {
		width: 20.25rem;
		height: 30rem;	
		padding: 0.75rem 1.81rem 2.5rem 1.81rem;
	}
	.content-press-release img{
		margin-bottom: 1rem;
	}
	.press-release-date {
		font-size: 1.125rem;
		line-height: 2.125rem;
		margin-bottom: 1rem;
	}
	.press-release-description {
		font-size: 1.5rem;
		line-height: 2.125rem;
		color: #000000;
	}
	.button-press-release {
		width: 12.375rem;
	}
	.button-press-release .link--black-w-icon {
		padding: 0.625rem 3.75rem; 
	}
}

