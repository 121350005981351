.dropdown {
  height: auto;

  margin: 7px 0 0 0;
  background: white;
  border-radius: 16px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1), 0px 15px 40px rgba(0, 0, 0, 0.08);

  &__item {
    cursor: pointer;
    height: 40px;
		
		a {
			padding: 8px 19px;
			display: flex;
			flex-direction: column;
			align-items: stretch;
		}

    &:hover {
      background: #f6f6f6;
    }

    &--complex {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  /* Complex */
  &--simple {
    min-width: 330px;
    padding: 16px 16px;
  }

  /* Complex */
  &--complex {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: space-between;
    min-width: 896px;
    min-height: 245px;
    height: 245px;

    &_left {
      width: 284px;
      padding: 16px 8px 16px 8px;
    }
    &_center {
      padding: 16px 8px 16px 8px;
      border-left: 1px solid #f1f1f1;
      height: 100%;
    }
    &_right {
      width: 334px;
      height: 229px;
      margin: 8px 8px 8px 0px;
      border-radius: 12px;
      overflow: hidden;
      cursor: pointer;

      & > span {
        display: block !important;
        width: 100%;
        height: 100%;

        & > img {
          width: 100%;
          height: 100%;
        }
      }
      /* img {
        border-radius: 8px;
      } */
    }
  }
}
