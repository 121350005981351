.header_content-Section {
  align-self: center;
  display: grid;
  grid-template-columns: repeat(2, 50%);
  justify-items: center;
  align-items: center;
  justify-content: center;
  width: 90%;

  .header_content-Content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    h1 {
      font-size: calc(40px + (60 - 40) * ((100vw - 300px) / (1600 - 300)));
      margin-bottom: 1rem;
    }
    p {
      font-size: calc(18px + (20 - 18) * ((100vw - 300px) / (1600 - 300)));
    }
    img {
      object-fit: contain;
    }
  }
}

.header_content_course-Section {
  align-self: center;
  display: grid;
  grid-template-columns: repeat(2, 50%);
  justify-items: center;
  align-items: center;
  justify-content: center;
  column-gap: 1rem;
  width: 90%;

  .header_content-Content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    h1 {
      font-size: calc(40px + (60 - 40) * ((100vw - 300px) / (1600 - 300)));
      margin-bottom: 1rem;
    }
    p {
      font-size: calc(18px + (20 - 18) * ((100vw - 300px) / (1600 - 300)));
    }
    img {
      object-fit: contain;
    }
  }

  .desktop_img {
    max-width: 529px;
  }

  .img_course_banner_mobile {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .header_content-Section {
    grid-template-columns: 1fr;
    .header_content-Content {
      h1 {
        width: 100%;
        text-align: center;
      }
      p {
        text-align: justify;
      }
    }
  }

  .header_content_course-Section {
    grid-template-columns: 1fr;
    .header_content-Content {
      h1 {
        width: 100%;
        text-align: center;
      }
      p {
        text-align: justify;
      }
    }
    img {
      display: none;
    }
    .img_course_banner_mobile {
      display: block;
    }
  }
}
