.game-jam-digital-skills-Wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 130px 0 130px 0;
    .game-jam-digital-skills {
      display: flex;
      gap: 32px;
      max-width: 1080px;
      width: 100%;
  
      &__image {
        display: flex;
      }
      &__list {
        &__item {
          margin-bottom: 50px;
        }
      }
    }
  }


@media only screen and (max-width: 768px) {
  .game-jam-digital-skills-Wrapper {
    padding: 80px 20px 80px 20px;
    overflow: hidden;
    .game-jam-digital-skills {
      gap: 0;
      flex-direction: column-reverse;
      align-items: center;
      &__title {
        text-align: center;
        margin-top: 20px;
      }
    }  
  }
}