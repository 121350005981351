.whitout-courses-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}
.whitout-courses-welcome-img {
  max-width: 11%;
  margin-bottom: 0.5rem;
}
.whitout-courses-title {
  font-size: 1.8rem;
  font-weight: 800;
  text-align: center;
}
.whitout-courses-content {
  margin: 0.8rem 0;
  width: 100%;
  font-size: 1.2rem;
  text-align: center;
}
.whitout-courses-content > a {
  font-weight: 800;
  text-decoration: underline;
}
.whitout-courses-content-button-wrapper {
  margin: 0.5rem 0;
}

@media only screen and (max-width: 768px) {
  .whitout-courses-welcome-img {
    max-width: 40%;
  }
  .whitout-courses-title {
    font-size: 2rem;
  }
  .whitout-courses-content {
    font-size: 1rem;
    text-align: justify;
  }
}

@media only screen and (max-width: 425px) {
  .whitout-courses-welcome-img {
    max-width: 50%;
  }
  .whitout-courses-content {
    font-size: 1rem;
    text-align: justify;
  }
}
