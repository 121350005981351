.description_purcchase-Wrapper {
  width: 444px;
  margin-left: 50px;
  display: flex;
  flex-direction: column;
  .thanks {
    &:nth-child(2) {
      display: none;
    }
  }
  h4 {
    margin-bottom: 0rem;
    font-size: calc(10px + (18 - 14) * ((100vw - 300px) / (1600 - 300)));
    color: #017ad5;
    display: inline-block;
    padding: 4px 13px 3px 13px;
    background: #ffffff;
    border: 1px solid #017ad5;
    border-radius: 8px;
  }
  p {
    &:last-child {
      margin-bottom: 0;
      text-transform: inherit;
    }
  }

  .description_purchase-Container {
    padding: 1rem 2rem;
    padding-bottom: 25px;
  }

  .title-section {
    svg {
      max-width: 100px;
    }
    h1 {
      font-size: 22px;
      left: 0;
    }
    svg {
      justify-self: end;
    }
  }

  .price-section {
    color: #017ad5;
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 24px;
  }

  .text-black {
    color: black !important;
  }

  .accent-txt {
    border-radius: 20px;
    background-color: var(--color-violet);
    padding: 0.2rem 1rem;
    color: var(--color-white);
    margin-bottom: 2rem;
  }

  .accent-title {
  }
  p {
    font-size: calc(12px + (17 - 12) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 800;
    margin-bottom: 1.5rem;
    text-transform: capitalize;
  }
}

.description_purcchase-Wrapper_mobile {
  display: none;
  width: 90%;
  justify-content: center;
  align-items: center;

  h1 {
    text-align: center;
    font-size: calc(20px + (35 - 20) * ((100vw - 300px) / (1600 - 300)));
    strong {
      color: var(--color-violet);
    }
  }

  .description_purchase_card-accordion {
    margin: 1rem 0;
    padding: 0 1rem;

    p {
      color: var(--color-cadetBlue);
      font-weight: 800;

      span {
        color: var(--color-black);
        font-weight: 400;
      }
    }
  }
}

.description_purchase_accordion__section {
  display: flex;
  flex-direction: column;
  background: var(--color-gray-white);
  transition: background-color 0.6s ease;
  margin: 2rem 0;

  .accordion {
    display: grid;
    grid-template-columns: 90% 1fr;
    justify-items: center;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem;
    transition: background-color 0.6s ease;
    outline: inherit;

    h4 {
      font-size: calc(16px + (20 - 16) * ((100vw - 300px) / (1600 - 300)));
      font-weight: 800;
      margin-bottom: 0;
    }
  }

  .accordion__title {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 14px;
    text-align: left;
  }

  .accordion__icon {
    margin-left: auto;
    transition: transform 0.6s ease;
  }

  .rotate {
    transform: rotate(45deg);
  }

  .accordion__content {
    background-color: white;
    overflow: auto;
    transition: max-height 0.6s ease;
  }

  .accordion__text {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
    padding: 18px;
  }
}

.description_purchase_accordion__section:hover,
.active {
  border: none;
  box-shadow: none;
  transform: none;
}

.description_purchase-Prices {
  padding: 1rem 2rem;
  .price-section {
    margin-bottom: 0;
  }
}
.description-purchase-Prices-left {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 21px;
  p {
    margin-bottom: 0;
  }
  span {
    font-size: 17px;
  }
}

.cupon-section {
  padding: 1rem 2rem;
  background: #faf9fb;
  p {
    margin-bottom: 10px;
    text-transform: inherit;
  }
  input {
    padding: 0.5rem;
    margin-right: 20px;
    border-radius: 8px;
    border: 2px solid black;
    margin-bottom: 10px;
  }
}

.price-original {
  color: black;
  font-weight: 300 !important;
  position: relative;
  text-decoration: line-through;
}

.coupon-message {
  font-size: 13px !important;
  color: gray;
  margin-top: 15px;
  text-align: center;
  font-weight: inherit !important;
}

.m-description_purchase_cupon-Wrapper-container {
  display: none;
  width: 100%;
}

.m-cupon-Wrapper {
  width: 100%;
  background-color: #e2efff;
  border-radius: 8px;
  padding: 16px 16px;
  margin: 10px 0 20px 0;
}

.m-description_purchase_cupon-Wrapper {
  background-color: #e2efff;
  border-radius: 8px;
  padding: 16px 16px;
  width: 100%;
}

.m-description_purchase {
  width: 100%;
}

.m-description_purchase-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  margin-bottom: 15px;

  & .m-description_purchase-header-button {
    & button:focus {
      outline: none;
    }

    & button p {
      text-decoration: underline;
    }
  }
}

.m-description_purchase-header-tag {
  border: solid #017ad5 1px;
  border-radius: 8px;
  padding: 4px 8px;
  width: fit-content;
  background-color: #ffffff;
  color: #017ad5;
  font-size: 12px;
  margin-bottom: 10px;
}

.m-description_purchase-body {
  width: 100%;

  & p {
    font-size: 16px;
  }

  & > p {
    font-weight: 700;
  }

  & div {
    display: flex;
    align-items: center;
    & p:first-child {
      color: #017ad5;
    }
    & p:last-child {
      font-size: 12px;
      text-decoration: line-through;
      margin-left: 10px;
    }
  }
}

.m-description_purchase_cupon-Wrapper-modal {
  width: 100%;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
}

@media only screen and (max-width: 1024px) {
  .description_purcchase-Wrapper {
    .title-section {
      display: grid;
      grid-template-columns: 1fr;
      justify-items: center;
      align-items: end;
      svg {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .m-description_purchase-header-tag {
    margin-bottom: 0px;
  }

  .m-description_purchase_cupon-Wrapper-container {
    display: flex;
    flex-direction: column;
  }

  .description_purcchase-Wrapper_mobile {
    display: none;
  }

  .description_purcchase-Wrapper {
    display: none;
  }

  .m-description_purchase_cupon-Wrapper {
    display: flex;
  }
}

.bg-course {
  background: #e2efff;
  border-radius: 16px;
  overflow: hidden;
}

.m-0 {
  margin: 0 !important;
}

.content-all-cards {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.m-cupon-Wrapper-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.m-cupon-Wrapper-header-button {
  & button:focus {
    outline: none;
  }
}

.m-cupon-Wrapper-body {
  margin-top: 10px;
  .cupon-input {
    padding: 0.5rem 0.2rem;
    outline: none;
    border-radius: 8px;
    border: solid 2px black;
    width: 57%;
  }
  & button {
    width: 40%;
  }
}

.m-description_purchase_cupon-Wrapper-modal {
  & .m-description_purchase-header-tag {
    width: fit-content;
  }

  & .m-description_purchase-body {
    & p {
      font-size: 20px;
    }

    & div p {
      font-size: 18px;
    }

    & div {
      display: flex;
      align-items: center;
      & p:last-child {
        font-size: 16px;
        text-decoration: line-through;
        margin-left: 10px;
      }
    }
  }

  & > div:first-child {
    margin: 15px 0;
  }

  & div:nth-child(4) {
    margin-top: 10px;
  }

  & button {
    margin-top: 30px;
  }
}

.row-container {
  display: flex;
  margin-top: 10px;
  /* align-items: center; */
  /* justify-content: flex-start; */
  font-size: 16px;
  & span {
    margin-right: 5px;
  }
}
