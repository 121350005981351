.custom-phone-input-field {
  border-radius: 8px;
  border: 2px solid #000000;
  /* overflow: hidden; */
}

.custom-phone-input-field-contact-us {
  width: 100% !important;
  height: 48px !important;
  border: 2px solid #b2b2b2 !important;
}

.custom-phone-input-field .form-control {
  padding-left: 126px;
}

.custom-phone-input-field .phone-input-field {
  border-radius: 10px !important;
  height: 52px !important;
  width: 100% !important;
  border: none !important;
}

.custom-phone-input-field .flag-dropdown {
  /* border: 1px solid red; */
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
  border-right: 2px solid #000000;
  border-left: none;
  border-bottom: none;
  width: 108px !important;
  background: transparent;
}

.custom-phone-input-field input {
  /* margin-left:  -58px !important; */
  width: 100% !important;
}
.custom-phone-input-field .selected-flag {
  padding: 0 0 0 27px;
  width: 100px;
}

.custom-phone-input-field .flag-dropdown .arrow {
  margin-left: 15px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #555;
}
