.MuiPaper-root {
  background: transparent !important;
  border-radius: 16px;
  box-shadow: inherit !important;
}
.MuiBox-root {
  position: relative;
  top: 40px;
  color: white;

  .css-i4bv87-MuiSvgIcon-root {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    color: white;
  }
}
.MuiDialogContent-root {
  padding: 0 !important;
  border-radius: 16px;
}
