.post-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  font-size: 0.8rem;
  padding-bottom: 1rem;
  cursor: pointer;
  transition: box-shadow 0.2s ease-in-out;

  h3 {
    width: 90%;
    font-weight: 700;
    margin-top: 1rem;
    font-size: 1.1rem;
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 150px;
  }

  time {
    width: 90%;
    font-weight: 500;
    color: #333333;
    margin-bottom: 0.6rem;
  }

  p {
    width: 90%;
    color: #333333;
    font-weight: 400;
  }
}

.post-card:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
