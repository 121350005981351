.user_recruitment-Tile {
  justify-self: center;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  margin-bottom: 2rem;

  button {
    width: 310px;
  }
}

.user_recruitment {
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;

  h2 {
    color: var(--color-violet);
  }
  h3 {
    color: var(--color-violet);
    font-size: calc(18px + (28 - 18) * ((100vw - 300px) / (1600 - 300)));
    margin: 1.5rem 0 0 0;
  }
  h4 {
    color: var(--color-gray-dark);
    margin-bottom: 0;
  }

  .selection_area-Wrapper {
    width: 100%;
    margin: 1rem 0;
    display: grid;
    grid-template-columns: repeat(2, minmax(310px, 1fr));
    column-gap: 20px;
    align-items: start;

    .selection_area {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      h5 {
        font-size: calc(16px + (18 - 16) * ((100vw - 300px) / (1600 - 300)));
        font-weight: 700;
      }
    }
    .course_item_card {
      cursor: pointer;
      background-color: #eeeeee;
      box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      border: solid 3px transparent;
      padding: 0.5rem 1rem;
      margin-top: 1rem;
      width: 100%;
      transition: 0.4s;
      p {
        text-align: center;
        margin: 0;
        font-size: calc(18px + (20 - 18) * ((100vw - 300px) / (1600 - 300)));
        font-weight: 700;
      }

      &:hover {
        opacity: 0.8;
        border: solid 3px #000000;
        transform: scale(0.97);
      }
    }
    .course_item_card.active {
      opacity: 0.8;
      color: #000000;
      border: solid 3px #000000;
      transform: scale(0.97);
    }
  }
}

@media screen and (max-width: 1200px) {
  .user_recruitment-Tile {
    padding: 0 2rem;
  }
}

@media screen and (max-width: 768px) {
  .user_recruitment-Tile {
    .user_recruitment {
      .selection_area-Wrapper {
        grid-template-columns: 1fr;
        row-gap: 30px;
      }
      .selection_area-Wrapper:last-child {
        row-gap: 0px;
      }
    }
  }
}

@media screen and (max-width: 320px) {
  .user_recruitment-Tile {
    button {
      width: 100%;
    }
  }
}
