.breadcrumbs {
  @apply flex items-center mb-2;

  a {
    @apply mr-2 text-sm;

    &:hover {
      @apply underline;
    }
  }

  span {
    @apply mr-2;
  }

  span:last-child {
    @apply mr-0;
  }

  p {
    @apply mb-0;
  }
}
