.alice-carousel {
	padding: 0 0.5rem;
	width: 100%;
}
.alice-carousel div li {
	width: 256px !important;
	height: 380px;
}
.alice-carousel__prev-btn, .alice-carousel__next-btn {
	position: relative;
	bottom: 52px;
}
.alice-carousel__prev-btn span, .alice-carousel__next-btn span{
	margin: 0 50px;
	color: black;
	font-size: 1.5rem;
	height: 0.9rem;
}
.alice-carousel__dots-item:not(.__custom):hover, .alice-carousel__dots-item:not(.__custom).__active {
	color: black;
}
.alice-carousel__dots-item:not(.__custom):hover, .alice-carousel__dots-item:not(.__custom).__active {
	background-color: black;
}
.alice-carousel__prev-btn [data-area]::after, .alice-carousel__next-btn [data-area]::after {
	position: relative;
	content: attr(data-area);
	text-transform: capitalize;
	font-weight: bold;
}
.alice-carousel__dots-item:not(.__custom){
	font-size: 2.9rem;
	width: 0.9rem;
	height: 0.9rem;
	
}
@media (min-width : 1024px){
	.alice-carousel {
		padding: 0 6rem;
		width: 100%;
	}   
	.alice-carousel div li {
		width: 275px !important;
	}
}

@media (min-width : 1440px) {
	.alice-carousel {
		padding: 0 9rem;
		width: 100%;
	}
	.alice-carousel div li {
		width: 30% !important;
		height: 510px;
	}


	@media (min-width : 1920px){

		.alice-carousel div li {
			width: 400px !important;
		}
	}
}