.footer {
  @apply bg-black text-white;

  .logo {
    @apply mb-4;
    font-size: 6rem;

    & > svg {
      margin: 20px 0 0 0;
    }
  }

  & > div {
    @apply mx-auto px-10 py-10;
    max-width: calc(1200px + 5rem);
  }
}

.footer-links__container {
  @screen lg {
    @apply flex justify-between;

    /* & > p {
      @apply pb-2;
    } */
  }
}

.footer-links__container2 {
  width: 100%;
  margin-top: 1rem;
  @apply flex justify-between items-center flex-wrap;
}

.footer__links {
  @apply mb-12;

  li {
    @apply text-lg mb-4;

    div:hover {
      cursor: pointer;
      @apply underline;
    }
  }

  @screen lg {
    @apply mb-0 mr-6;

    &:first-child {
      padding-top: 40px;
    }

    &:last-child {
      @apply mr-0;
    }
  }
}

.footer__links2 {
  @apply flex justify-items-start items-center flex-wrap;

  li {
    @apply text-lg mb-4;

    a:hover {
      @apply underline;
    }
  }
}

.footer__links2 > li {
  font-size: 14px;
  margin-right: 1rem;
  text-align: center;
}

@media only screen and (max-width: 425px) {
  .footer__links2 {
    margin-bottom: 1rem;
  }
  .footer__links2 > li {
    margin-bottom: 1rem;
  }
}
