@import "react-alice-carousel/lib/alice-carousel.css";

.content-landing-companies {
	display: flex; 
	flex-direction: column; 
	align-items: center;
	position: relative;
	padding-bottom: 35.9rem;
	gap: 3.12rem;
}

.content-landing-companies > h1 {
	font-family: 'Roca One';
	color: #000000;
	text-align: center;
	font-weight: bold;
	font-size: 30px;
	line-height: 45px;
}
.content-landing-companies > section:last-of-type .container-info-video{
	order: -1;
}
@media (min-width : 1020px){
	.content-landing-companies {
		padding-bottom: 37.5rem;
	}
	.content-landing-companies > h1 {
		font-family: 'Roca One';
		color: #000000;
		text-align: center;
		font-weight: bold;
		font-size: 40px;
		line-height: 70px;
	}

	.content-landing-companies .footer {
		position: relative;
    top: -48px;
	}
}

@media (min-width : 1423px){
	.content-landing-companies {
		padding-bottom: 49rem;
	}
}