.game-jam {
  background: url(/game-jam/background.webp);
  background-position: center top;
  background-repeat: no-repeat;
  background-color: rgb(7 13 36);
}

.game-jam-hero-Wrapper {
  position: relative;
  display: flex;
  justify-content: center;

  padding: 156px 0 100px 0;
  background-position: center;

  .game-jam-hero {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 53px;

    max-width: 1254px;
    width: 100%;

    /* Description */
    &__description {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 56px;

      &_title {
        text-align: center;
      }

      &_button {
        display: flex;
        justify-content: center;
        align-items: center;

        background: radial-gradient(
          66.89% 438.39% at 85.69% 92.86%,
          #5626f5 0%,
          #8400ec 100%
        );
        color: white;
        max-width: 367px;
        width: 100%;
        height: 56px;
        border-radius: 8px;
        cursor: pointer;
        text-transform: uppercase;
        transition: all 0.2s ease-in;

        &:hover {
          background: radial-gradient(
            66.89% 438.39% at 85.69% 92.86%,
            #ff43e0 0%,
            #ff43e0 100%
          );
        }
      }
    }
  }

  &__mouster1 {
    position: absolute;
    top: -70px;
    left: 57px;
  }

  &__mouster2 {
    position: absolute;
    bottom: 50px;
    right: -25px;
  }
}

@media only screen and (max-width: 768px) {
  .game-jam-hero-Wrapper {
    padding: 118px 20px 80px 20px;
    overflow: hidden;

    .game-jam-hero {
      gap: 113px;

      /* Description */
      &__description {
        gap: 40px;

        &_button {
          height: 43px;
        }
      }
    }

    &__mouster1 {
      position: absolute;
      top: -25px;
      left: 25px;
    }

    &__mouster2 {
      position: absolute;
      top: 211px;
      right: -15px;
    }
  }
}
