.hero-home-Wrapper {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 0 0 176px 0;
	padding: 81px 0 30px 0;

	&--hidde-show-bar {
		margin: 0px 0 30px 0;
	}

	&_background {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 615px;
		z-index: 1;
	}

	.hero-home {
		display: grid;
		align-items: center;
		grid-template-columns: 559px 666px;
		grid-template-rows: auto;
		grid-template-areas: 'text img';
		column-gap: 59px;

		max-width: 1254px;
		z-index: 2;

		/* Text */
		&--text {
			grid-area: text;
		}

		/* Image */
		&--image {
			position: relative;
			grid-area: img;
			margin: 0 0 0 0;

			/* Play */
			&_play {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 108px;
				height: 108px;
				background: rgba(0, 0, 0, 0.5);
				border-radius: 50%;

				&>svg {
					margin: 0 0 0 14px;
				}
			}

			&>div>div {
				border-radius: 16px;
				filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.08));

				&>iframe {
					border-radius: 16px;
					filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.08));
				}
			}

			/* Code */
			&_code {
				position: absolute;
				width: 308px;
				height: 271px;
				top: -57px;
				left: -88px;
				z-index: -1;
			}

			/* Minecraft */
			&_minecraft {
				position: absolute;
				width: 260px;
				height: 208px;
				bottom: -189px;
				right: 50px;
			}
		}
	}
}

@media only screen and (max-width: 768px) {
	.hero-home-Wrapper {
		margin: 52px 0 160px 0;
		padding: 22px 0 0 0;

		&--hidde-show-bar {
			margin: 0px 0 56px 0;
		}

		&_background {
			/* height: 715px; */
			height: 740px;
		}

		.hero-home {
			grid-template-columns: 1fr;
			grid-template-rows: auto;
			grid-template-areas:
				'img'
				'text';
			column-gap: 0px;
			row-gap: 57px;
			width: 90%;

			/* Image */
			&--image {
				grid-area: img;
				margin: 0 0 0 0;

				&>div>div {
					border-radius: 16px;
					filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.08));

					&>iframe {
						border-radius: 16px;
						filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.08));
					}
				}

				/* Background */
				&_background {
					position: absolute;
					width: 337px;
					height: 190px;
					top: 15px;
					right: -13px;
					border-radius: 16px;
					z-index: -1;
				}

				/* Code */
				&_code {
					position: absolute;
					width: 175px;
					height: 154px;
					top: -15px;
					left: -48px;
				}

				/* Minecraft */
				&_minecraft {
					width: 148px;
					height: 118px;
					bottom: -68px;
					right: 16px;
				}
			}
		}
	}
}