.contact-support {
	&__container {
		margin: 0 1.5rem 0 2rem;
		display: flex;
    flex-direction: column;
    align-items: center;
	}
	&__title {
		max-width: 1168px;
		width: 100%;
	}
}
.contact_support_content {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: minmax(320px, 646px) minmax(320px, 502px);
  column-gap: 20px;
  row-gap: 25px;
  justify-content: center;
  align-items: start;
}

@media only screen and (max-width: 1460px) {
  .contact_support_content {
    justify-content: space-between;
  }
}

@media only screen and (max-width: 768px) {
  .contact_support_content {
    grid-template-columns: 1fr;
  }
}
