.contact_us_card {
  max-width: 460px;
  min-width: 310px;
  box-shadow: -5px 16px 40px rgba(14, 86, 124, 0.2);
  border-radius: 20px;
  padding: 2.2rem 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  h4 {
    font-size: calc(20px + (20 - 20) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 700;
    line-height: 20px;
    margin: 0 0 1.8rem 0;
  }

  .mail-section {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    p {
      margin: 0 0 0 1.2rem;
      font-size: 18px;
      color: var(--color-gray-dark);
    }
  }
  .social_media_bar {
    width: 100%;
    margin: 2.5rem 0;
    display: grid;
    column-gap: 2em;
    row-gap: 2em;
    grid-template-columns: repeat(auto-fit, 25px);
    justify-content: start;
    justify-items: center;
    align-items: end;
  }
  .ask_frecuency-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    li {
      font-size: 16px;
      font-weight: 700;
      line-height: 16px;
      margin-bottom: 2.2rem;
      list-style-type: disc;
      list-style-position: inside;
    }
    p {
      font-size: 15px;
      font-weight: 400;
      line-height: 15px;
      margin-bottom: 2.2rem;
      color: var(--color-gray-dark);
    }
  }
  .btn-whasapp {
    margin-top: 0.6rem;
    a {
      border-radius: 10px;
      border: none;
    }
  }
}

.contact_us_dashboard_card {
  background: #ffffff;
  box-shadow: -5px 5px 30px rgba(14, 86, 124, 0.05);
  border-radius: 20px;
  padding: 2.5rem;

  h2 {
    font-size: calc(18px + (21 - 18) * ((100vw - 300px) / (1600 - 300)));
    margin-bottom: 2.5rem;
  }

  .contact_us_form {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;

    select,
    textarea {
      margin-bottom: 2.5rem;
    }

    button {
      width: 129px;
    }
  }

  h4 {
    font-size: calc(20px + (20 - 20) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 700;
    line-height: 20px;
    margin: 1rem 0 1.8rem 0;
  }

  .mail-section {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    p {
      margin: 0 0 0 1.2rem;
      font-size: 18px;
      color: var(--color-gray-dark);
    }
  }
  .social_media_bar {
    width: 100%;
    margin: 2.5rem 0;
    display: grid;
    column-gap: 2em;
    row-gap: 2em;
    grid-template-columns: repeat(auto-fit, 25px);
    justify-content: start;
    justify-items: center;
    align-items: end;
  }
}
