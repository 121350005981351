.tag {
  @apply bg-black text-white text-xs font-bold
    h-6 pt-1 px-2 mb-3 whitespace-no-wrap uppercase;
  width: fit-content;
  font-family: Montserrat, sans-serif;
  letter-spacing: 2px;
}

.tag--color-red {
  @apply bg-red;
}

.tag--color-green {
  @apply bg-green;
}

.tag--color-grey {
  background-color: rgba(107, 114, 128, 1);
}
