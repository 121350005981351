.game-jam-why-participate-Wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 0 100px 0;

  .game-jam-why-participate {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    max-width: 1080px;
    width: 100%;

    &__list {
      display: flex;
      justify-content: center;
      align-items: stretch;
      gap: 24px;
      text-align: center;
      width: 100%;
      margin-bottom: 70px;

      &__item {
        border: 1px solid #5d37ce;
        padding: 26px 24px;
        border-radius: 12px;
        width: 33%;
      }
    }
  }
}

.monsters {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: -110px;
    right: 50px;
    background: url(/game-jam/faq/dragon.svg) center center no-repeat;
    width: 120px;
    height: 120px;
    background-size: cover;
  }
  &:after {
    content: '';
    position: absolute;
    bottom: -110px;
    left: -20px;
    background: url(/game-jam/faq/pulpo.svg) center center no-repeat;
    width: 120px;
    height: 217px;
  }
}

@media only screen and (max-width: 768px) {
  .game-jam-why-participate-Wrapper {
    padding: 80px 20px 80px 20px;
    overflow: hidden;
    .game-jam-why-participate {
      &__list {
        flex-direction: column;
        align-items: center;

        &__item {
          width: 100%;
          text-align: center;
        }
      }
    }
  }

  .why-participate-text {
    max-width: inherit !important;
  }

  .monsters {
    &:before {
      display: none;
    }
    &:after {
      display: none;
    }
  }
}

.game-jam-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background: radial-gradient(66.89% 438.39% at 85.69% 92.86%, #5626f5 0%, #8400ec 100%);
  color: white;
  max-width: 367px;
  width: 100%;
  height: 56px;
  border-radius: 8px;
  cursor: pointer;
  text-transform: uppercase;

  &:hover {
    background: #ff43e0;
  }
}
