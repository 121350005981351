.container__children {
	display: flex;
	flex-direction: column;

	align-items: center;

	.notification-bar {
		width: 100%;
		max-width: 1248px;
		padding: 0 1.5rem 0 1.5rem;

		&__container {
			width: 100%;
			max-width: 1200px;
			background: #F96B61;
			height: 40px;
			margin: 0.5rem auto 0 auto;
			border-radius: 16px;
			padding: 8px 12px;
			position: relative;
			display: flex;
			justify-content: center;
			transition: all 0.3s ease;
		}

		&__close-icon {
			position: absolute;
			padding: 8px 12px;
			right: 0;
			top: 0;
			cursor: pointer;
		}
	}

	.childre_overview_Tile {
		width: 100%;
		height: inherit;
		max-width: 1248px;
		padding: 2rem 1.5rem 1.2rem 1.5rem;
		display: grid;
		grid-template-columns: minmax(273px, 1500px) 300px;
		column-gap: 1.5rem;
		row-gap: 25px;
		justify-content: center;
		align-items: start;

		.course_section {
			display: grid;
			grid-template-columns: 1fr;
			/* row-gap: 20px; */

			.kNBmiK {
				margin: 0 !important;
			}
		}

		.previous-path {
			margin-top: 2.75rem;
		}

		.kNBmiK {
			margin: 0 !important;
		}

		.kVtMtA, .bhRnqO {
			box-shadow: none;
			background: rgba(0, 0, 0, 0.08);
			opacity: 1;
		}

		.kVtMtA::before, .bhRnqO::before {
			border-top: 2px solid #fff;
			border-right: 2px solid #fff;
		}

		.eXIdQs {
			background: #8C30F2;
		}

		.qbpMB {
			background: #EFEAF6;
		}

		.recommended-courses-title {
			padding: 24px 0;
		}
		
		.recommended-courses {
			padding-top: 23px;
			display: flex;
			gap: 23px;
			max-width: 876px;
			overflow-x: scroll;
			padding: 0.25rem;

			.ui-card-recommended-course {
				width: 33.3%;

				&__container-image {
					width: 100%;

				}

				.ui-image {
					width: 100% !important;
				}

				.ui-image>div {
					width: 100%;
				}
			}
		}

		.recommended-courses::-webkit-scrollbar {
			background: #fff;
			border: 1px solid white;

		}

		.recommended-courses::-webkit-scrollbar-track {
			border: 1px solid white;

		}

		.recommended-courses::-webkit-scrollbar-thumb {
			background: #00000035;
			border-radius: 20px;
			border: 5px solid white;
		}
	}

	.children_overview_column {
		display: flex;
		flex-direction: column;
		gap: 1.5rem;
	}
}


@media only screen and (max-width: 1460px) {
	.container__children {
		.childre_overview_Tile {
			justify-content: space-between;
		}
	}
}

@media only screen and (max-width: 768px) {
	.container__children {
		.childre_overview_Tile {
			grid-template-columns: 1fr;
			padding: 2rem 0 1.2rem 0;
		}
	}
}

@media only screen and (max-width: 420px) {
	.container__children {
		.childre_overview_Tile {
			grid-template-columns: 1fr;
			padding: 2rem 0 1.2rem 0;
		}

		.children_overview_column {
			padding: 0 1.25rem;
		}
	}
}