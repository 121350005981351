.card-student-zone {
	width: 100%;
	min-height: 340px;
	border-radius: 1rem;
	background: radial-gradient(161.94% 170.37% at 50% 100%, #4AEAFF 0%, #798BFF 100%);
	box-shadow: var(--shadow-small);
	&__bg {
		padding: 0.5rem 0.5rem 0 0.5rem;
		width: 100%;
		height: auto;
		background-image: url("/avatars-profile/bg-profile-card.webp");
		background-repeat: no-repeat;
		border-radius: 1rem;
	}
	&__circle-icon {
		width: 4.625rem;
		height: 4.625rem;
		position: relative;
		top: -1.5rem;
		margin: 0  auto -1.3rem auto;
		border-radius: 50%;
		border: 5px solid rgba(255, 255, 255, 0.78);
		box-shadow: var(--shadow-small);
	}
	&__section-access {
		display: flex;
		background: rgba(255, 255, 255, 0.5);
		padding: 0.62rem 0.62rem;
		flex-direction: column;
    align-items: center;
		border-radius: 0.63rem;
		margin-top: 0.5rem;
		margin-bottom: -1rem;
		gap: 0.25rem;
		p {
			line-height: 20px;
		}
	}
}