.information_box {
  width: 100%;
  padding: 1.2rem;
  border-radius: 20px;
  margin-top: 1rem;
  p {
    margin: 0;
  }
}

/* Types of boxs */
.information_box--type_warning {
  background-color: var(--color-yellow-white);
}
