.jumbutronUpd-container {
  position: relative;
  /* margin-top: -5rem; */
  padding: 0 auto;
  justify-content: center;
  top: 0;
  left: 0;

  .jumbutronUpd-content {
    display: flex;
    position: relative;
    padding: 0 0.63rem 5rem 0.63rem;
    flex-direction: column;
    align-items: center;
    margin-top: 112px;
		
    .jumbutronUpd-first-section {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      .jumbutronUpd-content-vacation {
        display: flex;
        flex-direction: column;
        gap: 38px;
        margin-top: 24px;
        order: 7;
      }
      .online-chip {
        margin-bottom: 0.75rem;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 10.13rem;
        height: 1.56rem;
        border-radius: 0.63rem;
        background: #f0f0f0;
        order: 1;

        .icon-online {
          background: #ff2c2c;
          width: 0.42rem;
          height: 0.42rem;
          border-radius: 50%;
          margin-right: 0.42rem;
        }
        /* p {
					font-family: 'Roca One';
					font-style: normal;
					font-weight: bold;
					font-size: 0.69rem;
					line-height: 0.69rem;
				} */
      }
      .breadcrumbs {
        order: 2;
        margin-bottom: 0;
        .link {
          width: auto;
        }
        .body2 {
          text-decoration-line: underline;
        }
      }
      h3 {
        display: none;
        order: 3;
      }
      h1 {
        /* font-family: 'Roca One';
				font-style: normal;
				font-weight: bold;
				font-size: 2.9rem;
				line-height: 3.9rem; */
        /* margin: 2.5rem 0; */
        text-align: center;
        order: 4;
      }
      .jumbutronUpd-description {
        /* font-family: 'Roca One';
				font-style: normal;
				font-weight: normal;
				font-size: 1.25rem;
				line-height: 2.19rem; */
        text-align: center;
        /* margin-bottom: 1.56rem; */
        order: 5;
      }
      .jumbutronUpd-buttons-container {
        display: flex;
        gap: 0.63rem;
        max-width: 26.9rem;
        margin: 0.62rem 0 1.56rem 0;
        order: 6;
      }
      .jumbutronUpd-buttons-container-tech {
        display: flex;
        gap: 0.63rem;
        max-width: 26.9rem;
        margin: 0.62rem 0 1.56rem 0;
        order: 6;
        .jumbutronUpd-second-section {
          display: flex;
          justify-content: center;
        }
      }
    }
    .jumbutronUpd-first-section > .breadcrumbs > .link {
      width: auto !important;
    }
  }
}

@media (min-width: 480px) {
  .jumbutronUpd-container {
    .jumbutronUpd-content {
      .jumbutronUpd-first-section {
        .online-chip {
        }
        .jumbutronUpd-description {
        }
        .jumbutronUpd-buttons-container {
          display: flex;
          gap: 0.63rem;
          width: 100%;
          max-width: 34.9rem;
          margin: 0.62rem auto 1.56rem auto;
          order: 6;
        }
        .jumbutronUpd-buttons-container-tech {
          display: flex;
          gap: 0.63rem;
          width: 100%;
          max-width: 34.9rem;
          margin: 0.62rem auto 1.56rem auto;
          order: 6;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .jumbutronUpd-container {
    .jumbutronUpd-content {
      .jumbutronUpd-first-section {
        /* h3 {
					font-weight: 800;
					font-size: 1rem;
					line-height: 150%;
				} */
      }
      .jumbutronUpd-second-section {
      }
      .jumbutronUpd-second-section > img {
        width: 80%;
        margin: 0 auto;
      }
      .jumbutronUpd-second-section > div {
        width: 451px;
        height: 264px;
        border-radius: 20px;
        position: relative;
        display: flex;
        align-items: flex-end;
        bottom: -90px;
        margin-right: auto;
        margin-left: auto;
      }
      .jumbutronUpd-second-section > div img {
        width: 488px;
        height: 488px;
        position: relative;
        bottom: 0;
        left: -19px;
      }
    }
  }
}
@media (min-width: 1024px) {
  .jumbutronUpd-container {
    display: flex;
    margin-left: auto;
    margin-right: auto;

    .jumbutronUpd-content {
      max-width: 1200px;
      display: flex;
      position: relative;
      padding: 0 0.63rem 3.8rem 0.63rem;
      flex-direction: row;

      .jumbutronUpd-first-section {
        width: 55%;
        h1 {
          text-align: left;
          /* font-size: 55px; */
        }
        .jumbutronUpd-subtitle {
          order: 3;
          /* font-weight: bold;
					font-size: 20px; */
        }
        .jumbutronUpd-description {
          text-align: left;
        }
        .online-chip {
          order: 2;
        }
        .breadcrumbs {
          order: 1;
        }
        h3 {
          display: flex;
          justify-content: center;
        }
        .jumbutronUpd-buttons-container {
          display: flex;
          gap: 1.87rem;
          max-width: 26.9rem;
          margin: 0.62rem 0 1.56rem 0;
          order: 6;
        }
        .jumbutronUpd-buttons-container-tech {
          display: flex;
          gap: 1.87rem;
          max-width: 26.9rem;
          margin: 0.62rem 0 1.56rem 0;
          order: 6;
        }
      }
      .jumbutronUpd-second-section {
        width: 45%;
      }
      .jumbutronUpd-second-section > img {
        width: 100%;
      }
    }
  }
}
