.login-b2b-container {
  margin: auto;
  background: #63a3d2;
  background-image: url('/login-b2b-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  display: flex;

  & img {
    margin: 30px 0 0 30px;
  }

  & .login-form-b2b-container {
    margin: auto;
    flex-direction: column;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    & .login-form-b2b {
      background-color: white;
      border-radius: 16px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      padding: 20px 100px;
      max-width: 550px;
      max-height: 476px;
      width: 100%;

      & h2 {
        font-size: 28px;
        margin: 10px 0;
      }

      & > p {
        font-size: 21px;
      }

      & h2,
      & p {
        text-align: center;
      }

      & .login-form-b2b-inputs {
        margin-top: 30px;
      }

      & .login-form-b2b-inputs > p {
        text-align: center;
        font-size: 12px;
        color: #555555;
      }

      & .login-form-b2b-forgot-pass {
        margin: 20px 0 30px 0;
      }

      & .login-form-b2b-user-input,
      & .login-form-b2b-pass-input,
      & .login-form-b2b-btn {
        margin-bottom: 10px;
      }

      & input {
        width: 100%;
        padding: 8px;
        box-sizing: border-box;
        border: 2px solid #000;
        border-radius: 8px;
        margin-top: 10px;
      }

      & input:focus {
        outline-color: grey;
      }

      & .login-form-b2b-btn button {
        width: 100%;
        padding: 10px;
        background-color: #000;
        color: white;
        border-radius: 5px;
        cursor: pointer;
        margin: 15px 0;
      }

      & .login-form-b2b-btn button:focus {
        outline: none;
      }

      & .login-form-b2b-error {
        margin: 0px;
        text-align: left !important;
        padding: 0 15px 0 0;
        color: red !important;
      }
    }
  }
}

@media (max-width: 768px) {
  .login-b2b-container {
    background-image: url('/login-b2b-bg-m.png');

    & .login-form-b2b-container {
      & .login-form-b2b {
        padding: 20px 20px;
        width: 85%;

        & h2 {
          font-size: 20px;
        }

        & p {
          font-size: 14px;
        }

        & input:focus {
          outline-color: grey;
        }

        & .login-form-b2b-inputs > p {
          margin: 0 30px;
        }

        & .login-form-b2b-inputs {
          & .login-form-b2b-error {
            margin: 0px;
            text-align: left;
            padding: 0 30px 0 0;
          }
        }
      }
    }
  }
}
