.ui-header-card-course-progress {
	padding: 4px 16px 8px 16px;
	display: flex;
	gap: 16px;
	min-height: 95px;
	&__header {
		padding: 8px 16px;
		display: flex;
		gap: 17px;
	}
	&__header-principal {
		width: 100%;
	}
	&__header-title-status {
		display: flex;
		justify-content: space-between;	
	}
	&__header-title-status > p:first-child {
		margin-top: 14px;
	}
	&__header-start-date {
		display: flex;
		justify-content: space-between;	
		margin-top: 3px;
	}
}

@media only screen and (max-width: 420px) {
  .ui-header-card-course-progress {
		&__header-start-date {
			p:first-child {
				display: none;
			}
		}
	}
}