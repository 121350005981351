.contact_us_home-Wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url(../../../public/contact-us/contact-us-home.svg) var(--color-cadetBlue);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 100px 0 0 0;

  .contact_us_home {
    max-width: 1080px;
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 0 86px 0;

    .contact_text {
      color: white;
      width: 561px;
    }

    .contact_form-Card {
      width: 450px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: var(--color-white);
      box-shadow: 0px 16px 40px rgba(14, 86, 124, 0.2);
      border-radius: 20px;
      padding: 2rem 3rem;

      .contact_us_home-Form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        input,
        select {
          margin: 7.5px 0;
        }
        input:first-child {
          margin-top: 1.25rem;
        }
        textarea {
          margin: 0.5rem 0;
        }

        p {
          margin: 1rem 0 2rem 0;
          a {
            font-weight: 700;
          }
        }
        .containerClass {
          margin-top: 4rem;
        }
        .phone_input-Wrapper {
          width: 100%;
          margin: 0.5rem 0;
        }
        .form-control,
        .flag-dropdown {
          border: solid 2px red;
          border-color: #616161;
          color: #616161;
          border-radius: 10px;
          height: 3rem;
          transition: 0.4s;
        }
        .form-control {
          width: 100%;
          padding-left: 88px;
        }
        .selected-flag {
          padding: 0 0 0 24px;
          width: 70px;
        }
        .selected-flag:hover {
          background-color: transparent;
        }
        .button {
          margin: 0;
        }
      }
    }
  }

  .contact_faq {
    max-width: 1080px;

    .faq_tab_upd-Wrapper {
      background: white;
      border-radius: 16px;
      padding: 80px 40.5px;
      margin: 0;
    }
  }
}

@media only screen and (max-width: 768px) {
  .contact_us_home-Wrapper {
    padding: 48px 0 62px 0;

    .contact_us_home {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0 0 45px 0;

      .contact_text {
        max-width: 320px;
        color: white;
        margin: 0 0 32px 0;
      }

      .contact_form-Card {
        width: 100%;
        padding: 26px 17.68px 21px 15px;

        .contact_us_home-Form {
          input:first-child {
            margin-top: 17px;
          }

          .body {
            font-size: 14px;
            line-height: 21.96px;
            font-family: 'Roca One';
            margin: 4px 0 18px 0;
          }
        }
      }
    }

    .contact_faq {
      width: 90%;

      .faq_tab_upd-Wrapper {
        background: white;
        border-radius: 16px;
        padding: 40px 10px 25px 10px;
        margin: 0;

        h2 {
          text-align: center;
          font-size: 21px;
          font-weight: 700;
          line-height: 24px;
          margin: 0 0 10px 0;
        }

        .faq_list {
          .faq_card_upd-Wrapper {
            .accordion {
              padding: 12.5px 0 24px 0;

              h3 {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}
