.ui-card-certificate-blocked {
	position: absolute;
  top: calc(100% - 380px);
  left: calc(100% - 680px);
  margin: 16px;
  width: 495px;
  height: 304px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  padding: 1.5rem 4.75rem 4.48rem;
  display: flex;
  flex-direction: column;
  align-items: center;
	overflow: hidden;
  p:first-child {
    max-width: 446px !important;
    margin: 0 auto;
  }
  &__container-certificate {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 12px auto;
    margin-bottom: 32px;
  }
  &__container-btn {
    margin-top: 24px;
  }
	&__first-img-bg {
		position: absolute;
		left: -2rem;
		bottom: 2rem;
	}
	&__second-img-bg {
		position: absolute;
		right: -3rem;
		bottom: 1.13rem;
	}
  &__button-filled {
		display: flex;
		align-items: center;
		gap: 0.25rem;
		background: black;
		border-radius: 6px;
		width: 250px;
		text-align: center;
    margin-top: 32px;
		a {
			display: flex;
			align-items: center;
			gap: 0.25rem;
			justify-content: center;
			padding: 8px;
		}
	}
}

@media only screen and (max-width: 420px) {
	.ui-card-certificate-blocked {
		&__first-img-bg {
			display: none;
		}
		&__second-img-bg {
			display: none;
		}
	}
}