.testimony_card-Wrapper {
  min-width: 409px;
  align-items: center;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.06);
  border-radius: 16px;
  padding: 24px;
  border: 2px solid transparent;
  transition: 0.4s ease-in-out;
  height: 100%;

  .testimony_card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    &--header {
      color: #838383;
      margin: 0 0 16px 0;
    }

    &--footer {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &_information {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        &_image {
          margin: 0 7px 0 0;
          padding: 3px 0 0 0;

          &_img > svg {
            border-radius: 4px;
            width: 48px;
            height: 32px;
          }
        }
      }
    }
  }

  img,
  .lazy-load-image-background {
    width: 100px;
    margin: auto;
    height: 100px;
    border-radius: 100px;
    object-fit: cover;
    object-position: 50% 20%;
  }
}

@media only screen and (max-width: 768px) {
  .testimony_card-Wrapper {
    min-width: 310px;
    padding: 24px 24px 16px 24px;

    .testimony_card {
      &-header {
        margin: 0 0 24px 0;
      }
    }
  }
}
