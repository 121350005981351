.textarea {
  @apply outline-none px-6 p-4
    border-2 border-black block w-full
    resize-none;
  height: 200px;
}

.textarea-purchase-flow {
  @apply outline-none px-6 p-4
    border-2 border-black block w-full
    resize-none;
}

.textarea-contact-us {
  color: black;
  border: 2px solid var(--color-gray);
  border-radius: 6px;
  padding: 10px 16px;
  height: 200px;
  width: 100%;
}

.textarea-contact-home {
  border-color: #616161;
  color: #616161;
  border-radius: 10px;
  transition: 0.4s;
  resize: none;
  @apply outline-none px-6 p-4
    h-12 border-2  block w-full;
  height: 110px;
}

.textarea-material-design {
  border-bottom: solid 2px #e0e0e0;
  color: var(--color-black);
  font-weight: 700;
  transition: 0.4s;
  resize: none;
  @apply outline-none
    h-12 block w-full;
  height: 110px;
}
.textarea-material-design:focus {
  border-bottom: solid 2px;
}
.textarea-material-design::placeholder {
  color: var(--color-black);
  font-weight: 700;
}
