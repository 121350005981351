.button {
  transition: 0.4s;
  @apply h-12 bg-black
    text-white font-medium rounded-sm px-6
    flex justify-center items-center
    tracking-wider;
  padding-top: 2px;
  border-radius: 0.5rem;

  &:hover {
    background: rgba(0, 0, 0, 0.8);
		cursor: pointer;
  }
}

.button:disabled {
  background-color: black;
  opacity: 0.1;
  border-radius: 8px;
  color: white;
  cursor: auto;
  border: none;
}

/* C O L O R S */
.button--color-sky {
  font-weight: 700;
  @apply bg-sky text-black;

  &:disabled {
    @apply opacity-50;

    &:hover {
      @apply bg-opacity-100 opacity-50;
    }
  }
}

.button--color--coders {
  background-color: var(--color-little-coders);
  &:disabled {
    @apply opacity-50;
  }
}

.button--color-little-coders,
.button--color-mini-creador {
  background-color: var(--color-little-coders);
  font-weight: 700;
  &:disabled {
    @apply opacity-50;
  }
}
.button--color-desarrollo-de-videojuegos,
.button--color-videojuegos-tipo-retro {
  background-color: var(--color-desarrollador-de-videojuegos);
  font-weight: 700;
  &:disabled {
    @apply opacity-50;
  }
}
.button--color-disenio-y-creatividad,
.button--color-disenio-digital {
  background-color: var(--color-disenio-y-creatividad);
  font-weight: 700;
  &:disabled {
    @apply opacity-50;
  }
}
.button--color-super-programadores,
.button--color-minecraft-for-education {
  background-color: var(--color-super-programador);
  font-weight: 700;
  &:disabled {
    @apply opacity-50;
  }
}
.button--color-courses-witout-path {
  background-color: var(--color-cadetBlue);
  font-weight: 700;
  &:disabled {
    @apply opacity-50;
  }
}

/* V A R I A N T S */

.button--variant-radius {
  width: 100%;
  color: #ffffff;
  border: none;
  border-radius: 10px;
  padding: 0.5rem;
  margin-bottom: 0.6rem;
  p {
    font-size: 14px;
    text-align: left;
    margin: 0;
  }
  small {
    @apply mt-1 ml-2;
  }

  i {
    @apply ml-2;
  }

  &:hover {
    opacity: 1;
    transform: scale(1.04, 1.04);
  }
}

.button--variant-radius-white {
  width: 100%;
  background-color: #ffffff;
  color: #000000;
  border: none;
  border-radius: 10px;
  padding: 0.5rem;
  font-weight: 700;
  margin-bottom: 0.6rem;
  p {
    font-size: 14px;
    text-align: left;
    margin: 0;
  }
  small {
    @apply mt-1 ml-2;
  }

  i {
    @apply ml-2;
  }

  &:hover {
    opacity: 1;
    transform: scale(1.04, 1.04);
  }
}

.button--variant-subscribe {
  small {
    @apply mt-1 ml-2;
  }

  i {
    @apply ml-2;
  }

  &:hover {
    @apply bg-skyHover;

    i {
      transform: translateX(2px);
    }
  }
}

.button--variant-mvp {
  height: auto;
  width: 100%;
  padding: 0.5rem 0;
  margin-bottom: 0.6rem;
  p {
    font-size: 14px;
    text-align: left;
    margin: 0;
  }
  p:nth-child(1) {
    font-size: 16px;
  }
  small {
    @apply mt-1 ml-2;
  }

  i {
    @apply ml-2;
  }

  &:hover {
    @apply bg-skyHover;

    i {
      transform: translateX(2px);
    }
  }
}

.button--variant-outline-w-icon {
  @apply bg-transparent text-black font-bold;
  font-family: Montserrat, sans-serif;

  i {
    @apply ml-2 relative;
    top: -1px;
  }

  &:hover {
    @apply bg-black text-white;
  }
}

.button--variant-outline-w-icon-small {
  @apply bg-transparent text-black font-semibold text-sm;
  font-family: Montserrat, sans-serif;

  i {
    @apply ml-2 relative;
    top: -1px;
  }

  &:hover {
    @apply bg-black text-white;
  }
}

.button--variant-only-icon {
  @apply px-0
    bg-transparent border-transparent text-black font-bold shadow-none text-base;
  font-family: Montserrat, sans-serif;
  width: fit-content;

  i {
    @apply ml-2 relative;
    top: -1px;
  }

  &:hover {
    @apply bg-transparent;
  }
}

.button--variant-link {
  @apply px-0 outline-none inline-block underline
    bg-transparent border-transparent text-black font-normal shadow-none;
  width: fit-content;
  height: initial;

  i {
    @apply ml-2 relative;
    top: -1px;
  }

  &:focus {
    @apply outline-none;
  }

  &:hover {
    @apply bg-transparent text-opacity-50;
  }
}

.button--color-black {
  background-color: var(--color-black);
  color: var(--color-white);
  padding: 0.5rem 1rem;
  &:disabled {
    @apply opacity-50 text-black;
  }
}
