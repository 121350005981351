.start_your_future-Wrapper {
  display: flex;
  justify-content: center;
  position: relative;
  height: auto;
  padding: 64px 0 40px 0;
  background: #f8f8f8;
  border-radius: 32px;

  max-width: 1264px;
  width: 1264px;
  margin: 0 auto 120px auto;

  /* Container */
  .start_your_future {
    width: 100%;
    max-width: 1080px;

    /* Header */
    &--header {
      text-align: center;
      margin: 0 0 32px 0;
    }

    /* Main */
    &--main {
      width: 100%;
      margin: 0 0 72px 0;

      &_ages {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        &_list {
          display: flex;
          justify-content: center;
          align-items: center;

          margin: 14px 0 24px 0;

          &::-webkit-scrollbar {
            display: none;
          }

          &_item {
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 80px;
            min-height: 48px;
            border: 1px solid transparent;
            border-radius: 8px;
            background: rgba(255, 255, 255, 0.41);
            cursor: pointer;

            &:not(:last-child) {
              margin-right: 18px;
            }

            &--active {
              background-color: #1e1e1e;
              color: white;
            }
          }
        }
      }

      &_list {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(344px, 1fr));
        grid-auto-rows: 1fr;
        grid-auto-flow: dense;
        grid-column-gap: 24px;
        grid-row-gap: 42px;
        justify-items: center;
        justify-content: center;
        align-items: flex-start;
      }
    }

    /* Advice */
    &--advice {
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(255, 255, 255, 0.4);
      padding: 16px 0;
      border-radius: 16px;

      &_container {
        display: grid;
        grid-template-columns: 1fr auto;
        grid-template-rows: auto;
        grid-template-areas: 'advice contact';
        max-width: 100%;
        align-items: center;

        &_advice {
          grid-area: advice;
        }

        &_contact {
          grid-area: contact;
          margin: 0 0 0 36px;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .start_your_future-Wrapper {
    margin: 0 0 90px 0;
    padding: 40px 0 50px 0;
    border-radius: 0;
    width: 100%;

    /* Container */
    .start_your_future {
      width: 100%;

      /* Header */
      &--header {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin: 0 0 32px 0;
      }

      /* Main */
      &--main {
        width: 100%;
        margin: 0 0 32px 0;

        &_ages {
          &_list {
            width: 95%;
            overflow-x: scroll;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin: 14px -5% 24px 0;

            padding: 0 8px 0 0;
          }
        }

        &_list {
          width: 95%;

          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          overflow-x: scroll;
          max-height: 100%;
          min-height: 433px;
          height: 433px;

          margin: 0 -5% 0 5%;
          padding: 0 8px 0 0;

          &::-webkit-scrollbar {
            display: none;
          }
        }
      }

      /* Advice */
      &--advice {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 90%;
        margin: 0 auto;

        &_container {
          display: grid;
          grid-template-columns: 1fr;
          grid-template-rows: auto auto;
          grid-template-areas:
            'advice'
            'contact';
          max-width: 100%;
          width: 766px;
          align-items: center;

          &_advice {
            grid-area: advice;
            text-align: center;
            margin: 0 0 16px 0;
          }

          &_contact {
            grid-area: contact;
            text-align: center;
            margin: 0 auto;
          }
        }
      }
    }
  }
}

/* === Appear === */
.list-appear {
  opacity: 0;
}
.list-appear-active {
  opacity: 1;
  transition: opacity 1000ms;
}

/* === Enter === */
.list-enter {
  opacity: 0;
}

.list-enter-active {
  opacity: 1;
  transition: opacity 1000ms ease-in-out;
}

.list-enter-done {
  opacity: 1;
}

/* === Exit === */
.list-exit {
  opacity: 0;
}

.list-exit-active {
  opacity: 1;
  transition: opacity 1000ms ease-out;
}

.list-exit-done {
  opacity: 0;
}
