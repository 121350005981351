.confirm-payment-card {
  border-radius: 8px;
  margin-left: 5px;
  width: 600px;
  margin-bottom: 50px;
  & button:focus {
    outline: none;
  }
}

.confirm-payment-card-box-shadow {
  box-shadow: 0px 0px 5px rgb(0, 0, 0, 0.1);
}

.confirm-payment-card-header {
  background-color: #d9fffb;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px 10px 20px;
}

.confirm-payment-card-icon-left,
.confirm-payment-card-icon-right {
  margin-right: 10px;
}

.confirm-payment-card-body {
  background-color: transparent;
  padding: 5px 10px 15px 20px;
  font-size: 12px;
  line-height: 24px;
  p {
    margin-top: 10px;
  }
  & p:last-child {
    line-height: 18px;
    color: #808080;
  }
}

.confirm-payment-card-title-description-container {
  width: 100%;
  margin-left: 20px;
}

.confirm-payment-card-title-description {
  font-size: 16px;
  line-height: 24px;
  & p:last-child {
    font-size: 12px;
    line-height: 24px;
  }
}

@media only screen and (max-width: 768px) {
  .confirm-payment-card {
    width: 100%;
  }
}
