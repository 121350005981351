.login-perfiles {
	width: 100%;
	height: 100%;
	min-height: 100vh;
	background: linear-gradient(109.78deg, #6DF2F2 15.67%, #8E84FD 86.57%);
	display: flex;
	flex-direction: column;
	align-items: center;
	align-content: center;
	gap: 24px;
	&__container-logo {
		width: 100%;
		max-width: 1030px;
	}
	&__intro-title {
		margin-top: -10px;
		
	}
	&__intro-title-text {
		font-size: 38px;
		line-height: 48px;
		margin-bottom: 42px;
		&--bold {
			font-weight: bold;
		}
	}
	&__container-profile {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 48px;
	}
	&__container-item {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 24px;
		width: auto;
		
		min-height: 440px !important;
		padding: 24px;
		border-radius: 16px;
		background: rgba(255, 255, 255, 0.36);
		&--admin {
			max-width: 328px !important;
		}
		&--user {
			max-width: 680px;
			/* width: 680px; */
		}
		&--multiple {
			min-width: 680px;
		}
		&--one {
			min-width: 328px;
		}
	}
	&__container-title {
		min-width: 278px;
		width: 100%;
		background: rgba(255, 255, 255, 0.5);
		background-blend-mode: soft-light;
		border-radius: 8px;
		padding: 8px 8px;
	}
	&__text-title {
		text-align: center;
		font-size: 21px;
		line-height: 32px;
		&--bold {
			font-weight: bold;
		}
	}
	&__container-user-profile {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 8px;
		
	}	
	&__user-profile {
		display: flex;
		justify-content: center;
		gap: 72px;
		&--one {
			width: 260px;
			a{
				width: 260px;
			}
		}
		&--multiple {
			width: 632px;
			a{
				width: 260px;
			}
		}
	}
	&__profile-info {
		display: flex;
		flex-direction: column;
		align-items: center;
		height: 274px;
    justify-content: center;
		border-radius: 16px;
		gap: 10px;
	}
	&__profile-info:hover {
		background: rgba(255, 255, 255, 0.5);
		transition: all 0.1s ease;
		cursor: pointer;
		padding: 0 24px;
		gap: 10px;
		width: 260px;
	}
	&__profile-image {
		width: 160px;
		height: 160px;
		outline: 4.5px solid rgba(255, 255, 255, 0.5);
		background: rgb(195, 156, 226);
		border-radius: 50%;
		margin-bottom: 16px;
		position: relative;
	}
	&__container-notification {
		position: absolute;
		top: 0;
    right: 0;
		width: 2rem;
		height: 2rem;
		border-radius: 50%;
		background: #F74949;
		display: flex;
		align-items: center;
    justify-content: center;
	}
	&__notification-text {
		color: #FFFFFF;
		font-size: 21px;
		line-height: 32px;
	}

	&__container-lock {
		position: absolute;
		bottom: 0;
    right: 0;
		width: 2rem;
		height: 2rem;
		border-radius: 50%;
		background: #F1F1F1;
		display: flex;
		align-items: center;
    justify-content: center;
	}
	&__profile-name {
		font-size: 28px;
		line-height: 32px;
		text-align: center;
		&--bold {
			font-weight: bold;
		}
	}
	&__profile-access {
		font-size: 16px;
		line-height: 24px;
	}
	&__profile-note {
		margin-top: 10px;
		&--multiple {
			max-width: 380px !important;
		}
		&--one {
			max-width: 280px !important;
		}
	}
	&__text-note {
		text-align: center;
		font-size: 14px;
		line-height: 21px;
		&--dashboard {
			max-width: 227px;
		}
		&--bold {
			font-weight: bold;
		}
	}
	&__redirect-home {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		align-content: center;
		margin: 0 0 132px 0;
		a {
			text-align: center;
			text-decoration-line: underline;
		}
	}
}

@media (max-width : 1060px){
	.login-perfiles {
		&__container-logo {
			width: 100%;
			max-width: 680px;
		}
		.login-perfiles__container-profile {
			flex-direction: column;
	}
	}
}

@media (max-width : 768px){
	.login-perfiles {
		padding: 0 44px;
		&__container-logo {
			width: 100%;
			max-width: 680px;
		}
		&__intro-title-text {
			font-size: 28px;
			line-height: 32px;
			margin-bottom: 27px;
		}
		&__container-profile {
			flex-direction: column;
		}
		&__container-item {
			width: 100%;
			min-width: 100%;
			
			min-height: auto !important;
			padding: 16px;
			&--one {
				max-width: 288px !important;
			}
		}
		&__container-title {
			min-width: 216px;
		}
		&__text-title {
		}
		&__container-user-profile {
			width: 100%;
		}	
		&__user-profile {
			flex-direction: row;
			gap: 0;
			&--one {
				width: 100%;
				
			}
			&--multiple {
				width: 100%;
				a{
					width: 100%;
				}
			}
		}
		&__profile-info {
			height: 172px;
		}
		&__profile-info:hover {
			background: transparent;
		}
		&__profile-image {
			width: 96.5px;
			height: 96.5px;
		}
		&__container-notification {
			width: 1.2rem;
			height: 1.2rem;
			top: 0;
			right: 0;
		}
		&__notification-text {
			font-size: 14px;
 			line-height: 21px;
		}
	
		&__container-lock {
		}
		&__profile-name {
			font-size: 21px;
			line-height: 32px;
		}
		&__profile-access {
		}
		&__profile-note {
			display: none;
			width: 100%;
		}
		&__text-note {
			margin: 0 auto;
		}
		&__redirect-home {
			margin: 41px 0 42px 0;
		}
	}
	
}

@media (max-width : 480px){
	.login-perfiles {
		&__container-logo {
			width: 100%;
			max-width: 100%;
			margin-left: -28px;
			margin-top: 16px;
		}
		&__user-profile {
			flex-direction: column;
			gap: 47px;
			&--one {
				width: 100%;
			}
			&--multiple {
				width: 100%;
				a{
					width: 100%;
				}
			}
		}
	}
}