/* BUTTON */
.button-component {
  width: 100%;
  height: 3.13rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.63rem 20px;
  font-family: 'Roca One';
  font-style: normal;
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.69rem;
  text-align: center;
  transition: 0.4s;
  cursor: pointer;
  .icon-right {
    order: 1;
  }
  .icon-left {
    margin-right: 0.63rem;
    order: -1;
  }
  a {
    display: flex;
    justify-content: center;
  }
  &--hover-5_to_7 {
    &:hover {
      /* box-shadow: 0px 16px 40px #990f8720; */
      box-shadow: 0px 16px 40px rgba(153, 15, 135, 0.2);
      border: 2px solid #990f87 !important;
    }
  }
  &--hover-8_plus {
    &:hover {
      /* box-shadow: 0px 16px 40px #25936920; */
      box-shadow: 0px 16px 40px rgba(37, 147, 105, 0.2);
      border: 2px solid #259369 !important;
    }
  }
  &--hover-12_plus {
    &:hover {
      /* box-shadow: 0px 16px 40px #2772AB20; */
      box-shadow: 0px 16px 40px rgba(39, 113, 170, 0.2);
      border: 2px solid #2772ab !important;
    }
  }
}
.black-button:hover {
  background: rgba(0, 0, 0, 0.6) !important;
  border: 2px solid rgba(0, 0, 0, 0.06) !important;
}
.outlined-black-button:hover {
  background: rgba(0, 0, 0, 0.1) !important;
  /* border: 2px solid rgba(0, 0, 0, 0.06) !important; */
}

/* BUTTON */
.button-component-effect {
  width: 100%;
  height: 3.13rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.63rem 20px;
  font-family: 'Roca One';
  font-style: normal;
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.69rem;
  text-align: center;
  transition: all 0.25s ease;
  .icon-right {
    order: 1;
    margin-left: 0.63rem;
  }
  .icon-left {
    margin-right: 0.63rem;
    order: -1;
  }
  a {
    display: flex;
    justify-content: center;
  }
  &--outlined {
    background: transparent;
    border: 2px solid #000000;
    path {
      transition: all 0.25s ease;
      stroke: #000000 !important;
    }
    &:hover {
      transition: all 0.25s ease;
      background: #000000;
      border: 2px solid #000000;
      color: #ffffff;
    }
    &:hover path {
      transition: all 0.25s ease;
      stroke: #ffffff !important;
    }
    &:hover .fillColor path {
      transition: all 0.25s ease;
      stroke: #ffffff !important;
      fill: #ffffff !important;
    }
  }
  &--outlined-color-5_to_7 {
    border: 2px solid #990f87;
    color: #990f87;
    &:hover {
      transition: all 0.25s ease;
      border: 2px solid #990f87;
      background: #980f86;
      color: #ffffff;
    }
    &:hover path {
      transition: all 0.25s ease;
      stroke: #ffffff;
    }
  }
  &--outlined-color-8_plus {
    border: 2px solid #259369;
    color: #259369;
    &:hover {
      transition: all 0.25s ease;
      border: 2px solid #259369;
      background: #259369;
      color: #fff;
    }
    &:hover path {
      transition: all 0.25s ease;
      stroke: #ffffff !important;
    }
  }
  &--outlined-color-12_plus {
    border: 2px solid #2772ab;
    color: #2772ab;
    &:hover {
      transition: all 0.25s ease;
      border: 2px solid #2772ab;
      background: #2772ab;
      color: #fff;
    }
    &:hover path {
      transition: all 0.25s ease;
      stroke: #ffffff;
    }
  }
  &--contained {
    background: #000000;
    border: 2px solid #000000;
    color: #ffffff;
    &:hover {
      transition: all 0.25s ease;
      background: transparent;
      border: 2px solid #000000;
      color: #000000;
    }
  }
  &--contained-color-5_to_7 {
    background: #990f87;
    border: 2px solid #990f87;
    color: #ffffff;
    path {
      transition: all 0.25s ease;
      stroke: #ffffff !important;
    }
    &:hover {
      transition: all 0.25s ease;
      border: 2px solid #ffe3fe;
      background: #ffe3fe;
      color: #990f87;
    }
    &:hover path {
      transition: all 0.25s ease;
      stroke: #990f87 !important;
    }
  }
  &--contained-color-8_plus {
    background: #259369;
    border: 2px solid #259369;
    color: #ffffff;
    path {
      transition: all 0.25s ease;
      stroke: #ffffff !important;
    }
    &:hover {
      transition: all 0.25s ease;
      border: 2px solid #d0ffed;
      background: #d0ffed;
      color: #259369;
    }
    &:hover path {
      transition: all 0.25s ease;
      stroke: #259369 !important;
    }
  }
  &--contained-color-12_plus {
    background: #2772ab;
    border: 2px solid #2772ab;
    color: #ffffff;
    path {
      transition: all 0.25s ease;
      stroke: #ffffff !important;
    }
    &:hover {
      transition: all 0.25s ease;
      border: 2px solid #cfe9fd;
      background: #cfe9fd;
      color: #2772ab;
    }
    &:hover path {
      transition: all 0.25s ease;
      stroke: #2772ab !important;
    }
  }
  &--contained-color-alternative-5_to_7 {
    background: #f0f0f0;
    color: #000000;
    &:hover {
      transition: all 0.25s ease;
      background: #ffe3fe;
      color: #990f87;
    }
  }
  &--contained-color-alternative-8_plus {
    background: #f0f0f0;
    color: #000000;
    &:hover {
      transition: all 0.25s ease;
      background: #d0ffed;
      color: #259369;
    }
  }
  &--contained-color-alternative-12_plus {
    background: #f0f0f0;
    color: #000000;
    &:hover {
      transition: all 0.25s ease;
      background: #cfe9fd;
      color: #2772ab;
    }
  }
}
