.course-hero {
  @apply w-screen relative;
  left: -1.5rem;

  @screen lg {
    @apply w-full left-0;
  }
}

.course-hero__image {
  @apply mb-6 relative flex items-center justify-center bg-black overflow-hidden;
  z-index: 1;
  min-height: 300px;
  max-height: 350px;

  @screen lg {
    margin-bottom: -4rem;
    min-height: 408px;
  }
}

.course-hero__title {
  @apply px-6 mb-2;

  & > p {
    @apply mb-3 mt-4;
  }

  @screen lg {
    @apply bg-sky px-10 pr-12 py-4 relative mb-6 shadow-md;
    z-index: 2;
    width: fit-content;
    max-width: 650px;

    h1 {
      line-height: 1.3;
    }

    h2 {
      line-height: 1.3;
    }

    h4 {
      @apply mb-0;
    }

    .breadcrumbs {
      @apply hidden;
    }
  }
}

.course-hero__subtitle {
  @apply px-6;

  @screen lg {
    @apply px-10;
  }

  .no-margin-bottom {
    margin-bottom: 0px;
  }

}
