.course-tile {
  @apply cursor-pointer;

  picture {
    @apply mb-4;
  }

  h5 {
    @apply mb-1;
  }
}

.course-tile__description {
  min-height: 63px;
}

.course-tile__footer {
  @apply flex mt-3;

  p {
    @apply flex items-center;

    i {
      @apply mr-1;
    }

    span {
      padding-top: 6px;
    }
  }

  & > *:first-child {
    @apply mr-3 relative;

    &::after {
      @apply absolute bg-grey;
      width: 2px;
      height: 18px;
      right: -12px;
      content: ''
    }
  }

  & > *:last-child {
    @apply ml-3;
  }
}
