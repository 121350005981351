.any_question_card-Wrapper {
  width: 100%;
  height: 365px;
  box-shadow: 0px 21.5483px 43.1083px rgba(14, 86, 124, 0.16);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 0.4s;
  padding: 2rem 0;

  h3 {
    font-size: calc(18px + (20 - 18) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 700;
    text-align: center;
    width: 85%;
    color: #a8afb3;
    margin: 1rem 0 2rem 0;
  }
}

.any_question_card-Wrapper:hover {
  transform: scale(1.02, 1.02);
  box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.5);
}

@media only screen and (max-width: 768px) {
  .any_question_card-Wrapper {
    scroll-snap-align: start;
    flex-shrink: 0;
    width: 250px;
    position: relative;
  }
}
