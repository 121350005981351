@tailwind base;
@tailwind components;
@tailwind utilities;

@import './fonts/fonts.css';
@import './layout/index.css';
@import './atoms/index.css';
@import './helpers/index.css';
@import './styleguide/atoms/index.css';
@import './styleguide/molecules/index.css';
@import './styleguide/organisms/index.css';
@import './components/index.css';
@import './pages/index.css';
@import './refactor.css';

:root {
  /* Colors */
  --color-black: #000000;
  --color-white: #ffffff;
  --color-cadetBlue: #29d7fd;
  --color-violet: #6552ff;
  --color-violet2: #6058f6;
  --color-pink: #fdb2f7;
  --color-gray: #aab7c7;
  --color-gray-dark: #616161;
  --color-gray-white: #f0f0f0;
  --color-marmol-white: #f8f9fb;
  --color-error: #da1414;
  --color-little-coders: #f6c546;
  --color-desarrollador-de-videojuegos: #f09af9;
  --color-disenio-y-creatividad: #b0fec0;
  --color-super-programador: #02aed9;
  --color-yellow-white: #fff4d3;
  --color-blue: #017ad5;
  --color-gray: #b2b2b2;
  /* Tools */
  --padding_layout-sides: 3rem;
  /* transition hover */
  --transition: all 0.3s ease;
  /* Shadow */
  --shadow-large: 0px 2px 15px rgba(0, 0, 0, 0.1), 0px 15px 40px rgba(0, 0, 0, 0.08);
  --shadow-medium: 0px 2px 10px rgba(0, 0, 0, 0.1), 0px 5px 16px rgba(0, 0, 0, 0.08);
  --shadow-small: 0px 1px 3px rgba(0, 0, 0, 0.15), 0px 1px 13px rgba(0, 0, 0, 0.05);

  --shadow-color_5-7: 0px 2px 15px rgba(235, 34, 209, 0.5),
    0px 15px 40px rgba(235, 34, 209, 0.25);
  --shadow-color_8-11: 0px 2px 15px rgba(41, 160, 67, 0.5),
    0px 15px 40px rgba(41, 160, 67, 0.25);
  --shadow-color_12: 0px 2px 15px rgba(0, 121, 214, 0.5),
    0px 15px 40px rgba(0, 121, 214, 0.25);
  --color_5-7: #eb22d1;
  --color_8-11: #29a043;
  --color_12: #0079d6;
}

html,
body {
  font-family: NT Bau;
}

.display_flex {
  display: flex;
}

.d-none {
  display: none;
}

.rockone {
  font-family: Roca One;
}

.ntbau {
  font-family: NT Bau;
}

.sofiapro {
  font-family: Sofia Pro;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Roca One;
}
