.game-jam-store-Wrapper {
  background: #010a2a;
  padding: 24px 20px;
  border-radius: 12px;
  height: 100%;

  .game-jam-store {
    /* List */
    &__list {
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin: 0 0 24px 0;

      &__item {
        display: grid;
        grid-template-columns: auto auto auto auto;
        grid-template-areas: 'radio days hour start';
        justify-content: space-between;
        align-items: center;
        border-radius: 8px;
        border: 1px solid #c8bfe3;
        padding: 10px 16px 8px 20px;
        gap: 10px;
        cursor: pointer;

        &--active {
          border: 1px solid #5d37ce;
        }

        &_radio {
          grid-area: radio;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          border: 2px solid white;

          &--active {
            border: 2px solid #5d37ce;
          }

          &_btn {
            background-color: transparent;
            width: 8px;
            height: 8px;
            border-radius: 50%;

            &--active {
              background: #5d37ce;
            }
          }
        }

        &_days {
          grid-area: days;
          display: flex;
          gap: 5px;
        }

        &_hour {
          grid-area: hour;
        }

        &_start {
          grid-area: start;
        }
      }
    }

    /* Price */
    &__price {
      display: flex;
      justify-content: center;
      gap: 10px;
      margin: 0 0 24px 0;
    }

    /* Button */
    &__button {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #422a9f;

      padding: 18px 40px 14px;
      border-radius: 8px;

      &--active {
        background: radial-gradient(
          66.89% 438.39% at 85.69% 92.86%,
          rgb(86, 38, 245, 1) 0%,
          rgb(132, 0, 236, 1) 100%
        );
        cursor: pointer;

        &:hover {
          background: #ff43e0;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .game-jam-store-Wrapper {
    .game-jam-store {
      /* List */
      &__list {
        &__item {
          display: grid;
          grid-template-columns: auto auto auto auto;
          grid-template-areas:
            'radio days days'
            'radio hour start';
          gap: 2px;
        }
      }

      /* Button */
      &__button {
        padding: 18px 20px 14px;
      }
    }
  }
}
