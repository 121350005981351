.ui-tab-with-line {
	display: flex;
	align-items: flex-end;
	cursor: pointer;
	min-height: 60px;
	&:first-child {
		padding-left: 24px;
	}
	&__center {
		min-width: 158px;
		width: 100%;
		border-top-left-radius: 32px;
		border-top-right-radius: 32px;
		display: flex;
		align-items: center;
		padding: 10px 24px 2px 24px;
		p {
			text-transform: capitalize;
		}
		&--inactive {
			border-bottom: 4px solid #ffffff;
			p {
				color: #808080 !important;
			}
		}
		&--active {
			border-bottom: 4px solid #000000;
		}
		p {
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: pre;	
		}
	}
}
