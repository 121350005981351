.game-jam-requirements-Wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 190px 0 100px 0;

  .game-jam-requirements {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 40px;

    max-width: 812px;
    width: 100%;

    &__list {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 24px;

      &__item {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: flex-start;
        gap: 24px;

        background: #101445;
        border-radius: 12px;
        padding: 24px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .game-jam-requirements-Wrapper {
    padding: 80px 20px 80px 20px;
    overflow: hidden;

    .game-jam-requirements {
      &__list {
        grid-template-columns: 1fr;

        &__item {
          flex-direction: row;
          align-items: flex-start;
          padding: 16px 16px 16px 24px;
        }
      }
    }
  }
}
