.frecuency_ask-Container {
  background: #ffffff;
  box-shadow: -5px 5px 30px rgba(14, 86, 124, 0.05);
  border-radius: 20px;
  padding: 2.5rem;

  h2 {
    font-size: calc(18px + (21 - 18) * ((100vw - 300px) / (1600 - 300)));
    margin-bottom: 2.5rem;
  }

  .ask_item {
    margin-bottom: 1.9rem;
    li {
      margin-left: 0.5rem;
      font-weight: 700;
      font-size: calc(14px + (17 - 14) * ((100vw - 300px) / (1600 - 300)));
      margin-bottom: 1.3rem;
    }
    p {
      font-weight: 300;
      font-size: calc(14px + (17 - 14) * ((100vw - 300px) / (1600 - 300)));
      line-height: 25px;
      color: #616161;
    }
  }
  .ask_item:last-child {
    margin-bottom: 0;
  }
}
