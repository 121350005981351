.card-program-vacation {
	width: 1080px;
	display: flex;
	flex-direction: column;
	padding: 0;
	padding-top: 60px;
	&__first-section {
		width: 100%;
		/* height: 184px; */
		padding: 32px 35px 32px 68px;
		border-top-right-radius: 20px;
		border-top-left-radius: 20px; 
		display: flex;
		gap: 56px;
		position: relative;
		justify-content: space-between;
		align-items: center;
	}
	&__header-detail {
		width: 100%;
		max-width: 676px;
	}
	&__header-image {
		width: 276px;
		min-width: 276px;
		height: 213px;
		position: relative;
		/* top: -32px; */
		float: right;
		margin-top: -7px;
    /* margin-bottom: -32px; */
		display: flex;
    	align-items: flex-end;
		justify-content: flex-end;
	}
	&__header-image img {
		bottom: 0px !important;
		width: 276px;
		height: 243px;
		/* height: auto !important;
		top: auto !important; */
	}
	&__tooltip {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 164px;
		height: 28px;
		border-radius: 0.45rem;
		margin-bottom: 1rem;
		background: #F1F1F1
	}
	&__tooltip-online {
		background: #FF2C2C;
		width: 0.5rem;
		height: 0.5rem;
		border-radius: 50%;
		margin-right: 0.42rem;
	}
	&__tooltip-text {
		font-family: 'Roca One';
		font-style: normal;
		font-size: 14px;
		line-height: 14px;
		&--bold {
			font-weight: bold;
		}
	}
	&__br {
		display: none;
	}
	&__title {
		margin-bottom: 20px;
		font-size: 37px;
	}
	&__subtitle {
		margin-bottom: 24px;
	}
	&__section-button {
		width: 100%;
		display: flex;
		gap: 8px;
		align-items: center;
	}
	&__second-section {
		width: 100%;
		height: 208px;
		border-bottom-right-radius: 20px;
		border-bottom-left-radius: 20px; 
		padding: 40px 60px 40px 40px;
		display: flex;
		flex-direction: row;
		gap: 86px;
	}
	&__description {
		width: 608px;
		margin: auto 0;
	}
	&__description-text {
		font-size: 18px;
		line-height: 150%;
	}
	&__details {
		width: 240px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
	&__details-text {
		font-size: 18px;
		line-height: 150%;
		display: flex;
		flex-direction: row;
		align-items: center;
    gap: 8px;
		&--bold {
			font-weight: bold;
		}
	}
	&__section-programs {
		display: flex;
		gap: 16px;
		margin-top: 40px;
	}
}

@media (max-width : 1100px){
	.card-program-vacation {

		&__section-programs {
			flex-direction: column;
			align-items: center;
		}
		
	}
}

@media (max-width : 1040px){
	.card-program-vacation {
		width: 100%;
		padding-bottom: 0;
		padding-top: 80px;
		max-width: 1020px;
		&__first-section {
			width: 100%;
			height: auto;
			padding: 32px 16px 0 16px;
		}
		&__header-image {
			width: calc(100% - 100px);
			/* position: relative;
			top: 0; */
			/* margin: 0 auto; */
		}
		&__br {
			display: none;
		}
		&__title {
			font-size: 36px;
			letter-spacing: -1.5px;
			&--bold {
				font-weight: bold;
			}
		}
		&__subtitle {
			font-size: 20px;	
		}
		&__second-section {
			width: 100%;
			height: auto; 
			flex-direction: column;
			padding: 21px 32px 34px 32px;
			gap: 24px;
		}
		&__description {
			width: 100%;
			
		}
		&__details {
			gap: 23px;
			margin: 0 auto;
		}
		&__section-programs {
			display: flex;
			flex-direction: row;
		}
	}
}

@media (max-width : 860px) {
	.card-program-vacation {
		
		&__section-programs {
			flex-direction: column !important;
			gap: 40px;
		}
	}
}
@media (max-width : 760px) {
	.card-program-vacation {
		&__first-section {
			flex-direction: column;
	}
		/* &__img {
			width: calc(100% - 100px);
		} */
		&__section-button {
			align-items: flex-start;
			flex-direction: column;
			margin-bottom: 38px;
		}
		&__header-image {
			width: auto;
			margin: 0 auto;
		}
		&__header-image img {
			width: 329px;
			height: 290px;
		}
	}
}
@media (max-width : 480px) {
	.card-program-vacation {
		&__br {
			display: none !important;
		}
		&__header-image img {
			width: 276px;
			height: 243px;
		}
	}
}




