.image {
  @apply block;

  img {
    @apply w-full;
  }
}

.image--course-tile {
  @apply overflow-hidden shadow-lg;
  max-height: 200px;
}

.image--teacher-avatar {
  @apply overflow-hidden rounded-full shadow-md;
  height: 130px;
  width: 130px;

  @screen lg {
    height: 200px;
    width: 200px;
  }
}

.image--my-course-tile {
  @apply overflow-hidden shadow-lg;
  max-height: 143px;

  @screen xl {
    max-height: 100px;
    max-width: 255px;
  }
}
