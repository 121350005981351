.h1 {
  @apply font-bold text-3xl relative leading-tight;
  font-family: Montserrat, sans-serif;
  left: -4px;

  @screen md {
    @apply mb-2 text-5xl;
  }
}

.special-h1 {
  @apply text-2xl font-bold leading-tight tracking-tighter;
  font-family: Montserrat, sans-serif;

  @screen md {
    @apply text-4xl;
  }
}

.h2 {
  @apply font-bold text-2xl mb-4;
  font-family: Montserrat, sans-serif;

  @screen md {
    @apply text-3xl mb-6;
  }

  &-small {
    @apply font-bold mb-4;
    font-family: Montserrat, sans-serif;
    font-size: 28px !important;

    @screen md {
      @apply mb-6;
      font-size: 28px !important;
    }
  }
}

.h3 {
  @apply font-bold text-xl mb-2;

  @screen md {
    @apply text-2xl;
  }

  &-small {
    @apply font-bold  mb-2;
    font-size: 21px;

    @screen md {
      font-size: 21px;
    }
  }
}

.h4 {
  @apply font-normal;
  font-size: 20px;

  @screen md {
    /* @apply mb-8; */
    font-size: 20px;
    line-height: 1.4;
  }

  &-small {
    @apply text-lg font-normal;

    @screen md {
      /* @apply mb-8; */
      font-size: 18px;
      line-height: 1.4;
    }
  }
}

.h5 {
  @apply font-bold text-lg uppercase;
}

.h6 {
  @apply font-bold text-sm uppercase mb-5;
  letter-spacing: 2px;
}

.body {
  @apply font-normal text-base mb-2;
}

.body2 {
  @apply font-normal text-sm mb-2;
}

.jumbo {
  @apply font-bold;
  font-family: Montserrat, sans-serif;
  font-size: 100px;
  line-height: 1;

  @screen md {
    font-size: 100px;
  }
}

.small {
  @apply text-xs font-medium;
}

/* Regulars */
.regular {
  font-family: 'Roca One';
  @apply font-light;

  /* 67px -> 51px */
  &-1 {
    @screen md {
      font-size: 67px;
      line-height: 100.5px;
    }

    font-size: 51px;
    line-height: 72px;
  }

  /* 51px -> 38px */
  &-2 {
    @screen md {
      font-size: 51px;
      line-height: 72px;
    }

    font-size: 38px;
    line-height: 56px;
  }

  /* 38px -> 28px */
  &-3 {
    @screen md {
      font-size: 38px;
      line-height: 56px;
    }

    font-size: 28px;
    line-height: 32px;
  }

  /* 28px -> 21px */
  &-4 {
    @screen md {
      font-size: 28px;
      line-height: 32px;
    }

    font-size: 21px;
    line-height: 32px;
  }

  /* 21px -> 16px */
  &-5 {
    @screen md {
      font-size: 21px;
      line-height: 32px;
    }

    font-size: 16px;
    line-height: 24px;
  }

  /* 16px -> 14px */
  &-6 {
    @screen md {
      font-size: 16px;
      line-height: 24px;
    }

    font-size: 14px;
    line-height: 21px;
  }

  /* 14px -> 12px */
  &-7 {
    @screen md {
      font-size: 14px;
      line-height: 21px;
    }

    font-size: 12px;
    line-height: 18px;
  }

  /* 12px */
  &-8 {
    font-size: 12px;
    line-height: 18px;
  }
}

.bold {
  font-family: 'Roca One';
  @apply font-bold;

  /* 67px -> 51px */
  &-1 {
    font-weight: inherit;

    @screen md {
      font-size: 67px;
      line-height: 80px;
    }

    font-size: 51px;
    line-height: 60px;
  }

  /* 51px -> 38px */
  &-2 {
    @screen md {
      font-size: 51px;
      line-height: 60px;
    }

    font-size: 38px;
    line-height: 44px;
  }

  /* 38px -> 28px */
  &-3 {
    @screen md {
      font-size: 38px;
      line-height: 44px;
    }

    font-size: 28px;
    line-height: 32px;
  }

  /* 28px -> 21px */
  &-4 {
    @screen md {
      font-size: 28px;
      line-height: 32px;
    }

    font-size: 21px;
    line-height: 24px;
  }
}

/* Weights */
.weight-medium {
  @apply font-medium;
}

.weight-semibold {
  @apply font-semibold;
}

.weight-bold {
  @apply font-bold;
}

/* Colors */
.color-grey {
  @apply text-grey;
}

.color-white {
  @apply text-white;
}

.color-red {
  @apply text-red-error;
}

.color-green {
  @apply text-green;
}
