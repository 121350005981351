.ui-number-course {
	width: 100px;
	min-width: 100px;
	height: 73px; /* height: 85px; */
	display: flex;
	flex-direction: column;
	align-items: center;
	font-family: 'Roca One';
	font-style: normal;
	font-weight: 700;
	color: #000000;
	&__description {
		font-size: 18px;
		line-height: 32px;
		letter-spacing: 0.23em;
		margin-left: 8px;
		position: relative;
	}
	&__number {
		font-size: 67px;
		line-height: 57px;
		letter-spacing: -0.05em;
		position: relative;
		top: -5px;
	}
}