.admin-pages-body {
  margin: 0.8rem 1rem;
}
.admin-pages-body > p > p {
  padding: 0.3rem 0;
}

.admin-pages-body > p > ul {
  padding: 0.3rem 0;
  list-style: disc outside none;
  margin-left: 1rem;
}
