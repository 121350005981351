.banner_dasboard {
  background-color: var(--color-white);
  width: 300px;
  height: 400px;

  img {
    width: 300px;
    height: auto;
  }
}

@media only screen and (max-width: 768px) {
  .banner_dasboard {
    width: auto;
    height: auto;

    img {
      width: auto;
      height: auto;
    }
  }
}
