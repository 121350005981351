.need_support_card {
  width: 198px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 0.8rem;
  padding: 1.3rem 0;
  filter: drop-shadow(0px 16px 40px rgba(14, 86, 124, 0.2));
  background-color: var(--color-black);
  border-radius: 20px;

  .need_support-Title {
    color: #ffffff;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    text-align: center;
    margin: 1.3rem 0;
  }

  button {
    width: 90%;
    height: auto;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 768px) {
  .need_support_card {
    background-color: transparent;

    .need_support-Title {
      color: #000000;
    }
  }
}
