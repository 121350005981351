.ui-card-certificate-content {
	position: relative;
  margin: 16px;
  width: calc(100% - 32px);
  background: #ffffff;
  border-radius: 8px;
  padding: 1.5rem 4.75rem 4.48rem;
  display: flex;
  flex-direction: column;
  align-items: center;
	overflow: hidden;
  p:first-child {
    max-width: 446px !important;
    margin: 0 auto;
  }
  &__container-certificate {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 12px auto;
  }
  &__container-btn {
    margin-top: 24px;
  }
	&__first-img-bg {
		position: absolute;
		left: -2rem;
		bottom: 2rem;
	}
	&__second-img-bg {
		position: absolute;
		right: -3rem;
		bottom: 1.13rem;
	}
}

@media only screen and (max-width: 420px) {
	.ui-card-certificate-content {
		&__first-img-bg {
			display: none;
		}
		&__second-img-bg {
			display: none;
		}
	}
}