.inscription-head {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.inscription-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > * {
    @apply mb-6;
  }
  .__row {
    width: 100%;
    max-width: 50%;
    div {
      @apply flex;

      & > * {
        @apply mr-4;
        flex: 1;
      }

      & > *:nth-child(2) {
        flex: 2;
      }

      & > *:last-child {
        @apply mr-0;
      }
    }
  }
}

.book-claim-subcontent {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 1.5rem;
}
.book-claim-subcontent > h4 {
  margin-bottom: 0;
}
.book-claim-subcontent > h4 > span {
  font-weight: 700;
}

@media only screen and (max-width: 768px) {
  .inscription-form {
    .__row {
      width: 100%;
      max-width: 60%;
    }
  }
}

@media only screen and (max-width: 425px) {
  .inscription-form {
    .__row {
      width: 100%;
      max-width: 80%;
    }
  }
}
@media only screen and (max-width: 320px) {
  .inscription-form {
    .__row {
      width: 100%;
      max-width: 90%;
    }
  }
}
