.header-login {
  display: flex;
  justify-content: center;
  height: 80px;
  transition: all 0.3s ease;
  .header-login-content {
    padding: 0.5rem 0.63rem;
    width: 100%;
    height: 100%;
    max-width: 1440px;
    transition: all 0.3s ease;
  }
}
.login_tile {
  justify-self: center;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  gap: 128px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8f9fb;
  background: linear-gradient(109.78deg, #6df2f2 15.67%, #8e84fd 86.57%);
  position: relative;
  overflow: hidden;
  .bg_deg {
    background: radial-gradient(
      50% 50% at 50% 50%,
      #f895ff 0%,
      rgba(248, 149, 255, 0) 100%
    );
    opacity: 0.5;
    transform: rotate(-180deg);
    position: absolute;
    bottom: 50%;
    left: 20%;
    width: 120%;
    height: 115%;
    z-index: -2;
  }
  .form_card-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    background: #ffffff;
    max-width: 472px;
    border-radius: 16px;
    padding: 40.5px 52px 32.5px 52px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1), 0px 15px 40px rgba(0, 0, 0, 0.08);
    position: relative;
    z-index: 2;
  }
  .container-image {
    display: none;
  }
  .form_tile {
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .form_card {
    position: relative;
    z-index: 2;
    background-color: #ffffff;
    max-width: 19.8rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1.69rem 2.44rem 2.25rem 2.44rem;
    box-shadow: 0px 10px 16px rgba(109, 109, 109, 0.6);
    border-radius: 1rem;
    transition: all 0.3s ease;
    .h2 {
      font-family: 'Roca One';
      font-style: normal;
      font-weight: bold;
      font-size: 1.25rem;
      line-height: 1.25rem;
      margin-bottom: 1.38rem;
    }
    .h4 {
      font-family: 'Roca One';
      font-weight: normal;
      font-size: 1rem;
      line-height: 1.25rem;
      margin-bottom: 1.38rem;
    }
  }
}

.darshboard-link-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  background-color: #e2edff;
  border-radius: 16px;
  padding: 20px 0;
  margin: 20px 0;
  & a {
    border-radius: 0;
    & p {
      font-weight: bold;
    }
  }
}

@media (min-width: 1024px) {
  .header-login {
    height: 100px;
    .header-login-content {
      padding: 1.125rem 8.9rem;
      width: 100%;
      height: 100%;
    }
  }
  .login_tile {
    height: 100%;
    min-height: 100vh;
    .container-image {
      display: block;
      position: relative;
      margin-right: -4rem;
    }
    .form_card {
      max-width: 30rem;
      padding: 4rem 3.5rem 4.5rem 3.5rem;
      margin-left: -4rem;
      .h2 {
        font-size: 1.4rem;
        line-height: 2.5rem;
        margin-bottom: 0.63rem;
      }
      .h4 {
        font-size: 1.15rem;
        line-height: 2.5rem;
        margin-bottom: 2.38rem;
      }
    }
    .image_login {
      position: relative;
      z-index: 1;
    }
  }
}
@media (min-width: 1150px) {
  .login_tile {
    .container-image {
      margin-right: 0;
    }
    .form_card {
      margin-left: 0;
    }
  }
}
@media (min-width: 1280px) {
  .login_tile {
    .container-image {
      margin-right: 2.8rem;
    }
    .form_card {
      max-width: 33rem;
      padding: 4.5rem 4rem 4.9rem 4rem;
      margin-left: 2.8rem;
      .h2 {
        font-size: 1.5rem;
      }
      .h4 {
        font-size: 1.25rem;
      }
    }
  }
}
