.ui-step {
	width: 100px;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	&__functional-section {
		display: flex;
    flex-direction: column;
    align-items: center;
		cursor: pointer;
		&--color {
			&:hover .ui-step__circle {
					width: 36px;
					height: 36px;
					border-radius: 50%;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					position: relative;
					z-index: 1;
					background: rgba(0, 0, 0, 0.03) !important;
			}
		}
		&--noColor {
			&:hover .ui-step__circle {
					width: 36px;
					height: 36px;
					border-radius: 50%;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					position: relative;
					z-index: 1;
					background: rgba(0, 0, 0, 0.03) !important;
			}
		}
	}
	/* &__functional-section:hover .ui-step__circle {
				width: 36px;
				height: 36px;
				border-radius: 50%;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				position: relative;
				z-index: 1;
				background: rgba(0, 0, 0, 0.03) !important;
				transition: all 0.1s ease;
	} */
	&__circle {
		width: 36px;
		height: 36px;
		border-radius: 50%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		position: relative;
		z-index: 1;
	}
	&__name-course {
		min-height: 48px;
		font-size: 14px;
		line-height: 16px;
	}
	&__number-course {
		font-size: 14px;
		line-height: 16px;
	}
	&__number-course-certificate {
		font-size: 14px;
		line-height: 16px;
	}
	&__connector {
		height: 2px;
		width: 100px;
		position: relative;
		left: -52px;
		top: -18px;
	}
	&__student-here-triangle {
		margin-top: 4px;
		width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
	}
	&__student-here {
		position: relative;
		white-space: nowrap;
		display: flex;
		flex-direction: column;
    align-items: center;
		p {
			color: #F96B61;
		}
	}
}

@media only screen and (max-width: 420px) {
  .ui-step {
		&__name-course {
			display: none;
		}
		&__number-course-certificate {
			color: transparent;
		}
	}
}