.faq_tab_upd-Wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 120px;
  text-align: center;

  h2 {
		width: 100%;
    font-family: 'Roca One';
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		line-height: 40px;
    margin-bottom: 20px;
  }

  .faq_list {
    width: 95%;
    display: grid;
    /* grid-template-columns: repeat(2, 50%); */
    justify-items: center;
    align-items: start;
  }
}

@media only screen and (max-width: 768px) {
  .faq_tab-Wrapper {
    .faq_list {
      grid-template-columns: 100%;
      align-items: center;
      row-gap: 20px;
      column-gap: 0px;
    }
  }
  .faq_tab_upd-Wrapper {
    h2 {
      font-size: 21px;
    }
  }
}
