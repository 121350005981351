.nps-score-Wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &__points {
        display: flex;
        align-items: center;
        justify-content: center;
        &_container{
            display: flex;
            align-items: center;
            justify-content: center;
        }
        &_number{
            background: #E5E5E5;
            border-radius: 4px;
            width: 66px;
            height: 54px;
            padding: 4px 10px 2px;
            display: flex;
            justify-content: center;
            margin-right: 20px;
            cursor: pointer;
            font-size: 32px;
            color: #555555;
            font-weight: 700;
            align-items: center;
            &:hover{
                color: #FFFFFF;
                background: #9C56E0;
            }
        }
    }
    &__points-text{
        display: flex;
        justify-content: space-between;
        width: 100%;
        color: #BCBCBC;
        padding-right: 20px;
        font-size: 12px;
        line-height: 16px;
    }
    &__is-select{
        background: #9C56E0 !important;
        color: #FFFFFF;
    }
    .hr{
        width: 98%;
        color: #E5E5E5;
        margin: 20px 20px 20px 0;
    }
}

@media only screen and (max-width: 768px) {
    .nps-score-Wrapper {
        &__points {
            display: flex;
            align-items: center;
            justify-content: center;
            &_container{
                flex-wrap: wrap;
                row-gap: 16px;
                width: 318px;
            }
            &_number{
                width: 40px;
                height: 36px;
                margin-right: 12px;
                font-size: 20px;
                align-items: center;
                &:hover{
                    color: #FFFFFF;
                    background: #9C56E0;
                }
            }
        }
        &__points-text{
            padding-right: 12px;
            width: 318px;
            font-size: 10px;
            line-height: 12px;
        }
        .hr{
            width: 318px;
            color: #E5E5E5;
            margin: 16px 12px 16px 0;
        }
    }
}