.game-jam-workshop-Wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 100px 0 100px 0;

  .game-jam-workshop {
    display: flex;
    flex-direction: column;
    gap: 32px;

    max-width: 1080px;
    width: 100%;

    /* Title */
    &__title {
    }

    /* Container */
    &__container {
      border-radius: 12px;
      padding: 40px;

      width: 100%;

      /* Info */
      &_info {
        display: flex;
        &_img {
          margin: 0 0 30px 0;
        }

        &_download {
          display: flex;
          align-items: center;
          gap: 12px;
          cursor: pointer;
        }
      }
    }

    /* Free */
    &__free {
      display: flex;
      text-align: center;
      justify-content: center;
      flex-direction: column;
      background: #161a46;
      border-radius: 12px;
      padding: 40px;

      width: 100%;
      display: none;
      &_text {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      &_container {
        display: flex;
        justify-content: center;
        align-items: center;

        &_btn {
          padding: 18px 40px 14px 40px;
          border: 1px solid #ff52e2;
          border-radius: 8px;
          cursor: pointer;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .game-jam-workshop-Wrapper {
    padding: 80px 20px 80px 20px;
    overflow: hidden;

    .game-jam-workshop {
      /* Container */
      &__container {
        grid-template-columns: 1fr;
        padding: 40px 20px;
        gap: 24px;

        /* Info */
        &_info {
          flex-direction: column;
          &_img {
            margin: 0 0 14px 0;
          }
        }
      }

      /* Free */
      &__free {
        grid-template-columns: 1fr;
        gap: 24px;

        padding: 40px 20px;

        &_container {
          padding: 24px 20px;
        }
      }
    }
  }
}
.card-date {
  display: flex;
  justify-content: space-evenly;
  div {
    width: 31%;
    background: #010A2A;;
    border-radius: 8px;
    padding: 16px;
    text-align: center;
    p {
      text-align: left;
      img {
        margin-left: 10px;
        width: 30px;
        height: 30px;
        background: rgba(151, 104, 255, 0.29);
        border-radius: 50%;
        padding: 6px;
      }
    }
    .date {
      justify-content: start;
    }   
  }
}

.MuiSvgIcon-root {
  color: white !important;
}
.MuiDialog-container {
  background-color: rgb(15 19 66 / 85%);
}

@media screen and (max-width: 768px) {
  .card-date {
    flex-direction: column;
    div {
      width: 100%;
      margin-bottom: 20px;
      p {
        /* text-align: center; */
      }
      a {
        margin: auto;
        margin-bottom: 20px;
      }
    }
  }
  .date-popup {
    a {
      padding: 0 50px;
      text-align: center;
    }
  }
}