.card-type-program {
	/* padding: 16px; */
	/* border-bottom-right-radius: 16px;
	border-bottom-left-radius: 16px; */
	display: flex;
	transition: all 0.6s ease;
	cursor: pointer;
	flex-direction: column;
	
	&--more-program {
		margin: 0;
		width: 60%;
	}
	&--one-program {
		width: 40%;
	}
	&__flag-countries {
		display: flex;
		gap: 16px;
		height: 32px;
		align-items: center;
	}
	&__individual-flag {
		display: flex;
		gap: 12px;
	}
	&__container-card {
		box-shadow: var(--shadow-small);
		overflow: hidden;
		border-radius: 16px;

	}
	&__container-thumbnail {
		&--more-program {
			width: 100%;
		}
		&--one-program {
			width: 100%;
		}
	}
	&__thumbnail {
		max-width: none;
		background: #ffffff;
		width: 100%;
		object-fit: cover;
		display: flex;
		&--one-program {
			/* min-height: 203px;
			height: 203px; */
			width: 118%;
		}
		&--more-program {
			height: 236px;
			min-height: 236px;
		}
	}
	&__thumbnail-mob {
		display: none;
	}
	&__tag {
		&--one-program {
			margin-bottom: 38px;
		}
		&--more-program {
			margin-bottom: 20px;
		}
	}
	&__duration {
		display: flex;
		gap: 8px;
		&--one-program {
			/* margin-bottom: 38px; */
		}
		&--more-program {
			margin-bottom: 20px;
		}
	}
	&__container-detail {
		width: auto;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		&--one-program {
			padding: 24px 24px 24px 24px;
			min-height: 333px;
		}
		&--more-program {
			padding: 20px 24px 24px 24px;
			
		}
	}
	&__container-button {
		display: flex;
		flex-direction: column;
		gap: 12px;
	}
	&__detail-title {
		display: flex;
		transition: all 0.6s ease;
		align-items: center;
		flex-direction: row;
    	flex-wrap: wrap;
		&--one-program {
			min-height: 124px;
			margin-bottom: 9px;
			overflow: hidden;
			position: relative;
			span {
				position: absolute;
				top: 0px;
				left: 0;
			}
		}
		&--more-program {
			min-height: 124px;
			margin-bottom: 9px;
			position: relative;
			> span {
				position: absolute;
				top: 0px;
				left: 0;
			}
		}
	}
	&__detail-description {
		&--one-program {
			margin-bottom: 38px;
		}
		&--more-program {
			margin-bottom: 20px;
		}
	}
	&__tag-offer {
		width: 100%;
		height: 44px;
		display: flex;
		align-items: center;
    	∫justify-content: center;
	}
	&__link {
		display: flex;
		align-items: center;
		gap: 8px;
		font-size: 18px;
		line-height: 27px;	
		&--bold {
			font-weight: bold;
			text-decoration-line: underline;
		}
	}
	&__icon-right {
		transform: scale(1.5);
		margin-left: 10px;
	}
}

@media (max-width : 1040px){
	.card-type-program {
		flex-direction: column;
		&--one-program {
			width: 100%;
			margin-left: auto;
			margin-right: auto;
		}
		&--more-program {
			margin: 0;
			width: 100%;
			display: flex;
		}
	}  
	.card-type-program .typography-h3 {
		font-size: 34px;
	}
	 
}
@media (max-width : 920px){
	.card-type-program {
		width: calc(100% - 20px);
		margin-left: auto;
	margin-right: auto;

	}
	.card-type-program .typography-h3 {
		font-size: 21px;
		line-height: 30px;
		letter-spacing: -0.04em;
		min-height: inherit;
	}
}

@media (max-width : 768px){
	.card-type-program {
		flex-direction: column;
		&__container-thumbnail {
			width: 100%;
		}
		&__thumbnail {
			width: 100%;
			height: 204px;
			display: none;
			object-fit: cover;
			&--one-program {
				max-width: 232px;
			}
		}
		&__thumbnail-mob {
			object-fit: cover;
			display: flex;
			width: 100%;
			&--one-program {
				height: 120px;
				
			}
			&--more-program {
				height: 192px;

			}
		}
	}   
}

@media (max-width : 480px){
	.card-type-program {
		flex-direction: column;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		&__container-thumbnail {
			width: 100%;
		}
		&__thumbnail {
			width: 100%;
			height: 204px;
			object-fit: cover;
			&--one-program {
				max-width: 100%;
			}
		}
		&__link {
			justify-content: flex-end;
		}
		&__detail-title {
			&--one-program {
				min-height: inherit;
				span {
					position: inherit;
				}
			}
		}
		&__container-detail--one-program {
			min-height: inherit;
		}
		&__duration--one-program {
			margin-bottom: 20px;
		}
		&__detail-title--more-program {
			> span {
				position: inherit;
			}
		}
	}  
	&--one-program {
			min-height: 124px;
			margin-bottom: 9px;
			overflow: hidden;
			position: relative;
			span {
				position: absolute;
				top: 0px;
				left: 0;
			}
		}
		&--more-program {
			min-height: 124px;
			margin-bottom: 9px;
			position: relative;
			> span {
				position: absolute;
				top: 0px;
				left: 0;
			}
		} 
}
@media (max-width : 375px){
	.card-type-program {
		flex-direction: column;
		&__container-thumbnail {
			width: 100%;
		}
		&__thumbnail {
			width: 100%;
			height: 204px;
			object-fit: cover;
		}
	}   
}
