.vacation-page{
	transition: all 0.6s ease;
	&__first-section {
		position: relative;
		padding: 77px 0 76px 0;
		background: linear-gradient(180deg, #FFFDDF 0%, rgba(255, 253, 223, 0) 100%);
		.age-range-programs {
			display: none;
		}
	}
	&__second-section-ref {
		margin-top: -80px;
		padding-top: 80px;
	}
	&__second-section {
		position: relative;
		padding: 40px 0;
		min-height: 782px;
		background: linear-gradient(180deg, rgba(194, 255, 255, 0) 0%, #C2FFFF 100%);
		display: flex;
		flex-direction: column;
    	align-items: center;
	}
	&__second-section-content {
		display: flex;
		gap: 105px;
		flex-direction: row;
		max-width: 1080px;
	}
	&__detail-form {
		width: 52%;
		max-width: 550px;
	}
	&__image-form {
		position: relative;
		left: -165px;
		width: 700px;
		min-width: 700px;
	}
	&__contact-form {
		width: 50%;
		max-width: 477px;
	}
	&__contact-form .typography-h4{
		font-size: 28px;
		line-height: 32px;
	}
	&__title-contact {
		margin-bottom: 12px;
		font-size: 57px;
	}
	.jumbutronUpd-container {
		max-width: 1100px;
		.jumbutronUpd-content {
			padding: 0;
			align-items: flex-start;
			.jumbutronUpd-first-section {
				width: 48%;
				margin-top: 38px;
				.online-chip {
					margin-bottom: 0.75rem;
				}
				.age-range-programs {
					display: flex;
				}
				.jumbutronUpd-buttons-container {
					display: none;
				}
				.jumbutronUpd-buttons-container-tech {
					display: none;
				}
				.jumbutronUpd-subtitle {
					font-size: 20px;
					animation: title_enter .4s ease-out;
				}
				h1 {
					animation: title_enter 1s ease-out;
				}
			}
			.jumbutronUpd-second-section {
				width: 50%;
			}
		}
	}
	.age-range-programs {
		/* z-index: 6;
		position: relative; */
		/* top: -97px; */
	}
}

@media (max-width : 1200px){
	.vacation-page {
		&__second-section-content {
			gap: 100px;
		}
		/* &__image-form {
			width: 550px;
			min-width: 550px;
		} */
		
	}
}

@media (max-width : 1100px){
	.vacation-page {
		.jumbutronUpd-first-section {
			width: calc(100% - 40px);
			margin-left: auto;
			margin-right: auto;
		}
		&__second-section {
			padding: 40px 20px;
		}
		&__second-section-content {
			gap: 50px;
		}
		/* &__image-form {
			width: 400px;
			min-width: 400px;
		} */
		
	}
}

@media (max-width : 1023px){
	.vacation-page{
		transition: all 0.6s ease;
		&__first-section {
			padding: 30px 0 76px 0;
			.age-range-programs {
				display: flex;
				z-index: 6;
				margin-top: -76px;
				margin-bottom: 24px;
			}
		}
		&__image-form {
			width: 650px;
			min-width: 650px;
		}
		.jumbutronUpd-container {
			.jumbutronUpd-content {
				.jumbutronUpd-first-section {
					width: calc(100% - 40px);
					margin-left: auto;
					margin-right: auto;
					.age-range-programs {
						display: none;
					}
				}
				.jumbutronUpd-subtitle {
					order: 3;
				}
				.jumbutronUpd-description {
					text-align: left;
				}
				.jumbutronUpd-second-section {
					width: 100%;
				}
				.typography-h1 {
					text-align: left;
				}
			}
		}
	}
}

@media (max-width : 890px){
	.vacation-page{
		&__second-section-content {
			flex-direction: column;
		}
		&__detail-form {
			display: flex;
			flex-direction: column-reverse;
			align-items: center;
			height: auto;
			width: 100%;
		}
		&__contact-form {
			width: 100%;
		}
		&__contact-form .typography-h4{
			font-size: 23px;
		}
		&__title-contact {
			font-size: 34px;
		}
		&__image-form {
			/* width: calc(100% - 100px);
			margin: auto 50px; */
			width: 700px;
			min-width: 700px;
			left: -124px;
		}
	}

}
@media (max-width : 768px){
	.vacation-page{
		transition: all 0.6s ease;
		&__first-section {
			position: relative;
			padding-bottom: 56px;
		}
		&__second-section {
			position: relative;
			padding: 49px 0;
			
		}
		&__detail-form {
			display: flex;
		}
		&__title-contact {
			text-align: center;
		}
		.typography-h4 {
			text-align: center;
		}
		&__image-form {
			margin: 0 auto;
			width: 600px;
			min-width: 600px;
			left: -124px;
		}
		.jumbutronUpd-container {
			.jumbutronUpd-content {
				padding: 0 20px 0 20px;
				.jumbutronUpd-first-section {
					width: 100%;
					.jumbutronUpd-description {
						text-align: left;						
					}
					.jumbutronUpd-buttons-container {
						display: none;
					}
					.jumbutronUpd-subtitle {
						order: 3;
						font-weight: bold;
						font-size: 20px;
					}
				}
				.jumbutronUpd-second-section {
					width: 100%;
				}
				.jumbutronUpd-second-section img {
					margin: -50px auto 0 auto;
				}
			}
		}
		.age-range-programs {
			z-index: 6;
			position: relative;
			/* top: -47px; */
			.button-component-effect {
				width: 62px;
			}
		}
		&__contact_form-Card {
			width: 370px;
			margin: 0 auto;;
		}
	}
}
@media (max-width : 530px){
	.vacation-page{
		&__second-section {
			padding: 0 0 49px 0;
		}
		&__detail-form {
			width: 100%;
		}
		&__image-form {
			width: 100%;
			margin-bottom: 12px;
		}
		&__title-contact {
			text-align: left;
		}
		.typography-h4 {
			text-align: left;
		}
	}
}

@media (max-width : 480px){
	.vacation-page{
		&__second-section-content {
			width: 100%;
		}
		&__second-section {
			padding: 0 20px
		}
		&__image-form {
			width: 400px;
			min-width: 400px;
			left: -20px;
		}
	}
}

.animation-title-bottom-top {
	animation: title_enter .4s ease-out;
}

@media (max-width : 400px){
	.vacation-page{
		&__image-form {
			width: 350px;
			min-width: 350px;
			left: -30px;
		}
	}
}



