.detail-vacation-page{
	transition: all 0.6s ease;
	&__first-section {
		position: relative;
		padding-bottom: 120px;
		padding-top: 18px;
		.about-vacation {
			max-width: 850px;
			gap: 46px;
			&__first-content {
				width: 43%;
			}
			&__second-content {
				width: 57%;
				padding-top: 60px;
			}
		}
	}
	&__second-section {
		position: relative;
		padding: 49px 0;
	}
	.jumbutronUpd-container {
		.jumbutronUpd-content {
			padding: 4.7rem 0.63rem 0 0.63rem;
			gap: 35px;
			.jumbutronUpd-first-section {
				width: 100%;
				margin-bottom: 80px;
				.online-chip {
					margin-bottom: -8px;
				}
				.breadcrumbs {
					span, p {
						display: flex;
					}
				}
				/* h1 {
					font-size: 64px;
					text-align: left;
				} */
				.jumbutronUpd-buttons-container {
					display: none;
				}
				.jumbutronUpd-buttons-container-tech {
					display: flex;
					flex-direction: row;
					.button-component-effect {

					}
				}
				.jumbutronUpd-description {
					width: 372px;
				}
			}
			.jumbutronUpd-second-section {
				width : 100%;
			}
		}
	}
	.age-range-programs {
		z-index: 6;
		position: relative;
		top: -97px;
	}
}

@media (max-width : 1023px) {
	.detail-vacation-page {
		&__first-section {
			margin-top: 100px;
		}
		.jumbutronUpd-buttons-container-tech {
			flex-direction: row;
			margin: 0.62rem auto 1.56rem 0 !important;
		}
		.jumbutronUpd-description {
			text-align: left !important;
			width: 100% !important;
		}
		.jumbutronUpd-second-section > div {
			bottom: 0px;
			top: 90px;
		}
	}
}
@media (max-width : 768px){
	.detail-vacation-page{
		transition: all 0.6s ease;
		&__first-section {
			.about-vacation {
				&__first-content {
					width: auto;
					padding-bottom: 120px;
				}
				&__second-content {
					width: 100%;
					padding-top: 60px;
				}
			}
		}
		.jumbutronUpd-container {
			.jumbutronUpd-content {
				padding: 4.7rem 0.63rem 0 0.63rem;
				.jumbutronUpd-first-section {
					
					margin-bottom: 80px;
					.online-chip {
						margin-bottom: -8px;
					}
					.breadcrumbs {
						span, p {
							display: none;
						}
					}
					/* h1 {
						font-size: 64px;
					} */
					.jumbutronUpd-buttons-container {
						display: none;
					}
					.jumbutronUpd-buttons-container-tech {
						flex-direction: row;
						margin: 0.62rem auto 1.56rem 0;
						.button-component-effect {
							width: 100% !important;
						}
					}
					.jumbutronUpd-description {
						width: 100%;
						text-align: left;
					}
				}
			}
			.jumbutronUpd-second-section > div {
				width: 451px;
				height: 264px;
				border-radius: 20px;
				position: relative;
				display: flex;
				align-items: flex-end;
				/* bottom: -90px; */
				margin-right: auto;
				margin-left: auto;
			}
		}
		.age-range-programs {
			z-index: 6;
			position: relative;
			top: -47px;
		}
	}
}
@media (max-width : 480px){
	.detail-vacation-page{
		
		.jumbutronUpd-buttons-container-tech {
			flex-direction: column !important;
			width: 100% !important;
			.button-component-effect {
				width: 100% !important;
			}
			.jumbutronUpd-description {
				width: 100%;
				text-align: left;
			}
		}
		.jumbutronUpd-second-section > div {
			width: 100% !important;
			height: 204px !important;
			
		}
		.age-range-programs {
			z-index: 6;
			position: relative;
			top: -47px;
		}
	}
}
