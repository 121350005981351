.select {
  @apply relative w-full flex items-center pr-4;

  label {
    @apply relative h-12 flex items-center pl-5 pr-3 text-grey whitespace-no-wrap;
    z-index: 1;
  }

  select {
    @apply relative w-full h-12 bg-transparent outline-none;
    z-index: 1;
  }

  select:focus {
    & ~ span {
      @apply border-purple;
    }
  }

  span {
    @apply absolute border-2 border-black top-0 left-0 w-full h-full;
    content: '';
    z-index: 0;
  }

  @screen md {
    width: fit-content;

    select {
      @apply pr-12;
    }
  }
}
