.faq_tab_path-Wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 0 80px 0;
  background: #fafafb;

  .faq_tab_path {
    max-width: 899.5px;
    margin: 0 auto;

    .faq_list {
      display: grid;
      justify-items: center;
      align-items: start;

      .accordion__content {
        background: #fafafb;
      }
    }

    &--invisible {
      display: none !important;
      min-height: 0px;
      height: 0px;
      margin: 0;
      padding: 0;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

@media only screen and (max-width: 768px) {
  .faq_tab_path-Wrapper {
    padding: 32px 20px 64px 20px;

    .faq_tab_path {
      .faq_list {
        grid-template-columns: 100%;
        align-items: center;
        row-gap: 20px;
        column-gap: 0px;
      }
    }
  }
}
