.section-payment-vacation {
	width: 100%;
	max-width: 1040px;
	margin: 0 auto;
	&__container-cards {
		width: 100%;
		display: flex;
		gap: 16px;
		justify-content: flex-start;
		padding-top: 16px;
		overflow: visible;
		overflow-x: auto;
	}
	&__title {
		font-size: 32px;
		line-height: 125%;
		padding: 52px 0;
		text-align: center;
		&--bold {
			font-weight: bold;
		}
	}
	&__class-details {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 56px 0 17px 15px;
		gap: 31px;
	}
	&__class-item {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 14px;
	}
	&__class-item-text {
		font-size: 18px;
		line-height: 150%;
		&--bold {
			font-weight: bold;
		}		
	}
	&__container-button {
		display: flex;
		justify-content: center;		
		margin-bottom: 104px;
	}
	&__container-button-pay {
		display: flex;
		justify-content: center;	
	}
	&__container-details {
		padding: 0 16px;
	}
	&__details-title {
		font-size: 32px;
		line-height: 67px;
		padding-top: 56px;
		&--bold {
			font-weight: bold;
		}
	}
	&__details-description {
		font-size: 20px;
		line-height: 26px;
	}
	&__container-courses {
		display: flex;
		flex-direction: column;
		gap: 56px;
		margin: 56px 0 64px;
	}
	&__section-courses {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 60px;
		justify-content: flex-end;
    align-items: center;
	}
	&__courses-icon {
		width: 128px;
		height: 128px;
		border-radius: 16px;
		display: flex;
		justify-content: center;
    align-items: center;
	}
	&__courses-title {
		width: 250px;
	}
	&__courses-title h2 {
		font-size: 24px;
		line-height: 125%;
		font-weight: bold;
		text-align: center;
	}
	&__courses-description {
		max-width: 510px;
	}
	&__courses-description p {
		width: 100%;
		font-size: 18px;
		line-height: 125%;
		text-align: center;
	}
	&__class-icon-online {
		width: 12px;
		height: 12px;
		border-radius: 50%;
		background: #FF2C2C;
	}
	&__final-price {
		margin: 16px 0;
		border-radius: 16px;
		padding: 56px 40px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
	&__section-price {
		display: flex;
		flex-direction: column;
		gap:10px;
		margin-bottom: 52px;
	}
	&__section-tools {
		width: 248px;
    display: flex;
    flex-direction: row;
    gap: 16px;
    justify-content: space-between;
		margin-bottom: 32px;
	}
	&__section-access{
		width: 248px;
    display: flex;
    flex-direction: row;
    gap: 16px;
    justify-content: space-between;
	}
	&__container-icon {
		width: 24px;
		min-width: 24px;
		height: 24px;
		border-radius: 50%;
		display: flex;
		align-items: center;
    justify-content: center;
	}
	&__final-price-amount {
		font-size: 42px;
		line-height: 35px;
		&--bold {
			font-weight: bold;
		}
	}
	&__subtitles-section {
		font-size: 16px;
		line-height: 160%;
		&--bold {
			font-weight: bold;
		}
	}
}


@media (min-width : 480px){

}
@media (min-width : 710px){
	/* COMMENT min-width : 710px IF SHOW PROGRAMA PLUS */
	.section-payment-vacation {
		&__container-cards {
			justify-content: space-evenly;
		}
	}
}
@media (min-width : 1024px){
	
}
@media (min-width : 1280px){
	.section-payment-vacation {
		&__container-cards {
			overflow-x: none;
			/* COMMENT CHANGE JUSTIFY CONTENT PROGRAMA PLUS */
			/* justify-content: center; */
			justify-content: space-evenly;
			padding-left: 0;
			overflow: visible;
			overflow-x: none;
		}
		&__final-price {
			margin: 62px 56px;
			padding: 62px 36px;
			flex-direction: row;	
		}
		&__section-price {
			margin-bottom: 0;
		}
		&__section-tools {
			margin-bottom: 0;
		}
		&__section-courses {
			flex-direction: row;
		}
		&__class-details {
			flex-direction: row;
			gap: 0;
		}
		&__courses-description {
			max-width: 510px;
		}
		&__courses-description p {
			text-align: left;
		}
	}   	
	
}
