.game-jam-stage-Wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 0 100px 0;

  .game-jam-stage {
    display: flex;
    align-items: flex-end;
    gap: 32px;
    max-width: 1080px;
    width: 100%;

    &__image {
      padding-left: 20px;
    }

    &__list {
      &__item {
        margin-bottom: 50px;

        &__container_text {
          &_link {
            text-decoration: underline;
            color: #ff53e2;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.stage-number {
  color: #5626f5;
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  border-radius: 50%;
  border: 2px solid #5626f5;
  font-size: 28px;
}

.stage-text {
  padding-left: 25px;
}

@media only screen and (max-width: 768px) {
  .game-jam-stage-Wrapper {
    padding: 80px 20px 80px 20px;
    overflow: hidden;
    .game-jam-stage {
      gap: 0;
      &__image {
        display: none;
      }
    }
  }
  .stage-text {
    max-width: inherit !important;
  }
}

@media only screen and (max-width: 500px) {
  .stage-text {
    max-width: 230px !important;
  }
}

.game-jam-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background: radial-gradient(66.89% 438.39% at 85.69% 92.86%, #5626f5 0%, #8400ec 100%);
  color: white;
  max-width: 367px;
  width: 100%;
  height: 56px;
  border-radius: 8px;
  cursor: pointer;
  text-transform: uppercase;
}
