.game-jam-categories-Wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 100px 0 100px 0;

  .game-jam-categories {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 79px;

    max-width: 1082px;
    width: 100%;

    /* Categories */
    &__categories {
      display: flex;
      align-items: center;
      gap: 31px;

      &_juniors {
        position: relative;
        max-width: 526px;
        width: 526px;
        border: 1px solid #5d37ce;
        border-radius: 12px;
        background: radial-gradient(
          122.27% 181.56% at 50% 50%,
          rgba(92, 33, 245, 0) 0%,
          rgba(92, 33, 245, 0.28) 100%
        );
        padding: 23px 0 66px 49px;

        &_img {
          position: absolute;
          bottom: 0;
          right: 0;

          & > span {
            display: block !important;
          }
        }
      }

      &_teens {
        max-width: 526px;
        width: 526px;
        border: 1px solid #5d37ce;
        border-radius: 12px;
        background: radial-gradient(
          122.27% 181.56% at 50% 50%,
          rgba(92, 33, 245, 0) 0%,
          rgba(92, 33, 245, 0.28) 100%
        );
        padding: 23px 0 66px 49px;

        &_img {
          position: absolute;
          bottom: 0;
          right: 0;

          & > span {
            display: block !important;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .game-jam-categories-Wrapper {
    padding: 80px 20px 80px 20px;
    overflow: hidden;

    .game-jam-categories {
      gap: 37px;

      /* Categories */
      &__categories {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 61px;

        &_juniors {
          width: 100%;
          padding: 23px 0 35px 23.5px;
        }

        &_teens {
          width: 100%;
          padding: 23px 0 35px 23.5px;
        }
      }
    }
  }
}
