.user-dropdown-mobile {

	/* Trigger */
	&__trigger-login {
		gap: 8px;
	}

	&__trigger {
		display: flex;
		align-items: center;
		margin: 0 0 14px 0;

		&__username {
			font-size: 18px;
			font-weight: 600;
			line-height: 24px;

			margin-right: 4px;
		}

		&__logo {
			position: relative;
			width: 24px;
			height: 24px;
			background: #1ca3ff;
			border-radius: 50%;

			margin-right: 14px;

			&_word {
				position: absolute;
				top: 45%;
				left: 50%;
				transform: translate(-46%, -44%);
				color: white;

				font-size: 15px;
				font-weight: 700;
				line-height: 15px;
			}
		}
	}

	/* Options */
	&__options {
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 0px;

		width: 100%;
		height: 0;

		background: white;
		padding: 0 0 0 25px;

		opacity: 0;
		overflow: hidden;
		transition: all 0.25s ease-in-out;

		&--visible {
			height: 70px;
			opacity: 1;
			gap: 12px;
		}
	}

	/* Options login */
	&__options-login {
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 0px;

		width: 100%;
		height: 130px;
		background: white;

		overflow: hidden;
		transition: all 0.25s ease-in-out;

		&--visible {
			height: 192px;
			opacity: 1;
			gap: 20px;
		}
	}

	&__option {
		padding: 10px 24px 10px 24px;
	}
}