.ui-shape-tab {
	display: flex;
	align-items: flex-end;
	&__left,
	&__right {
		width: 8px;
		height: 8px;
		overflow: hidden;
		position: relative;
	}
	&__left:before,
	&__right:before{
		content: "";
		display: block;
		width: 200%;
		height: 200%;
		position: absolute;
		border-radius: 50%;
		bottom: 0;
	}
	&__left--inactive:before {
		left: 0;
		box-shadow: -4px 4px 0 0 #EFEAF6;
	}
	&__right--inactive:before {
		right: 0;
		box-shadow: 4px 4px 0 0 #EFEAF6;
	}
	&__left--active:before {
		left: 0;
		box-shadow: -4px 4px 0 0 #FFFFFF;
	}
	&__right--active:before {
		right: 0;
		box-shadow: 4px 4px 0 0 #FFFFFF;
	}
	&__center {
		min-width: 158px;
		width: 100%;
		min-height: 60px;
		border-top-left-radius: 32px;
		border-top-right-radius: 32px;
		display: flex;
		align-items: center;
		padding: 10px 12px;
		cursor: pointer;
		p {
			text-transform: capitalize;
		}
		&--inactive {
			background-color: #EFEAF6;
			img {
				-webkit-filter: grayscale(1); /* Webkit */
				filter: gray; /* IE6-9 */
				filter: grayscale(1); /* W3C */
			}
		}
		&--active {
			background-color: #FFFFFF;
		}
		p {
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: pre;	
		}
	}
	&__profile-image {
		width: 36px;
		height: 36px;
		border-radius: 50%;
		margin-right: 16px;
		&--inactive {
			background: #e9e2f3;
		}
		&--active {
			background: #fbfbfb;
		}
	}
}
