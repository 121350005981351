.faq_tab_upd-Wrapperr {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &--margin {
    margin-bottom: 80px;
  }

  .faq_list {
    width: 100%;
    display: grid;
    justify-items: center;
    align-items: start;
  }

  &--invisible {
    display: none !important;
    min-height: 0px;
    height: 0px;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
  }
}

@media only screen and (max-width: 768px) {
  .faq_tab-Wrapperr {
    .faq_list {
      grid-template-columns: 100%;
      align-items: center;
      row-gap: 20px;
      column-gap: 0px;
    }
  }
}
