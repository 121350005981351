.modal {
  @apply
    bg-grey fixed left-0 top-0 w-full h-screen z-50
    flex flex-col justify-end;

  & > section {
    @apply bg-white p-6;
  }

  & > section > header {
    @apply flex items-center justify-between py-4 mb-4;

    h3 {
      @apply mb-0;
    }
  }
}
