.landing-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.registration-section {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
  padding: 1.5rem 0;
}

.register-date {
  color: #a0a0a0;
  font-weight: 600;
  font-size: 1.5rem;
  & > span {
    color: #000000;
  }
}

/* Links or butons */
.ajust-degraded1 {
  text-align: center;
  align-items: center;
  border-radius: 30px;
}

@media only screen and (max-width: 425px) {
  .ajust-degraded1 {
    font-size: 1rem;
    height: 2.5rem;
    width: 70%;
    padding: 1rem 0;
  }
}
