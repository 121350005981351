.about-us {
  max-width: 1040px;
  margin: 0 auto;
  &__our-story {
    margin-bottom: 80px;
    display: flex;
    flex-direction: column;
  }
  &__our-story-title {
    width: 100%;
    font-size: 38px;
    line-height: 48px;
    text-align: center;
    margin-bottom: 24px;
    &--bold {
      font-weight: bold;
    }
  }
  &__our-story-paragraph {
    font-size: 28px;
    line-height: 150%;
    &--bold {
      font-weight: bold;
    }
  }
  &__our-story-container {
    width: 100%;
    display: flex;
    gap: 17px;
    flex-wrap: nowrap;
    flex-direction: row;
  }
  &__first-section-story {
    width: 50%;
    min-width: 320px !important;
    border-radius: 20px;
    padding: 40px;
    background: #fff4d3;
    display: flex;
    align-items: center;
  }
  &__second-section-story {
    width: 50%;
    min-width: 320px !important;
    max-width: 600px !important;
    display: flex;
    align-items: center;
  }
  &__our-story-image {
    width: 100%;
  }
  &__alliance-companies {
    position: relative;
    width: 100%;
    height: 544px;
    background: linear-gradient(
      180deg,
      #aafff7 11.15%,
      #a8faf7 14.05%,
      #99cef3 41.27%,
      #8eaef1 65.63%,
      #879af0 86.05%,
      #8593ef 100.01%
    );
    overflow: hidden;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px 0 120px 0;
  }
  &__bg-deg-white {
    position: absolute;
    top: -544px;
    left: -544px;
    width: 1088px;
    height: 1088px;
    background: radial-gradient(
      50% 50% at 50% 50%,
      #f3f3f3 0%,
      rgba(243, 243, 243, 0) 100%
    );
  }
  &__bg-deg-pink {
    position: absolute;
    top: 0px;
    right: -544px;
    width: 1088px;
    height: 1088px;
    background: radial-gradient(
      50% 50% at 50% 50%,
      #f895ff 0%,
      rgba(248, 149, 255, 0) 100%
    );
    opacity: 0.5;
  }

  &__alliance-description {
    position: relative;
    font-weight: bold;
    font-size: 28px;
    line-height: 42px;
    max-width: 720px;
    text-align: center;
    color: #ffffff;
  }
  &__our-story-image {
    width: 444px;
    /* height: 328px; */
  }
  &__carousel {
    width: 100% !important;
    .alice-carousel {
      width: 100%;
      padding: 0;
      &__stage-item {
        width: 444px !important;
        height: 360px;
        margin: 0 15px;
      }
      &__stage-item img {
        width: 444px !important;
        pointer-events: none;
      }
      &__stage-item div {
        width: 444px !important;
        height: 360px;
      }
      &__dots {
        width: 200px;
        margin: 0 auto;
      }
      &__next-btn {
        width: 40px !important;
        left: calc(50% + 20px);
        top: -47px;
      }
      &__next-btn-item {
        width: 100%;
        span {
          width: 100%;
          margin: 0 4px;
        }
      }
      &__prev-btn {
        width: 40px !important;
        left: calc(50% - 100px);
        top: -47px;
      }
      &__prev-btn-item {
        width: 100%;
        span {
          width: 100%;
          margin: 0 4px;
        }
      }
    }
  }
}

.about-us .jumbutronUpd-buttons-container {
  display: none !important;
}
.about-us .online-chip {
  display: none !important;
}
.about-us .online-chip {
  display: none !important;
}
.about-us .icon-right {
  margin-left: 0;
}

@media only screen and (max-width: 1024px) {
  .about-us .jumbutronUpd-buttons-container-tech {
    justify-content: center;
  }
}
@media only screen and (max-width: 920px) {
  .about-us {
    &__our-story-paragraph {
      text-align: center;
    }
    &__our-story-container {
      align-items: center;
      flex-direction: column;
      gap: 24px;
    }
    &__first-section-story {
      width: 100%;
    }
    &__second-section-story {
      width: auto;
      justify-content: center;
    }
    &__alliance-companies {
      padding: 1px;
      height: 420px;
    }
    &__bg-deg-white {
      position: absolute;
      top: -420px;
      left: -420px;
      width: 350px;
      height: 840px;
      background: radial-gradient(
        50% 50% at 50% 50%,
        #f3f3f3 0%,
        rgba(243, 243, 243, 0) 100%
      );
    }
    &__bg-deg-pink {
      position: absolute;
      top: 0px;
      right: -145px;
      width: 350px;
      height: 840px;
      background: radial-gradient(
        50% 50% at 50% 50%,
        #f895ff 0%,
        rgba(248, 149, 255, 0) 100%
      );
      opacity: 0.5;
    }

    &__alliance-description {
      padding: 0 61px;
      font-size: 21px;
      line-height: 32px;
    }
    &__our-story-image {
      width: 355px;
      /* height: 208px; */
    }
    &__carousel {
      .alice-carousel {
        &__stage-item {
          width: 320px !important;
          height: 270px !important;
          margin: 0 15px;
        }
        &__stage-item img {
          width: 320px !important;
          height: 239px;
          pointer-events: none;
        }
        &__stage-item div {
          width: 320px !important;
          height: 270px;
        }
      }
    }
  }
}
@media only screen and (max-width: 760px) {
  .about-us .jumbutronUpd-first-section h1 {
    font-size: 38px !important;
    line-height: 48px !important;
  }
  .about-us {
    &__our-story {
      margin: 0 10px 27px 10px;
    }
    &__our-story-paragraph {
      font-size: 21px;
      line-height: 32px;
    }
  }
}
@media only screen and (max-width: 460px) {
  .about-us {
    &__alliance-companies {
      height: 425px;
    }
    &__bg-deg-white {
      width: 290px;
    }
    &__bg-deg-pink {
      width: 290px;
    }
    &__carousel {
      .alice-carousel {
        padding: 0 25px;
        &__stage-item {
          width: 226px !important;
          height: 220px !important;
          margin: 0 15px;
        }
        &__stage-item img {
          width: 226px !important;
          height: 169px;
          pointer-events: none;
        }
        &__stage-item div {
          width: 226px !important;
          height: 220px;
        }
      }
    }
  }
}
