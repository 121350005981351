.navegation_layout {
	height: 100vh;
	margin: 0;
	display: grid;
	grid-template-columns: 251px 1fr;

	.children_section {
		grid-column-start: 2;
		display: flex;
		flex-direction: column;
	}

	.content_section {
		position: relative;
		width: inherit;
		height: 100%;
		display: flex;
		flex-direction: column;

		/* margin: 0 1.5rem 0 2rem; */
		/* padding: 0 1.5rem 0 2rem; */

		&>.faq_container>.background {
			position: absolute;
			top: 13rem;
			right: 0;
			width: 634px;
			height: 483px;
			z-index: -1;
		}

		&>.pagos_container>.background {
			position: absolute;
			top: 13rem;
			right: 0;
			width: 634px;
			height: 483px;
			z-index: -1;
		}
	}

	/* colors */
	.children_section--color-contactar-a-soporte {
		background-color: var(--color-marmol-white);
	}
}

.container-modal-blocker {
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	z-index: 150;
	width: 100vw;
	height: 100vh;
	background-color: rgb(0, 0, 0, .1);
}

.content-modal-blocker {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1.25rem;
	min-width: 31rem;
	max-width: 90%;
	width: 35.5rem;
	background-color: white;
	padding: 3rem 2.5rem;
	border-radius: 0.5rem;
	box-shadow: var(--shadow-small);
}

@media only screen and (max-width: 768px) {
	.navegation_layout {
		display: grid;
		grid-template-columns: 1fr;

		.children_section {
			grid-column-start: 1;
			display: flex;
			flex-direction: column;
		}

		.has_filter_blur {
			filter: blur(10px);
			-webkit-filter: blur(10px);
		}

		.content_section {
			&>.faq_container>.background {
				position: absolute;
				top: 13.5rem;
				left: 0;
				right: 0;
				object-fit: cover;
				width: 100%;
				height: auto;
			}

			&>.pagos_container>.background {
				position: absolute;
				top: 13.5rem;
				left: 0;
				right: 0;
				object-fit: cover;
				width: 100%;
				height: auto;
			}
		}
	}
}