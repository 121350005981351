/* SubSidebar */
.sub-sidebar-Wrapper {
  position: fixed;
  top: 0;
  left: 100%;

  height: 100vh;
  width: 100%;
  background: white;
  z-index: 999;
  transition: all 0.25s ease-in-out;
  padding: 140px 20px 52px 20px;

  .sub-sidebar {
    &__back {
      position: absolute;
      top: 27px;
      left: 25px;
    }

    &__list {
      display: flex;
      flex-direction: column;
      gap: 25px;
    }

    &__close {
      position: absolute;
      top: 22.44px;
      right: 16.44px;
    }
  }

  &--active {
    left: 0;
    z-index: 9999999;
  }
}
